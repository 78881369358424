<template>
  <v-container fill-height :key="this.buildingIndex">
    <v-row>
      <h2>Building #{{ parseInt(this.buildingIndex) + 1 }}</h2>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <Shape :buildingIndex="this.buildingIndex" />
      </v-col>
      <v-col cols="12" md="3">
        <Column-Base-Elevation :buildingIndex="this.buildingIndex" />
        <Exterior-Panels :buildingIndex="this.buildingIndex" />
      </v-col>
      <v-col cols="12" md="3">
        <Loads :buildingIndex="this.buildingIndex" />
      </v-col>
      <v-col cols="12" md="3">
        <Base-Condition :buildingIndex="this.buildingIndex" />
        <Accessories :buildingIndex="this.buildingIndex" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <Rigid-Frames :buildingIndex="this.buildingIndex" />
      </v-col>
    </v-row>
    <v-row>
      <v-row class="pa-4">
        <h3>Roof & Wall Input and Accessories</h3>
      </v-row>
      <div style="border: 1px dotted; width: 100%"></div>
    </v-row>
    <v-row>
      <Walls />
    </v-row>
    <v-row>
      <Building-Notes :buildingIndex="this.buildingIndex" />
    </v-row>
    <v-row :key="this.nextBuilding">
      <v-col cols="1" v-if="this.buildingIndex === '0'">
        <v-btn color="secondary" class="mr-4" @click="$router.go(-1)"
          >Back</v-btn
        >
      </v-col>
      <v-col cols="1" v-if="this.buildingIndex > 0">
        <v-btn
          color="secondary"
          class="mr-4"
          :to="`${parseInt(this.buildingIndex) - 1}`"
          >Back</v-btn
        >
      </v-col>
      <v-col align="start" v-if="this.buildingIndex > 0">
        <v-btn color="error" @click="dialog = true">Remove</v-btn>
      </v-col>
      <!-- <v-col align="right" v-if="this.buildings.length <= this.nextBuilding">
        <v-btn color="primary" class="mr-4" @click="addBuilding()"
          >Add Building</v-btn
        >
      </v-col> -->
      <!-- <v-col align="right" v-if="this.buildings.length > this.nextBuilding">
        <v-btn color="primary" class="mr-4" :to="`${this.nextBuilding}`"
          >Next</v-btn
        >
      </v-col> -->
    </v-row>
    <v-dialog v-model="dialog" width="300"
      ><v-card>
        <v-card-title class="text-h5 grey lighten-2"> Warning </v-card-title>

        <v-card-text class="pt-4">
          <h3>
            You are about to remove a building from the project. Please confirm
            to remove.
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="removeBuilding()"> REMOVE </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex"
import Shape from "./Shape"
import Loads from "./Loads"
import ColumnBaseElevation from "./ColumnBaseElevation"
import BaseCondition from "./BaseCondition"
import Walls from "./Walls"
import Accessories from "./Accessories"
import ExteriorPanels from "./ExteriorPanels"
import RigidFrames from "./RigidFrames"
import BuildingNotes from "./BuildingNotes"

export default {
  name: "Index",

  components: {
    Shape,
    Loads,
    ColumnBaseElevation,
    BaseCondition,
    ExteriorPanels,
    Accessories,
    Walls,
    RigidFrames,
    BuildingNotes,
  },

  computed: {
    ...mapState({
      buildings: (state) => state.data.buildings,
    }),
    buildingIndex() {
      return this.$route.params.buildingIndex
    },
    building() {
      return this.buildings[this.buildingIndex]
    },
    nextBuilding() {
      const index = parseInt(this.buildingIndex) + 1
      return index
    },
  },

  methods: {
    ...mapActions({
      addBuildingToArray: "data/addBuildingToArray",
      removeBuildingFromArray: "data/removeBuildingFromArray",
    }),
    async addBuilding() {
      await this.addBuildingToArray()
      this.$router.push({ path: `${this.nextBuilding}` })
    },
    async removeBuilding() {
      this.dialog = false
      await this.$router.push({ path: `${parseInt(this.buildingIndex) - 1}` })
      await this.removeBuildingFromArray(parseInt(this.buildingIndex) + 1)
    },
  },
  data: () => ({
    dialog: false,
  }),
}
</script>
