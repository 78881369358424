<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container fill-height class="form">
      <v-row class="text-center">
        <v-col class="mb-4">
          <v-img
            :src="require('../../assets/ST Logo with bckgrnd.jpg')"
            class="my-3"
            contain
            height="100"
          />
          <h1 class="display-2 font-weight-bold mb-3">Dealer Sheet</h1>
          <p class="subheading font-weight-regular">
            Steel Tech Building Systems designs & manufactures pre-engineered
            metal buildings,
            <br />
            driven by an intentional, no-compromise passion for excellence.
          </p>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-card class="pa-5">
          <router-view />
        </v-card>
      </v-row>
      <v-row
        align="center"
        justify="center"
        v-if="this.$route.fullPath !== '/quote/project'"
      >
        <Attachments />
      </v-row>
      <v-row class="pa-4">
        <v-col align="right" cols="10">
          <v-btn color="accent" to="/"> Save & Continue </v-btn>
        </v-col>
        <v-col align="right" v-if="this.$route.fullPath !== '/quote/project'">
          <v-btn color="primary" class="mr-4" @click="openDialog()">
            Submit
          </v-btn>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" width="300"
        ><v-card>
          <v-card-title class="text-h5 grey lighten-2"> Warning </v-card-title>
          <v-card-text class="pt-4">
            <h3>
              You are about to submit the project. Submitting will reset the
              form. You can download the project without submitting.
            </h3>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" @click="submit()"> Download </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="submitAndClear()" :loading="submitted">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex"
import Attachments from "./Attachments"
import { createItem, sendEmail } from "@/plugins/axios"

export default {
  name: "App",

  components: {
    Attachments,
  },

  data: () => ({
    valid: true,
    isValid: false,
    dialog: false,
    submitted: false,
  }),

  computed: {
    ...mapState({
      project: (state) => state.data.project,
      dealer: (state) => state.data.project.primaryInfo.dealerInfo.name.value,
      dealerContact: (state) =>
        state.data.project.primaryInfo.projectInfo.contactName.value,
      dealerEmail: (state) =>
        state.data.project.primaryInfo.projectInfo.contactEmail.value,
      buildingNotes: (state) =>
        state.data.buildings[0].notes.buildingNotes.value,
      projectName: (state) =>
        state.data.project.primaryInfo.projectInfo.projectName.value,
      projectAddress: (state) =>
        state.data.project.primaryInfo.projectInfo.address.value,
      projectState: (state) =>
        state.data.project.primaryInfo.projectInfo.state.value,
      projectCity: (state) =>
        state.data.project.primaryInfo.projectInfo.city.value,
      projectZip: (state) =>
        state.data.project.primaryInfo.projectInfo.zip.value,
      building: (state) => state.data.building,
      roofPanel: (state) =>
        state.data.buildings[0].exteriorPanels.roofPanel.value,
      roofInsulationType: (state) =>
        state.data.buildings[0].roofInsulation.type.value,
      wallInsulationType: (state) =>
        state.data.buildings[0].wallInsulation.type.value,
      roofInsulationThickness: (state) =>
        state.data.buildings[0].roofInsulation.thickness.value,
      wallInsulationThickness: (state) =>
        state.data.buildings[0].wallInsulation.thickness.value,
      thermalFactor: (state) =>
        state.data.buildings[0].loads.thermalFactor.value,
      roofBays: (state) => state.data.buildings[0].roof.surfaceSpacings,
      leftEndwall: (state) =>
        state.data.buildings[0].leftEndwall.surfaceSpacings,
      rightEndwall: (state) =>
        state.data.buildings[0].rightEndwall.surfaceSpacings,
      frontSidewall: (state) =>
        state.data.buildings[0].frontSidewall.surfaceSpacings,
      backSidewall: (state) =>
        state.data.buildings[0].backSidewall.surfaceSpacings,
      rules: (state) => state.form.rules,
      formCheck: (state) => state.form.formCheck,
      attachment: (state) => state.form.attachment,
    }),
  },

  created() {
    this.initiateState()
  },

  watch: {
    formCheck() {
      if (this.validate()) {
        this.setObject({ value: true, path: "formValid" })
      }
    },
    roofBays: {
      deep: true,
      handler() {
        if (this.roofBays.length > 0) {
          this.$refs.form.validate()
        }
      },
    },
    leftEndwall: {
      deep: true,
      handler() {
        if (this.leftEndwall.length > 0) {
          this.$refs.form.validate()
        }
      },
    },
    rightEndwall: {
      deep: true,
      handler() {
        if (this.rightEndwall.length > 0) {
          this.$refs.form.validate()
        }
      },
    },
    backSidewall: {
      deep: true,
      handler() {
        if (this.backSidewall.length > 0) {
          this.$refs.form.validate()
        }
      },
    },
    frontSidewall: {
      deep: true,
      handler() {
        if (this.frontSidewall.length > 0) {
          this.$refs.form.validate()
        }
      },
    },
  },

  methods: {
    ...mapActions({
      downloadJson: "data/downloadState",
      convertStateToBase64: "data/convertStateToBase64",
      convertStateToJson: "data/convertStateToJson",
      resetDataState: "data/resetState",
      initiateState: "data/initiateState",
      setObject: "form/setObject",
    }),

    submit() {
      if (this.validate()) {
        this.downloadJson()
      }
    },
    async submitAndClear() {
      if (this.validate()) {
        this.submitted = true
        await this.sendEmail()
        await this.createClickUpTask()
        await this.delay(20000)
        this.resetDataState("/")
        this.dialog = false
        this.submitted = false
      }
    },
    convertAttachmentToBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()

        reader.onload = () => {
          resolve(reader.result.replace("data:", "").replace(/^.+,/, ""))
        }

        reader.onerror = reject

        reader.readAsDataURL(file)
      })
    },

    async sendEmail() {
      const {
        dealer,
        dealerEmail,
        projectName,
        projectAddress,
        projectState,
        projectZip,
        roofPanel,
        projectCity,
      } = this
      const base64 = await this.convertStateToBase64()
      const attachment =
        this.attachment.size > 0
          ? await this.convertAttachmentToBase64(this.attachment)
          : null
      try {
        sendEmail({
          content: base64,
          pdf: attachment,
          dealer,
          dealerEmail,
          projectName,
          projectAddress,
          projectState,
          projectZip,
          projectCity,
          roofPanel,
          email: "sales@steeltech.io",
        })
      } catch (err) {
        console.log(err)
      }
    },

    async createClickUpTask() {
      const listId = "4020718"
      const due_date = new Date().valueOf()
      const {
        dealer,
        dealerEmail,
        projectName,
        projectAddress,
        projectState,
        projectZip,
        roofPanel,
        projectCity,
        dealerContact,
        wallInsulationType,
        wallInsulationThickness,
        roofInsulationType,
        roofInsulationThickness,
        thermalFactor,
      } = this
      const attachments = []
      const data = await this.convertStateToJson()
      if (this.attachment.size > 0) {
        attachments.push({
          name: "Drawings.pdf",
          data: await this.convertAttachmentToBase64(this.attachment),
        })
      }
      attachments.push({
        name: `Quote.mbsQuote`,
        data: data,
      })
      const clickUpTask = await createItem(
        listId,
        {
          name: `${dealer}: ${projectName}`,
          description: `Project info: ${projectAddress}, ${projectCity}, ${projectState}, ${projectZip}\n
          Contact info: Name: ${dealerContact}\nEmail: ${dealerEmail}\nAdditional info:\n
          Roof Panel: ${roofPanel} Thermal Factor: ${thermalFactor}\n
          Wall Insulation: ${wallInsulationType} Thickness: ${wallInsulationThickness}\n
          Roof Insulation: ${roofInsulationType} Thickness: ${roofInsulationThickness}`,
          status: "Open",
          due_date,
          due_date_time: false,
          parent: null,
          links_to: null,
        },
        attachments
      )
      if (clickUpTask) {
        this.submitted = false
      }
    },

    openDialog() {
      if (this.validate()) {
        this.dialog = true
      }
    },
    validate() {
      return this.$refs.form.validate()
    },
    delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time))
    },
  },
}
</script>
