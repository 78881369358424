<template>
  <v-container fluid>
    <v-row v-for="(item, index) in items" :key="index" class="pb-2">
      <v-col cols="12" md="2">
        <input-handler
          :path="`${path}[${index}].numberOfBays`"
          :addRules="[
            (v) => v > 0 || 'Must be greater than 0.',
            (v) => !!v || 'This field is required.',
          ]"
          :disabled="type.includes('Sidewall') ? true : false"
        />
      </v-col>
      <v-col cols="12" md="3">
        <input-handler
          :path="`${path}[${index}].width`"
          :addRules="[
            (v) =>
              (!!v &&
                parseFloat(totalWidth).toPrecision(4) ==
                  parseFloat(buildingWidth)) ||
              'Must be equal to the building width or length.',
            // (v) => v <= 30 || 'Bay width cannot be greater than 30 feet.',
          ]"
          :disabled="type.includes('Sidewall') ? true : false"
        />
      </v-col>
      <!-- <v-col cols="12" md="3">
        <input-handler
          :path="`${path}[${index}].openHeight`"
          :disabled="type.includes('Sidewall') ? true : false"
        />
      </v-col> -->
      <v-col cols="12" md="3">
        <input-handler
          :path="`${path}[${index}].openCondition`"
          :disabled="type.includes('Sidewall') ? true : false"
        />
      </v-col>
    </v-row>
    <v-row class="pa-4" v-if="!type.includes('Sidewall')">
      <v-col>
        <v-btn
          small
          color="accent"
          @click="addSurfaceSpacing(type, buildingIndex)"
          >Add</v-btn
        >
      </v-col>
      <v-col v-if="items.length > 1">
        <v-btn
          small
          color="secondary"
          @click="removeSurfaceSpacing(type, buildingIndex)"
          >Remove
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex"

export default {
  name: "SurfaceTabItem",

  props: {
    type: String,
    items: Array,
  },

  computed: {
    ...mapState({
      buildings: (state) => state.data.buildings,
      activeTab: (state) => state.form.activeTab,
    }),
    buildingIndex() {
      return this.$route.params.buildingIndex
    },
    path() {
      return `buildings[${this.buildingIndex}].${this.type}.surfaceSpacings`
    },
    roofBays() {
      return this.buildings[this.buildingIndex].roof.surfaceSpacings
    },
    leftEndwallBays() {
      return this.buildings[this.buildingIndex].leftEndwall.surfaceSpacings
    },
    rightEndwallBays() {
      return this.buildings[this.buildingIndex].rightEndwall.surfaceSpacings
    },
    frontSidewallPath() {
      return `buildings[${this.buildingIndex}].frontSidewall`
    },
    leftEndwallPath() {
      return `buildings[${this.buildingIndex}].leftEndwall`
    },
    rightEndwallPath() {
      return `buildings[${this.buildingIndex}].rightEndwall`
    },
    backSidewallPath() {
      return `buildings[${this.buildingIndex}].backSidewall`
    },
    leftEndwallMirrored() {
      return this.buildings[this.buildingIndex].leftEndwall.mirroredBays
    },
    rightEndwallMirrored() {
      return this.buildings[this.buildingIndex].rightEndwall.mirroredBays
    },
    liners() {
      return this.buildings[this.buildingIndex][this.type].liners
    },
    wainscots() {
      return this.buildings[this.buildingIndex][this.type].wainscots
    },
    buildingWidth() {
      if (this.type.includes("Endwall"))
        return this.buildings[this.buildingIndex].shape.width.value
      else return this.buildings[this.buildingIndex].shape.length.value
    },
    totalWidth() {
      const surface =
        this.buildings[this.buildingIndex][this.type].surfaceSpacings
      let totalWidth = 0
      surface.forEach((bay) => {
        if (bay.numberOfBays.value && bay.width.value)
          totalWidth =
            totalWidth +
            parseInt(bay.numberOfBays.value) * parseFloat(bay.width.value)
      })
      return totalWidth
    },
    tab() {
      return this.activeTab
    },
  },

  data: () => ({}),

  watch: {
    roofBays: {
      deep: true,
      handler() {
        this.setObject({
          object: this.roofBays,
          path: this.frontSidewallPath,
          value: "surfaceSpacings",
        })
        this.setObject({
          object: this.roofBays,
          path: this.backSidewallPath,
          value: "surfaceSpacings",
        })
      },
    },
    leftEndwallMirrored: {
      deep: true,
      handler() {
        if (
          this.type === "leftEndwall" &&
          this.leftEndwallMirrored.value !== this.rightEndwallMirrored.value
        ) {
          this.updateStateValue({
            object: this.rightEndwallMirrored,
            value: this.leftEndwallMirrored.value,
            path: `${this.rightEndwallPath}.mirroredBays`,
          })
          if (this.leftEndwallMirrored.value) {
            const leftEndwallClone = JSON.parse(
              JSON.stringify(this.leftEndwallBays)
            )
            this.setObject({
              object: leftEndwallClone,
              path: this.rightEndwallPath,
              value: "surfaceSpacings",
            })
          }
        }
      },
    },
    leftEndwallBays: {
      deep: true,
      handler() {
        if (this.leftEndwallMirrored.value && this.tab === 1) {
          const leftEndwallClone = JSON.parse(
            JSON.stringify(this.leftEndwallBays)
          )
          this.setObject({
            object: leftEndwallClone,
            path: this.rightEndwallPath,
            value: "surfaceSpacings",
          })
        }
      },
    },

    rightEndwallMirrored: {
      deep: true,
      handler() {
        if (
          this.type === "rightEndwall" &&
          this.leftEndwallMirrored.value !== this.rightEndwallMirrored.value
        ) {
          this.updateStateValue({
            object: this.leftEndwallMirrored,
            value: this.rightEndwallMirrored.value,
            path: `${this.leftEndwallPath}.mirroredBays`,
          })
          if (this.rightEndwallMirrored.value) {
            const rightEndwallClone = JSON.parse(
              JSON.stringify(this.rightEndwallBays)
            )
            this.setObject({
              object: rightEndwallClone,
              path: this.leftEndwallPath,
              value: "surfaceSpacings",
            })
            this.updateValue = true
          }
        }
      },
    },
    rightEndwallBays: {
      deep: true,
      handler() {
        if (this.leftEndwallMirrored.value && this.tab === 3) {
          const rightEndwallClone = JSON.parse(
            JSON.stringify(this.rightEndwallBays)
          )
          this.setObject({
            object: rightEndwallClone,
            path: this.leftEndwallPath,
            value: "surfaceSpacings",
          })
        }
      },
    },
  },

  components: {
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },

  methods: {
    ...mapActions({
      updateStateValue: "data/updateStateValue",
      setObject: "data/setObject",
      addValidSurfaceSpacing: "data/addSurfaceSpacingToArray",
      removeValidSurfaceSpacing: "data/removeSurfaceSpacingFromArray",
    }),
    async addSurfaceSpacing(type, buildingIndex) {
      await this.addValidSurfaceSpacing({ type, buildingIndex })
    },
    removeSurfaceSpacing(type, buildingIndex) {
      this.removeValidSurfaceSpacing({ type, buildingIndex })
    },
  },
}
</script>
