var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.surfaces.length > 0 && _vm.options.length > 0)?_c('v-container',{attrs:{"fluid":""}},[_vm._l((_vm.items),function(item,index){return _c('v-row',{key:index,staticClass:"pb-3"},[(!_vm.type.includes('Endwall'))?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('input-handler',{attrs:{"path":(_vm.path + "[" + index + "].fullWidth")}})],1):_vm._e(),(!_vm.type.includes('Endwall'))?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('input-handler',{attrs:{"path":(_vm.path + "[" + index + "].start"),"disabled":item.fullWidth.value ? true : false,"setValue":item.fullWidth.value ? '1' : '',"options":item.fullWidth.value ? ['1'] : _vm.options,"addRules":[
          function (v) { return !_vm.bayCheck(v) || 'Must be equal to or less than End'; } ]}})],1):_vm._e(),(!_vm.type.includes('Endwall'))?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('input-handler',{attrs:{"path":(_vm.path + "[" + index + "].end"),"disabled":item.fullWidth.value ? true : false,"setValue":item.fullWidth.value
            ? String(_vm.options[_vm.options.length - 1].value)
            : '',"options":item.fullWidth.value
            ? [String(_vm.options[_vm.options.length - 1].value)]
            : _vm.options,"addRules":[
          function (v) { return v >= item.start.value ||
            'Must be equal to or greater than Start.'; } ]}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('input-handler',{attrs:{"path":(_vm.path + "[" + index + "].width")}})],1)],1)}),_c('v-row',[_c('h5',[_vm._v("Soffits included")])]),(!_vm.type.includes('Endwall'))?_c('v-row',{staticClass:"pa-4"},[_c('v-col',[_c('v-btn',{attrs:{"small":"","color":"accent"},on:{"click":function($event){return _vm.addExtension(_vm.type, _vm.buildingIndex)}}},[_vm._v("Add")])],1),(_vm.items.length > 1)?_c('v-col',[_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.removeExtension(_vm.type, _vm.buildingIndex)}}},[_vm._v("Remove ")])],1):_vm._e()],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }