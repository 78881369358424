import Vue from "vue"

export default {
  AUTHORIZED(state, user) {
    Vue.set(state, "user", user)
    Vue.set(state, "isAuthorized", true)
  },
  UNAUTHORIZED(state) {
    Vue.set(state, "user", {})
    Vue.set(state, "isAuthorized", false)
  },
}
