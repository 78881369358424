import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator,
} from "firebase/functions"

/** The Firebase credentials */
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

export const firebaseApp = initializeApp(firebaseConfig)

export const auth = getAuth(firebaseApp)
export const functions = getFunctions(firebaseApp, "us-central1")
// connectFunctionsEmulator(functions, "localhost", 5001)

const genericEmail = httpsCallable(functions, "genericEmail")
export const createTask = httpsCallable(functions, "createTask")
export const sendEmail = (data) => {
  try {
    genericEmail(data)
      .then((result) => {
        const data = result.data
        const sanitizedMessage = data.text
        console.log(sanitizedMessage)
      })
      .catch((error) => {
        const code = error.code
        const message = error.message
        const details = error.details
        console.log(code, message, details)
        // ...
      })
  } catch (err) {
    console.log(err)
  }
}
export default {
  firebaseConfig,
  firebaseApp,
  functions,
}
