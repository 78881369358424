<template>
  <v-container>
    <v-row class="pt-2">
      <h3>Accessories</h3>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler :path="`${path}.accessories.guttersAndDown`" />
      </v-col>
    </v-row>
    <v-row>
      <h4 class="pt-1 pl-2">Roof Insulation</h4>
      <v-col cols="12" class="pt-0 pb-2">
        <input-handler :path="`${path}.roofInsulation.bySteelTech`" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <input-handler
          :path="`${path}.roofInsulation.type`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
      <v-col cols="6">
        <input-handler :path="`${path}.roofInsulation.thickness`" />
      </v-col>
    </v-row>
    <v-row>
      <h4 class="pt-4 pl-2">Wall Insulation</h4>
      <v-col cols="12" class="pt-0 pb-2">
        <input-handler :path="`${path}.wallInsulation.bySteelTech`" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <input-handler
          :path="`${path}.wallInsulation.type`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
      <v-col cols="6">
        <input-handler :path="`${path}.wallInsulation.thickness`" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Accessories",

  props: {
    buildingIndex: String,
  },

  computed: {
    path() {
      return `buildings[${this.buildingIndex}]`
    },
  },

  components: {
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },
}
</script>
