<template>
  <v-app>
    <v-app-bar app color="gray" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Steel Tech Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/Logo Default.svg')"
          transition="scale-transition"
          width="40"
        />
        <v-toolbar-title
          ><router-link style="text-decoration: none; color: inherit" to="/"
            >Steel Tech</router-link
          ></v-toolbar-title
        >
      </div>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        v-if="this.$route.fullPath !== '/login'"
        to="/upload"
        >Upload</v-btn
      >
      <v-btn v-if="this.$route.fullPath !== '/login'" @click="signOut()"
        >Sign Out</v-btn
      >
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer absolute app color="primary" padless>
      <v-col class="text-center white--text py-2" cols="12">
        Copyright &copy; Steel Tech, All Rights Reserved -
        {{ currentYear }}
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions } from "vuex"
import "./styles/global.scss"

export default {
  name: "App",

  data: () => ({
    //
  }),

  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },

  methods: {
    ...mapActions({
      signOut: "auth/signOut",
    }),
  },
}
</script>
