<template>
  <v-container>
    <v-row>
      <h3>Loads</h3>
    </v-row>
    <v-row>
      <v-col cols="6">
        <input-handler :path="`project.loads.liveLoad`" :disabled="true" />
      </v-col>
      <v-col cols="6">
        <input-handler :path="`project.loads.liveLoadReducible`" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler :path="`project.loads.groundSnow`" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler
          :path="`buildings[${this.buildingIndex}].loads.collateralLoad`"
          :addRules="[(v) => v >= 1 || 'Must be greater than or equal to 1.']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <input-handler
          :path="`buildings[${this.buildingIndex}].loads.thermalFactor`"
          :addRules="[(v) => !!v || 'A thermal factor must be selected.']"
        />
      </v-col>
      <v-col cols="6">
        <input-handler
          :path="`buildings[${this.buildingIndex}].loads.slipperyRoof`"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <input-handler
          :path="`project.loads.windLoad`"
          :addRules="[
            (v) =>
              /(^(0{0,1}|([0-9]?[0-9][0-9]))?$)$/.test(v) ||
              'Values must be between 90 and 180.',
          ]"
        />
      </v-col>
      <v-col cols="6">
        <input-handler :path="`project.loads.windExposure`" :disabled="true" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler
          :path="`buildings[${this.buildingIndex}].loads.buildingUse`"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler
          :path="`buildings[${this.buildingIndex}].loads.buildingOccupancy`"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "Loads",

  props: {
    buildingIndex: String,
    globalLoads: Object,
    rules: Object,
  },

  components: {
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },

  methods: {
    ...mapActions({
      updateStateValue: "data/updateStateValue",
    }),
    setValue(object, value) {
      this.updateStateValue({ object, value })
    },
    updateSelect(value) {
      console.log(value)
    },
  },
}
</script>
