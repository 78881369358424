<template>
  <v-container fluid>
    <v-row
      v-for="(item, index) in items"
      :key="index"
      class="pb-3"
      style="height='20em'"
    >
      <v-col cols="12" md="auto">
        <input-handler :path="`${path}[${index}].fullWidth`" />
      </v-col>
      <v-col cols="12" md="auto">
        <input-handler
          v-if="type !== 'roof'"
          :path="`${path}[${index}].fullHeight`"
          :setValue="type === 'roof' ? 'true' : ''"
          :disabled="type === 'roof' ? true : false"
        />
      </v-col>
      <v-col cols="12" md="1">
        <input-handler
          :path="`${path}[${index}].start`"
          :disabled="item.fullWidth.value ? true : false"
          :setValue="item.fullWidth.value ? '1' : ''"
          :options="item.fullWidth.value ? ['1'] : options"
          :addRules="[
            (v) => !bayCheck(v) || 'Must be equal to or less than End',
            (v) => !!v || 'This field is required.',
          ]"
        />
      </v-col>
      <v-col cols="12" md="1">
        <input-handler
          :path="`${path}[${index}].end`"
          :disabled="item.fullWidth.value ? true : false"
          :setValue="
            item.fullWidth.value
              ? String(options[options.length - 1].value)
              : ''
          "
          :options="
            item.fullWidth.value
              ? [String(options[options.length - 1].value)]
              : options
          "
          :addRules="[
            (v) =>
              v >= item.start.value ||
              'Must be equal to or greater than Start.',
            (v) => !!v || 'This field is required.',
          ]"
        />
      </v-col>
      <v-col cols="12" md="2">
        <input-handler :path="`${path}[${index}].panel`" />
      </v-col>
      <v-col cols="12" md="2">
        <input-handler :path="`${path}[${index}].finish`" />
      </v-col>
      <v-col cols="12" md="3" v-if="type !== 'roof'">
        <input-handler
          :path="`${path}[${index}].linerHeight`"
          :disabled="item.fullHeight.value ? true : false"
          :setValue="item.fullHeight.value ? '0.0' : ''"
          :addRules="[
            (v) =>
              v <= peakHeight || 'Must be equal to or less than peak height.',
            (v) => !!v || 'This field is required.',
          ]"
        />
      </v-col>
    </v-row>
    <v-row class="pa-4" v-if="this.surfaces.length > 0">
      <v-col>
        <v-btn small color="accent" @click="addLiner(type, buildingIndex)"
          >Add</v-btn
        >
      </v-col>
      <v-col v-if="items.length > 0">
        <v-btn small color="secondary" @click="removeLiner(type, buildingIndex)"
          >Remove
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex"
import validate from "../../../utils/validation"

export default {
  name: "Liner",

  props: {
    type: String,
    items: Array,
  },

  computed: {
    ...mapState({
      buildings: (state) => state.data.buildings,
    }),
    buildingIndex() {
      return this.$route.params.buildingIndex
    },
    surfaces() {
      return this.buildings[this.buildingIndex][this.type].surfaceSpacings
    },
    path() {
      return `buildings[${this.buildingIndex}].${this.type}.liners`
    },
    building() {
      return this.buildings[this.buildingIndex]
    },
    leftEaveHeight() {
      return parseFloat(this.building.shape.heightLeft.value)
    },
    rightEaveHeight() {
      return parseFloat(this.building.shape.heightRight.value)
    },
    buildingPeakOffset() {
      return parseFloat(this.building.shape.peakOffset.value)
    },
    buildingSlope() {
      return parseFloat(this.building.shape.slope.value)
    },
    peakHeight() {
      return Math.max(
        this.leftEaveHeight,
        this.rightEaveHeight,
        this.leftEaveHeight +
          (this.buildingSlope / 12) * this.buildingPeakOffset
      )
    },
    options() {
      const surface =
        this.buildings[this.buildingIndex][this.type].surfaceSpacings
      const options = []
      let bays = 0
      if (surface.length > 0) {
        surface.forEach((bay) => {
          if (bay.numberOfBays.value !== "")
            bays = bays + parseInt(bay.numberOfBays.value)
        })
      }
      for (let i = 0; i < bays; i++) {
        let bay = i + 1
        const option = {
          display: bay,
          value: bay,
        }
        options.push(option)
      }
      return options
    },
  },

  components: {
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },

  methods: {
    ...mapActions({
      addValidLiner: "data/addLinerToArray",
      removeValidLiner: "data/removeLinerFromArray",
      updateStateValue: "data/updateStateValue",
    }),
    bayCheck(value) {
      return validate.bayValidation(
        this.buildings,
        this.buildingIndex,
        this.type,
        "liners",
        value
      )
    },

    async addLiner(type, buildingIndex) {
      await this.addValidLiner({ type, buildingIndex })
    },
    removeLiner(type, buildingIndex) {
      this.removeValidLiner({ type, buildingIndex })
    },
  },

  data: () => ({}),
}
</script>
