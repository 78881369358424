<template>
  <v-container fluid class="diagonal-split-background" fill-height>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-img
          :src="require('../assets/ST Logo with bckgrnd.jpg')"
          class="my-3"
          contain
          height="100"
        />
        <h1 class="display-2 font-weight-bold mb-3">Dealer Sheet</h1>
        <p class="subheading font-weight-regular">
          Steel Tech Building Systems designs & manufactures pre-engineered
          metal buildings,
          <br />
          driven by an intentional, no-compromise passion for excellence.
        </p>
      </v-col>
    </v-row>
    <v-row class="px-15">
      <v-col cols="12" md="6">
        <v-row align="center" justify="center">
          <h1 class="primary--text">Custom Start</h1>
        </v-row>
        <v-row align="center" justify="center">
          <v-card width="400">
            <v-card-text class="text-h6 font-weight-bold">
              For creating a project from scratch, use Create New Project. This
              creates a new project without applying a template.
              <br />
              <br />
              If you have previously started a project on this device that is
              unfinished, use Resume Project.
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col>
                  <v-btn text color="primary" @click="uploadToState()"
                    >Create New Project
                    <v-icon>mdi-arrow-right-thin</v-icon></v-btn
                  >
                </v-col>
                <v-col align-self="end">
                  <v-btn text color="primary" to="/quote/project"
                    >Resume Project <v-icon>mdi-arrow-right-thin</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="px-15">
      <v-col cols="0" md="6"></v-col>
      <v-col cols="12" md="6">
        <v-row align="center" justify="center">
          <h1 class="black--text">Use a Template</h1>
        </v-row>
        <v-row>
          <Templates />
        </v-row>
      </v-col>
    </v-row>
    <v-alert type="success" :value="buildingSubmitted">
      Your building has been successfully submitted.
    </v-alert>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex"
import Templates from "./form/Templates"

export default {
  name: "Index",

  components: {
    Templates,
  },

  methods: {
    ...mapActions({
      resetState: "data/resetState",
      setObject: "form/setObject",
    }),
    uploadToState() {
      return this.resetState("/quote/project")
    },
    delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time))
    },
  },

  data: () => ({}),

  mounted() {
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded")
    } else {
      localStorage.setItem("reloaded", "1")
      location.reload()
    }
    if (this.buildingSubmitted) {
      this.delay(5000)
      this.setObject({ value: false, path: "buildingSubmitted" })
    }
  },

  computed: {
    ...mapState({
      buildingSubmitted: (state) => state.form.buildingSubmitted,
    }),
  },
}
</script>

<style lang="scss" scoped></style>
