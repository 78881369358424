<template>
  <v-container class="pb-5" v-if="options !== 1">
    <v-row class="pt-1">
      <!-- <v-col cols="6" class="pt-0">
        <h3>Rigid Frames</h3>
      </v-col> -->
      <v-col md="6" cols="12" class="pt-0">
        <h3>Interior Columns</h3>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col cols="6">
        <v-row>
          <v-col>
            <input-handler :path="`${path}.locations`" />
          </v-col>
        </v-row>
      </v-col> -->
      <v-col cols="12" md="6">
        <v-row>
          <v-col md="2" cols="12">
            <input-handler :path="`${path}.interiorColumn.elevation`" />
          </v-col>
          <v-col md="8" cols="12">
            <input-handler
              :path="`${path}.interiorColumn.locations`"
              :addRules="locationRules"
              :error-messages="errorMessages"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "RigidFrames",

  props: {
    buildingIndex: String,
  },

  data: () => ({
    errorMessages: "",
  }),

  computed: {
    ...mapState({
      buildings: (state) => state.data.buildings,
    }),
    path() {
      return `buildings[${this.buildingIndex}].rigidFrame`
    },
    options() {
      const surface = this.buildings[this.buildingIndex].roof.surfaceSpacings
      let bays = 0
      surface.forEach((bay) => {
        bays = bays + parseInt(bay.numberOfBays.value)
      })
      return bays
    },
    locationRules() {
      return [(v) => this.validateLocations(v)]
    },
  },

  methods: {
    validateLocations(str) {
      let split = str.split(" ")
      let splitNum = split.map((s) => {
        return parseInt(s)
      })
      splitNum.forEach((s1) => {
        if (
          parseInt(this.buildings[this.buildingIndex].shape.width.value) - s1 <
          5
        ) {
          this.errorMessages = "Location must be less than building width by 5"
        } else this.errorMessages = ""
      })
      return true
    },
  },

  components: {
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },
}
</script>
