export default {
  rules(state) {
    return {
      validLength: (v) =>
        (!!v && state.rules.length.test(v)) ||
        "Must be in feet'inches\" format",
      validEmail: (v) =>
        (!!v && state.rules.emailRegex.test(v)) || "Email must be a valid",
      validPhoneNumber: (v) =>
        (!!v && state.rules.phoneNumberRegex.test(v)) ||
        "Phone Number must be valid",
    }
  },
}
