export default {
  templates: [
    {
      buildings: [
        {
          accessories: {
            guttersAndDown: {
              displayName: "Gutters & Downspouts",
              type: "BOOLEAN",
              value: false,
            },
          },
          backSidewall: {
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "2",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          baseCondition: {
            baseTrim: {
              displayName: "Base Trim",
              options: [
                {
                  display: "Base Trim",
                  value: "baseTrim",
                },
                {
                  display: "Return Base",
                  value: "ratGuard",
                },
                {
                  display: "Notch, No Trim",
                  value: "notchNoTrim",
                },
                {
                  display: "No Trim or Notch",
                  value: "noTrimOrNotch",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "baseTrim",
            },
            slabOrPiers: {
              displayName: "Slab",
              options: [
                {
                  display: "Slab",
                  value: "slab",
                },
                {
                  display: "Piers",
                  value: "piers",
                },
              ],
              type: "RADIO_BUTTON",
              value: "slab",
            },
          },
          columnBaseElevation: {
            backSidewall: {
              displayName: "Back Sidewall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
            frontSidewall: {
              displayName: "Front Sidewall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
            leftEndwall: {
              displayName: "Left Endwall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
            rightEndwall: {
              displayName: "Right Endwall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
          },
          exteriorPanels: {
            roofFinish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "galv",
            },
            roofPanel: {
              displayName: "Panel",
              options: [
                {
                  display: "Trapezoidal Standing Seam",
                  value: "standingSeamTrapezoidal",
                },
                {
                  display: "Vertical Standing Seam ",
                  value: "standingSeamVertical",
                },
                {
                  display: "PBR 26",
                  value: "pbr26",
                },
                {
                  display: "PBR 24",
                  value: "pbr24",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "pbr26",
            },
            wallFinish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "color",
            },
            wallPanel: {
              displayName: "Panel",
              options: [
                {
                  display: "PBR 26",
                  value: "pbr26",
                },
                {
                  display: "PBR 24",
                  value: "pbr24",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "pbr26",
            },
          },
          framedOpening: {
            baseElevation: {
              displayName: "Base Elevation",
              suffix: "ft",
              type: "ELEVATION",
              value: "",
            },
            bay: {
              displayName: "Bay",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            framingType: {
              displayName: "Framing Type",
              options: [
                {
                  display: "No Framing",
                  value: "noFraming",
                },
                {
                  display: "Jamb, Base to Girt",
                  value: "jambBaseToGirt",
                },
                {
                  display: "Jamb, Base to Roof",
                  value: "jambBaseToRoof",
                },
                {
                  display: "Jamb, Girt to Girt",
                  value: "jambGirtToGirt",
                },
                {
                  display: "Open Bay",
                  value: "openBay",
                },
                {
                  display: "Jamb, Base to Added Girt",
                  value: "jambBaseToAddedGirt",
                },
                {
                  display: "Strip Window",
                  value: "stripWindow",
                },
                {
                  display: "Jamb, Girt to Girt (No Girt Cut)",
                  value: "jambGirtToGirtNoGirtCut",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            headerHeight: {
              displayName: "Header Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            offset: {
              displayName: "Offset",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            openingType: {
              displayName: "Opening Type",
              options: [
                {
                  display: "Walk Door",
                  value: "walkDoor",
                },
                {
                  display: "Self Flashing Window",
                  value: "selfFlashingWindow",
                },
                {
                  display: "Standard Window",
                  value: "standardWindow",
                },
                {
                  display: "Overhead Door",
                  value: "overheadDoor",
                },
                {
                  display: "IS Roll-Up Door",
                  value: "isRollUpDoor",
                },
                {
                  display: "OS Roll-Up Door",
                  value: "osRollUpDoor",
                },
                {
                  display: "Louver",
                  value: "louver",
                },
                {
                  display: "Overhead Door Blocking",
                  value: "overheadDoorBlocking",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            panelOption: {
              displayName: "Panel Option",
              options: [
                {
                  display: "Sheet Over Opening",
                  value: "sheetOverOpening",
                },
                {
                  display: "Remove Panel",
                  value: "removePanel",
                },
                {
                  display: "Field Located",
                  value: "fieldLocated",
                },
                {
                  display: "Girts in Opening",
                  value: "girtsInOpening",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            sillHeight: {
              displayName: "Sill Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            width: {
              displayName: "Width",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
          },
          frontSidewall: {
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "2",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          initiated: true,
          leftEndwall: {
            mirroredBays: {
              type: "BOOLEAN",
              displayName: "Mirrored",
              value: "",
            },
            expandable: {
              displayName: "Expandable",
              type: "BOOLEAN",
              value: "",
            },
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "2",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "15",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          liner: {
            end: {
              displayName: "End",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            finish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "color",
            },
            fullHeight: {
              displayName: "Full Height",
              type: "BOOLEAN",
              value: "",
            },
            fullWidth: {
              displayName: "Full Width",
              type: "BOOLEAN",
              value: "",
            },
            linerHeight: {
              displayName: "Liner Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            panel: {
              displayName: "Panel",
              options: [
                {
                  display: "PBR-Panel",
                  value: "pbrPanel",
                },
                {
                  display: "Rev-PBR Panel",
                  value: "revPbrPanel",
                },
                {
                  display: "U Panel",
                  value: "uPanel",
                },
                {
                  display: "AG Panel",
                  value: "agPanel",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            start: {
              displayName: "Start",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          loads: {
            buildingUse: {
              displayName: "Building Use",
              type: "TEXT",
              value: "",
            },
            buildingOccupancy: {
              type: "STANDARD_DROPDOWN",
              displayName: "Building Occupancy",
              value: "",
              options: [
                {
                  display: "I - Low, Storage, Warehouse, or Agri",
                  value: "i",
                },
                {
                  display: "II - Normal, Standard Building",
                  value: "ii",
                },
                {
                  display: "III - High Schools, Large Occupancy",
                  value: "iii",
                },
                {
                  display: "IV - Post Essential (Fire Dept., Hospital)",
                  value: "iv",
                },
              ],
            },
            collateralLoad: {
              displayName: "Collateral Load",
              type: "FLOAT",
              value: "1.0",
            },
            slipperyRoof: {
              displayName: "Slippery Roof",
              type: "BOOLEAN",
              value: "",
            },
            thermalFactor: {
              displayName: "Thermal Factor",
              options: [
                {
                  display: "Heated",
                  value: "heated",
                },
                {
                  display: "Unheated",
                  value: "unheated",
                },
                {
                  display: "Greenhouse",
                  value: "greenhouse",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "unheated",
            },
          },
          notes: {
            buildingNotes: {
              displayName: "Building Notes",
              type: "AREA_TEXT",
              value: "",
            },
          },
          rightEndwall: {
            mirroredBays: {
              type: "BOOLEAN",
              displayName: "Mirrored",
              value: "",
            },
            expandable: {
              displayName: "Expandable",
              type: "BOOLEAN",
              value: "",
            },
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "2",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "15",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          rigidFrame: {
            column: {
              displayName: "Column",
              options: [
                {
                  display: "Tapered",
                  value: "tapered",
                },
                {
                  display: "Constant",
                  value: "constant",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            interiorColumn: {
              elevation: {
                displayName: "Elevation",
                suffix: "in",
                type: "ELEVATION",
                value: "-8",
              },
              locations: {
                displayName: "Locations",
                type: "TEXT",
                value: "",
              },
            },
            locations: {
              displayName: "Locations",
              type: "NUMBER",
              value: "",
            },
            rafter: {
              displayName: "Rafter",
              options: [
                {
                  display: "Tapered",
                  value: "tapered",
                },
                {
                  display: "Constant",
                  value: "constant",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          roof: {
            framedOpenings: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "2",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
          },
          roofInsulation: {
            bySteelTech: {
              displayName: "Steel Tech",
              type: "BOOLEAN",
              value: "",
            },
            thickness: {
              displayName: "Thickness",
              suffix: "in",
              type: "NUMBER",
              value: "",
            },
            type: {
              displayName: "Type",
              options: [
                {
                  display: "None",
                  value: "none",
                },
                {
                  display: "Single Layer",
                  value: "singleLayer",
                },
                {
                  display: "System",
                  value: "system",
                },
                {
                  display: "Spray Foam",
                  value: "sprayFoam",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          shape: {
            heightLeft: {
              displayName: "Left Eave Height",
              suffix: "ft",
              type: "LENGTH",
              value: "10",
            },
            heightRight: {
              displayName: "Right Eave Height",
              suffix: "ft",
              type: "LENGTH",
              value: "10",
            },
            length: {
              displayName: "Length",
              suffix: "ft",
              type: "SPECIAL_CASE",
              value: "40",
            },
            peakOffset: {
              displayName: "Peak Offset",
              suffix: "ft",
              type: "LENGTH",
              value: 15,
            },
            shape: {
              displayName: "Shape",
              options: [
                {
                  display: "Single Slope",
                  value: "singleSlope",
                },
                {
                  display: "Symmetrical Double Slope",
                  value: "symmetricalDoubleSlope",
                },
                {
                  display: "Asymmetrical Double Slope",
                  value: "asymmetricalDoubleSlope",
                },
              ],
              required: true,
              type: "STANDARD_DROPDOWN",
              value: "symmetricalDoubleSlope",
            },
            slope: {
              displayName: "Slope",
              type: "FLOAT",
              value: "2",
            },
            slopeRight: {
              displayName: "Right Slope",
              type: "FLOAT",
              value: "2",
            },
            width: {
              displayName: "Width",
              suffix: "ft",
              type: "SPECIAL_CASE",
              value: "30",
            },
          },
          surfaceSpacing: {
            numberOfBays: {
              displayName: "# Bays",
              type: "number",
              value: "",
            },
            openCondition: {
              displayName: "Open Condition",
              type: "TEXT",
              value: "",
            },
            openHeight: {
              displayName: "Open Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            width: {
              displayName: "Width",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
          },
          wainscot: {
            end: {
              displayName: "End",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            finish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            fullHeight: {
              displayName: "Full Height",
              type: "BOOLEAN",
              value: "",
            },
            fullWidth: {
              displayName: "Full Width",
              type: "BOOLEAN",
              value: "",
            },
            height: {
              displayName: "Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            panel: {
              displayName: "Panel",
              options: [
                {
                  display: "PBR-Panel",
                  value: "pbrPanel",
                },
                {
                  display: "Rev-PBR Panel",
                  value: "revPbrPanel",
                },
                {
                  display: "U Panel",
                  value: "uPanel",
                },
                {
                  display: "AG Panel",
                  value: "agPanel",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            start: {
              displayName: "Start",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          walkDoor: {
            accessories: {
              closer: {
                displayName: "Closer",
                type: "BOOLEAN",
                value: "",
              },
              deadBolt: {
                displayName: "Dead Bolt",
                type: "BOOLEAN",
                value: "",
              },
              kickPlate: {
                displayName: "Kick Plate",
                type: "BOOLEAN",
                value: "",
              },
              latchGuard: {
                displayName: "Latch Guard",
                type: "BOOLEAN",
                value: "",
              },
              panicHardware: {
                displayName: "Panic Hardware",
                type: "BOOLEAN",
                value: "",
              },
              window: {
                displayName: "Window",
                options: [
                  {
                    display: "None",
                    value: "none",
                  },
                  {
                    display: "Uninsulated",
                    value: "uninsulated",
                  },
                  {
                    display: "Insulated",
                    value: "insulated",
                  },
                ],
                type: "STANDARD_DROPDOWN",
                value: "",
              },
            },
            bay: {
              displayName: "Bay",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            offset: {
              displayName: "Offset",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            size: {
              displayName: "Size",
              options: [
                {
                  display: "3x7",
                  value: "3x7",
                },
                {
                  display: "4x7",
                  value: "4x7",
                },
                {
                  display: "6x7",
                  value: "6x7",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          wallInsulation: {
            bySteelTech: {
              displayName: "Steel Tech",
              type: "BOOLEAN",
              value: "",
            },
            thickness: {
              displayName: "Thickness",
              suffix: "in",
              type: "NUMBER",
              value: "",
            },
            type: {
              displayName: "Type",
              options: [
                {
                  display: "None",
                  value: "none",
                },
                {
                  display: "Single Layer",
                  value: "singleLayer",
                },
                {
                  display: "System",
                  value: "system",
                },
                {
                  display: "Spray Foam",
                  value: "sprayFoam",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
        },
      ],
      project: {
        loads: {
          groundSnow: {
            displayName: "Ground Snow",
            suffix: "psf",
            type: "FLOAT",
            value: "",
          },
          groundSnowReducible: {
            displayName: "Reducible",
            type: "BOOLEAN",
            value: "",
          },
          liveLoad: {
            displayName: "Live Load",
            suffix: "psf",
            type: "FLOAT",
            value: "20",
          },
          liveLoadReducible: {
            displayName: "Reducible",
            type: "BOOLEAN",
            value: true,
          },
          windExposure: {
            displayName: "Wind Exposure",
            options: [
              {
                display: "B",
                value: "b",
              },
              {
                display: "C",
                value: "c",
              },
              {
                display: "D",
                value: "d",
              },
            ],
            type: "STANDARD_DROPDOWN",
            value: "c",
          },
          windLoad: {
            displayName: "Wind Load",
            suffix: "mph",
            type: "FLOAT",
            value: "",
          },
        },
        primaryInfo: {
          dealerInfo: {
            address: {
              displayName: "Address",
              type: "TEXT",
              value: "",
            },
            city: {
              displayName: "City",
              type: "TEXT",
              value: "",
            },
            email: {
              displayName: "Email",
              type: "email",
              value: "",
            },
            name: {
              displayName: "Name",
              required: true,
              type: "TEXT",
              value: "",
            },
            phone: {
              displayName: "Phone",
              type: "PHONE",
              value: "",
            },
            state: {
              displayName: "State",
              type: "STATES_DROPDOWN",
              value: null,
            },
            zip: {
              displayName: "Zip",
              type: "number",
              value: "",
            },
          },
          projectInfo: {
            address: {
              displayName: "Address",
              type: "TEXT",
              value: "",
            },
            city: {
              displayName: "City",
              type: "TEXT",
              value: "",
            },
            contactEmail: {
              displayName: "Email",
              required: true,
              type: "email",
              value: "",
            },
            contactName: {
              displayName: "Name",
              required: true,
              type: "TEXT",
              value: "",
            },
            contactNumber: {
              displayName: "Phone",
              required: true,
              type: "PHONE",
              value: "",
            },
            projectName: {
              displayName: "Project Name",
              required: true,
              type: "TEXT",
              value: "30'-0\"x40'-0\"x10'-0\"",
            },
            state: {
              displayName: "State",
              type: "STATES_DROPDOWN",
              value: null,
            },
            zip: {
              displayName: "Zip",
              type: "number",
              value: "",
            },
          },
        },
      },
    },
    {
      buildings: [
        {
          accessories: {
            guttersAndDown: {
              displayName: "Gutters & Downspouts",
              type: "BOOLEAN",
              value: false,
            },
          },
          backSidewall: {
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "3",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          baseCondition: {
            baseTrim: {
              displayName: "Base Trim",
              options: [
                {
                  display: "Base Trim",
                  value: "baseTrim",
                },
                {
                  display: "Return Base",
                  value: "ratGuard",
                },
                {
                  display: "Notch, No Trim",
                  value: "notchNoTrim",
                },
                {
                  display: "No Trim or Notch",
                  value: "noTrimOrNotch",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "baseTrim",
            },
            slabOrPiers: {
              displayName: "Slab",
              options: [
                {
                  display: "Slab",
                  value: "slab",
                },
                {
                  display: "Piers",
                  value: "piers",
                },
              ],
              type: "RADIO_BUTTON",
              value: "slab",
            },
          },
          columnBaseElevation: {
            backSidewall: {
              displayName: "Back Sidewall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
            frontSidewall: {
              displayName: "Front Sidewall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
            leftEndwall: {
              displayName: "Left Endwall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
            rightEndwall: {
              displayName: "Right Endwall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
          },
          exteriorPanels: {
            roofFinish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "galv",
            },
            roofPanel: {
              displayName: "Panel",
              options: [
                {
                  display: "Trapezoidal Standing Seam",
                  value: "standingSeamTrapezoidal",
                },
                {
                  display: "Vertical Standing Seam ",
                  value: "standingSeamVertical",
                },
                {
                  display: "PBR 26",
                  value: "pbr26",
                },
                {
                  display: "PBR 24",
                  value: "pbr24",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "pbr26",
            },
            wallFinish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "color",
            },
            wallPanel: {
              displayName: "Panel",
              options: [
                {
                  display: "PBR 26",
                  value: "pbr26",
                },
                {
                  display: "PBR 24",
                  value: "pbr24",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "pbr26",
            },
          },
          framedOpening: {
            baseElevation: {
              displayName: "Base Elevation",
              suffix: "ft",
              type: "ELEVATION",
              value: "",
            },
            bay: {
              displayName: "Bay",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            framingType: {
              displayName: "Framing Type",
              options: [
                {
                  display: "No Framing",
                  value: "noFraming",
                },
                {
                  display: "Jamb, Base to Girt",
                  value: "jambBaseToGirt",
                },
                {
                  display: "Jamb, Base to Roof",
                  value: "jambBaseToRoof",
                },
                {
                  display: "Jamb, Girt to Girt",
                  value: "jambGirtToGirt",
                },
                {
                  display: "Open Bay",
                  value: "openBay",
                },
                {
                  display: "Jamb, Base to Added Girt",
                  value: "jambBaseToAddedGirt",
                },
                {
                  display: "Strip Window",
                  value: "stripWindow",
                },
                {
                  display: "Jamb, Girt to Girt (No Girt Cut)",
                  value: "jambGirtToGirtNoGirtCut",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            headerHeight: {
              displayName: "Header Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            offset: {
              displayName: "Offset",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            openingType: {
              displayName: "Opening Type",
              options: [
                {
                  display: "Walk Door",
                  value: "walkDoor",
                },
                {
                  display: "Self Flashing Window",
                  value: "selfFlashingWindow",
                },
                {
                  display: "Standard Window",
                  value: "standardWindow",
                },
                {
                  display: "Overhead Door",
                  value: "overheadDoor",
                },
                {
                  display: "IS Roll-Up Door",
                  value: "isRollUpDoor",
                },
                {
                  display: "OS Roll-Up Door",
                  value: "osRollUpDoor",
                },
                {
                  display: "Louver",
                  value: "louver",
                },
                {
                  display: "Overhead Door Blocking",
                  value: "overheadDoorBlocking",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            panelOption: {
              displayName: "Panel Option",
              options: [
                {
                  display: "Sheet Over Opening",
                  value: "sheetOverOpening",
                },
                {
                  display: "Remove Panel",
                  value: "removePanel",
                },
                {
                  display: "Field Located",
                  value: "fieldLocated",
                },
                {
                  display: "Girts in Opening",
                  value: "girtsInOpening",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            sillHeight: {
              displayName: "Sill Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            width: {
              displayName: "Width",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
          },
          frontSidewall: {
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "3",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          initiated: true,
          leftEndwall: {
            mirroredBays: {
              type: "BOOLEAN",
              displayName: "Mirrored",
              value: "",
            },
            expandable: {
              displayName: "Expandable",
              type: "BOOLEAN",
              value: "",
            },
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "2",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          liner: {
            end: {
              displayName: "End",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            finish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "color",
            },
            fullHeight: {
              displayName: "Full Height",
              type: "BOOLEAN",
              value: "",
            },
            fullWidth: {
              displayName: "Full Width",
              type: "BOOLEAN",
              value: "",
            },
            linerHeight: {
              displayName: "Liner Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            panel: {
              displayName: "Panel",
              options: [
                {
                  display: "PBR-Panel",
                  value: "pbrPanel",
                },
                {
                  display: "Rev-PBR Panel",
                  value: "revPbrPanel",
                },
                {
                  display: "U Panel",
                  value: "uPanel",
                },
                {
                  display: "AG Panel",
                  value: "agPanel",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            start: {
              displayName: "Start",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          loads: {
            buildingUse: {
              displayName: "Building Use",
              type: "TEXT",
              value: "",
            },
            buildingOccupancy: {
              type: "STANDARD_DROPDOWN",
              displayName: "Building Occupancy",
              value: "",
              options: [
                {
                  display: "I - Low, Storage, Warehouse, or Agri",
                  value: "i",
                },
                {
                  display: "II - Normal, Standard Building",
                  value: "ii",
                },
                {
                  display: "III - High Schools, Large Occupancy",
                  value: "iii",
                },
                {
                  display: "IV - Post Essential (Fire Dept., Hospital)",
                  value: "iv",
                },
              ],
            },
            collateralLoad: {
              displayName: "Collateral Load",
              type: "FLOAT",
              value: "1.0",
            },
            slipperyRoof: {
              displayName: "Slippery Roof",
              type: "BOOLEAN",
              value: "",
            },
            thermalFactor: {
              displayName: "Thermal Factor",
              options: [
                {
                  display: "Heated",
                  value: "heated",
                },
                {
                  display: "Unheated",
                  value: "unheated",
                },
                {
                  display: "Greenhouse",
                  value: "greenhouse",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "unheated",
            },
          },
          notes: {
            buildingNotes: {
              displayName: "Building Notes",
              type: "AREA_TEXT",
              value: "",
            },
          },
          rightEndwall: {
            mirroredBays: {
              type: "BOOLEAN",
              displayName: "Mirrored",
              value: "",
            },
            expandable: {
              displayName: "Expandable",
              type: "BOOLEAN",
              value: "",
            },
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "2",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          rigidFrame: {
            column: {
              displayName: "Column",
              options: [
                {
                  display: "Tapered",
                  value: "tapered",
                },
                {
                  display: "Constant",
                  value: "constant",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            interiorColumn: {
              elevation: {
                displayName: "Elevation",
                suffix: "in",
                type: "ELEVATION",
                value: "-8",
              },
              locations: {
                displayName: "Locations",
                type: "TEXT",
                value: "",
              },
            },
            locations: {
              displayName: "Locations",
              type: "NUMBER",
              value: "",
            },
            rafter: {
              displayName: "Rafter",
              options: [
                {
                  display: "Tapered",
                  value: "tapered",
                },
                {
                  display: "Constant",
                  value: "constant",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          roof: {
            framedOpenings: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "3",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
          },
          roofInsulation: {
            bySteelTech: {
              displayName: "Steel Tech",
              type: "BOOLEAN",
              value: "",
            },
            thickness: {
              displayName: "Thickness",
              suffix: "in",
              type: "NUMBER",
              value: "",
            },
            type: {
              displayName: "Type",
              options: [
                {
                  display: "None",
                  value: "none",
                },
                {
                  display: "Single Layer",
                  value: "singleLayer",
                },
                {
                  display: "System",
                  value: "system",
                },
                {
                  display: "Spray Foam",
                  value: "sprayFoam",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          shape: {
            heightLeft: {
              displayName: "Left Eave Height",
              suffix: "ft",
              type: "LENGTH",
              value: "12",
            },
            heightRight: {
              displayName: "Right Eave Height",
              suffix: "ft",
              type: "LENGTH",
              value: "12",
            },
            length: {
              displayName: "Length",
              suffix: "ft",
              type: "SPECIAL_CASE",
              value: "60",
            },
            peakOffset: {
              displayName: "Peak Offset",
              suffix: "ft",
              type: "LENGTH",
              value: 20,
            },
            shape: {
              displayName: "Shape",
              options: [
                {
                  display: "Single Slope",
                  value: "singleSlope",
                },
                {
                  display: "Symmetrical Double Slope",
                  value: "symmetricalDoubleSlope",
                },
                {
                  display: "Asymmetrical Double Slope",
                  value: "asymmetricalDoubleSlope",
                },
              ],
              required: true,
              type: "STANDARD_DROPDOWN",
              value: "symmetricalDoubleSlope",
            },
            slope: {
              displayName: "Slope",
              type: "FLOAT",
              value: "2",
            },
            slopeRight: {
              displayName: "Right Slope",
              type: "FLOAT",
              value: "2",
            },
            width: {
              displayName: "Width",
              suffix: "ft",
              type: "SPECIAL_CASE",
              value: "40",
            },
          },
          surfaceSpacing: {
            numberOfBays: {
              displayName: "# Bays",
              type: "number",
              value: "",
            },
            openCondition: {
              displayName: "Open Condition",
              type: "TEXT",
              value: "",
            },
            openHeight: {
              displayName: "Open Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            width: {
              displayName: "Width",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
          },
          wainscot: {
            end: {
              displayName: "End",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            finish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            fullHeight: {
              displayName: "Full Height",
              type: "BOOLEAN",
              value: "",
            },
            fullWidth: {
              displayName: "Full Width",
              type: "BOOLEAN",
              value: "",
            },
            height: {
              displayName: "Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            panel: {
              displayName: "Panel",
              options: [
                {
                  display: "PBR-Panel",
                  value: "pbrPanel",
                },
                {
                  display: "Rev-PBR Panel",
                  value: "revPbrPanel",
                },
                {
                  display: "U Panel",
                  value: "uPanel",
                },
                {
                  display: "AG Panel",
                  value: "agPanel",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            start: {
              displayName: "Start",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          walkDoor: {
            accessories: {
              closer: {
                displayName: "Closer",
                type: "BOOLEAN",
                value: "",
              },
              deadBolt: {
                displayName: "Dead Bolt",
                type: "BOOLEAN",
                value: "",
              },
              kickPlate: {
                displayName: "Kick Plate",
                type: "BOOLEAN",
                value: "",
              },
              latchGuard: {
                displayName: "Latch Guard",
                type: "BOOLEAN",
                value: "",
              },
              panicHardware: {
                displayName: "Panic Hardware",
                type: "BOOLEAN",
                value: "",
              },
              window: {
                displayName: "Window",
                options: [
                  {
                    display: "None",
                    value: "none",
                  },
                  {
                    display: "Uninsulated",
                    value: "uninsulated",
                  },
                  {
                    display: "Insulated",
                    value: "insulated",
                  },
                ],
                type: "STANDARD_DROPDOWN",
                value: "",
              },
            },
            bay: {
              displayName: "Bay",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            offset: {
              displayName: "Offset",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            size: {
              displayName: "Size",
              options: [
                {
                  display: "3x7",
                  value: "3x7",
                },
                {
                  display: "4x7",
                  value: "4x7",
                },
                {
                  display: "6x7",
                  value: "6x7",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          wallInsulation: {
            bySteelTech: {
              displayName: "Steel Tech",
              type: "BOOLEAN",
              value: "",
            },
            thickness: {
              displayName: "Thickness",
              suffix: "in",
              type: "NUMBER",
              value: "",
            },
            type: {
              displayName: "Type",
              options: [
                {
                  display: "None",
                  value: "none",
                },
                {
                  display: "Single Layer",
                  value: "singleLayer",
                },
                {
                  display: "System",
                  value: "system",
                },
                {
                  display: "Spray Foam",
                  value: "sprayFoam",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
        },
      ],
      project: {
        loads: {
          groundSnow: {
            displayName: "Ground Snow",
            suffix: "psf",
            type: "FLOAT",
            value: "",
          },
          groundSnowReducible: {
            displayName: "Reducible",
            type: "BOOLEAN",
            value: "",
          },
          liveLoad: {
            displayName: "Live Load",
            suffix: "psf",
            type: "FLOAT",
            value: "20",
          },
          liveLoadReducible: {
            displayName: "Reducible",
            type: "BOOLEAN",
            value: true,
          },
          windExposure: {
            displayName: "Wind Exposure",
            options: [
              {
                display: "B",
                value: "b",
              },
              {
                display: "C",
                value: "c",
              },
              {
                display: "D",
                value: "d",
              },
            ],
            type: "STANDARD_DROPDOWN",
            value: "c",
          },
          windLoad: {
            displayName: "Wind Load",
            suffix: "mph",
            type: "FLOAT",
            value: "",
          },
        },
        primaryInfo: {
          dealerInfo: {
            address: {
              displayName: "Address",
              type: "TEXT",
              value: "",
            },
            city: {
              displayName: "City",
              type: "TEXT",
              value: "",
            },
            email: {
              displayName: "Email",
              type: "email",
              value: "",
            },
            name: {
              displayName: "Name",
              required: true,
              type: "TEXT",
              value: "",
            },
            phone: {
              displayName: "Phone",
              type: "PHONE",
              value: "",
            },
            state: {
              displayName: "State",
              type: "STATES_DROPDOWN",
              value: null,
            },
            zip: {
              displayName: "Zip",
              type: "number",
              value: "",
            },
          },
          projectInfo: {
            address: {
              displayName: "Address",
              type: "TEXT",
              value: "",
            },
            city: {
              displayName: "City",
              type: "TEXT",
              value: "",
            },
            contactEmail: {
              displayName: "Email",
              required: true,
              type: "email",
              value: "",
            },
            contactName: {
              displayName: "Name",
              required: true,
              type: "TEXT",
              value: "",
            },
            contactNumber: {
              displayName: "Phone",
              required: true,
              type: "PHONE",
              value: "",
            },
            projectName: {
              displayName: "Project Name",
              required: true,
              type: "TEXT",
              value: "40'-0\"x60'-0\"x12'-0\"",
            },
            state: {
              displayName: "State",
              type: "STATES_DROPDOWN",
              value: null,
            },
            zip: {
              displayName: "Zip",
              type: "number",
              value: "",
            },
          },
        },
      },
    },
    {
      buildings: [
        {
          accessories: {
            guttersAndDown: {
              displayName: "Gutters & Downspouts",
              type: "BOOLEAN",
              value: false,
            },
          },
          backSidewall: {
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "4",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          baseCondition: {
            baseTrim: {
              displayName: "Base Trim",
              options: [
                {
                  display: "Base Trim",
                  value: "baseTrim",
                },
                {
                  display: "Return Base",
                  value: "ratGuard",
                },
                {
                  display: "Notch, No Trim",
                  value: "notchNoTrim",
                },
                {
                  display: "No Trim or Notch",
                  value: "noTrimOrNotch",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "baseTrim",
            },
            slabOrPiers: {
              displayName: "Slab",
              options: [
                {
                  display: "Slab",
                  value: "slab",
                },
                {
                  display: "Piers",
                  value: "piers",
                },
              ],
              type: "RADIO_BUTTON",
              value: "slab",
            },
          },
          columnBaseElevation: {
            backSidewall: {
              displayName: "Back Sidewall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
            frontSidewall: {
              displayName: "Front Sidewall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
            leftEndwall: {
              displayName: "Left Endwall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
            rightEndwall: {
              displayName: "Right Endwall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
          },
          exteriorPanels: {
            roofFinish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "galv",
            },
            roofPanel: {
              displayName: "Panel",
              options: [
                {
                  display: "Trapezoidal Standing Seam",
                  value: "standingSeamTrapezoidal",
                },
                {
                  display: "Vertical Standing Seam ",
                  value: "standingSeamVertical",
                },
                {
                  display: "PBR 26",
                  value: "pbr26",
                },
                {
                  display: "PBR 24",
                  value: "pbr24",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "pbr26",
            },
            wallFinish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "color",
            },
            wallPanel: {
              displayName: "Panel",
              options: [
                {
                  display: "PBR 26",
                  value: "pbr26",
                },
                {
                  display: "PBR 24",
                  value: "pbr24",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "pbr26",
            },
          },
          framedOpening: {
            baseElevation: {
              displayName: "Base Elevation",
              suffix: "ft",
              type: "ELEVATION",
              value: "",
            },
            bay: {
              displayName: "Bay",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            framingType: {
              displayName: "Framing Type",
              options: [
                {
                  display: "No Framing",
                  value: "noFraming",
                },
                {
                  display: "Jamb, Base to Girt",
                  value: "jambBaseToGirt",
                },
                {
                  display: "Jamb, Base to Roof",
                  value: "jambBaseToRoof",
                },
                {
                  display: "Jamb, Girt to Girt",
                  value: "jambGirtToGirt",
                },
                {
                  display: "Open Bay",
                  value: "openBay",
                },
                {
                  display: "Jamb, Base to Added Girt",
                  value: "jambBaseToAddedGirt",
                },
                {
                  display: "Strip Window",
                  value: "stripWindow",
                },
                {
                  display: "Jamb, Girt to Girt (No Girt Cut)",
                  value: "jambGirtToGirtNoGirtCut",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            headerHeight: {
              displayName: "Header Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            offset: {
              displayName: "Offset",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            openingType: {
              displayName: "Opening Type",
              options: [
                {
                  display: "Walk Door",
                  value: "walkDoor",
                },
                {
                  display: "Self Flashing Window",
                  value: "selfFlashingWindow",
                },
                {
                  display: "Standard Window",
                  value: "standardWindow",
                },
                {
                  display: "Overhead Door",
                  value: "overheadDoor",
                },
                {
                  display: "IS Roll-Up Door",
                  value: "isRollUpDoor",
                },
                {
                  display: "OS Roll-Up Door",
                  value: "osRollUpDoor",
                },
                {
                  display: "Louver",
                  value: "louver",
                },
                {
                  display: "Overhead Door Blocking",
                  value: "overheadDoorBlocking",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            panelOption: {
              displayName: "Panel Option",
              options: [
                {
                  display: "Sheet Over Opening",
                  value: "sheetOverOpening",
                },
                {
                  display: "Remove Panel",
                  value: "removePanel",
                },
                {
                  display: "Field Located",
                  value: "fieldLocated",
                },
                {
                  display: "Girts in Opening",
                  value: "girtsInOpening",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            sillHeight: {
              displayName: "Sill Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            width: {
              displayName: "Width",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
          },
          frontSidewall: {
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "4",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          initiated: true,
          leftEndwall: {
            mirroredBays: {
              type: "BOOLEAN",
              displayName: "Mirrored",
              value: "",
            },
            expandable: {
              displayName: "Expandable",
              type: "BOOLEAN",
              value: "",
            },
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "3",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "16.6667",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          liner: {
            end: {
              displayName: "End",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            finish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "color",
            },
            fullHeight: {
              displayName: "Full Height",
              type: "BOOLEAN",
              value: "",
            },
            fullWidth: {
              displayName: "Full Width",
              type: "BOOLEAN",
              value: "",
            },
            linerHeight: {
              displayName: "Liner Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            panel: {
              displayName: "Panel",
              options: [
                {
                  display: "PBR-Panel",
                  value: "pbrPanel",
                },
                {
                  display: "Rev-PBR Panel",
                  value: "revPbrPanel",
                },
                {
                  display: "U Panel",
                  value: "uPanel",
                },
                {
                  display: "AG Panel",
                  value: "agPanel",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            start: {
              displayName: "Start",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          loads: {
            buildingUse: {
              displayName: "Building Use",
              type: "TEXT",
              value: "",
            },
            buildingOccupancy: {
              type: "STANDARD_DROPDOWN",
              displayName: "Building Occupancy",
              value: "",
              options: [
                {
                  display: "I - Low, Storage, Warehouse, or Agri",
                  value: "i",
                },
                {
                  display: "II - Normal, Standard Building",
                  value: "ii",
                },
                {
                  display: "III - High Schools, Large Occupancy",
                  value: "iii",
                },
                {
                  display: "IV - Post Essential (Fire Dept., Hospital)",
                  value: "iv",
                },
              ],
            },
            collateralLoad: {
              displayName: "Collateral Load",
              type: "FLOAT",
              value: "1.0",
            },
            slipperyRoof: {
              displayName: "Slippery Roof",
              type: "BOOLEAN",
              value: "",
            },
            thermalFactor: {
              displayName: "Thermal Factor",
              options: [
                {
                  display: "Heated",
                  value: "heated",
                },
                {
                  display: "Unheated",
                  value: "unheated",
                },
                {
                  display: "Greenhouse",
                  value: "greenhouse",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "unheated",
            },
          },
          notes: {
            buildingNotes: {
              displayName: "Building Notes",
              type: "AREA_TEXT",
              value: "",
            },
          },
          rightEndwall: {
            mirroredBays: {
              type: "BOOLEAN",
              displayName: "Mirrored",
              value: "",
            },
            expandable: {
              displayName: "Expandable",
              type: "BOOLEAN",
              value: "",
            },
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "3",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "16.6667",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          rigidFrame: {
            column: {
              displayName: "Column",
              options: [
                {
                  display: "Tapered",
                  value: "tapered",
                },
                {
                  display: "Constant",
                  value: "constant",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            interiorColumn: {
              elevation: {
                displayName: "Elevation",
                suffix: "in",
                type: "ELEVATION",
                value: "-8",
              },
              locations: {
                displayName: "Locations",
                type: "TEXT",
                value: "",
              },
            },
            locations: {
              displayName: "Locations",
              type: "NUMBER",
              value: "",
            },
            rafter: {
              displayName: "Rafter",
              options: [
                {
                  display: "Tapered",
                  value: "tapered",
                },
                {
                  display: "Constant",
                  value: "constant",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          roof: {
            framedOpenings: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "4",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
          },
          roofInsulation: {
            bySteelTech: {
              displayName: "Steel Tech",
              type: "BOOLEAN",
              value: "",
            },
            thickness: {
              displayName: "Thickness",
              suffix: "in",
              type: "NUMBER",
              value: "",
            },
            type: {
              displayName: "Type",
              options: [
                {
                  display: "None",
                  value: "none",
                },
                {
                  display: "Single Layer",
                  value: "singleLayer",
                },
                {
                  display: "System",
                  value: "system",
                },
                {
                  display: "Spray Foam",
                  value: "sprayFoam",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          shape: {
            heightLeft: {
              displayName: "Left Eave Height",
              suffix: "ft",
              type: "LENGTH",
              value: "14",
            },
            heightRight: {
              displayName: "Right Eave Height",
              suffix: "ft",
              type: "LENGTH",
              value: "14",
            },
            length: {
              displayName: "Length",
              suffix: "ft",
              type: "SPECIAL_CASE",
              value: "80",
            },
            peakOffset: {
              displayName: "Peak Offset",
              suffix: "ft",
              type: "LENGTH",
              value: 25,
            },
            shape: {
              displayName: "Shape",
              options: [
                {
                  display: "Single Slope",
                  value: "singleSlope",
                },
                {
                  display: "Symmetrical Double Slope",
                  value: "symmetricalDoubleSlope",
                },
                {
                  display: "Asymmetrical Double Slope",
                  value: "asymmetricalDoubleSlope",
                },
              ],
              required: true,
              type: "STANDARD_DROPDOWN",
              value: "symmetricalDoubleSlope",
            },
            slope: {
              displayName: "Slope",
              type: "FLOAT",
              value: "1",
            },
            slopeRight: {
              displayName: "Right Slope",
              type: "FLOAT",
              value: "1",
            },
            width: {
              displayName: "Width",
              suffix: "ft",
              type: "SPECIAL_CASE",
              value: "50",
            },
          },
          surfaceSpacing: {
            numberOfBays: {
              displayName: "# Bays",
              type: "number",
              value: "",
            },
            openCondition: {
              displayName: "Open Condition",
              type: "TEXT",
              value: "",
            },
            openHeight: {
              displayName: "Open Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            width: {
              displayName: "Width",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
          },
          wainscot: {
            end: {
              displayName: "End",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            finish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            fullHeight: {
              displayName: "Full Height",
              type: "BOOLEAN",
              value: "",
            },
            fullWidth: {
              displayName: "Full Width",
              type: "BOOLEAN",
              value: "",
            },
            height: {
              displayName: "Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            panel: {
              displayName: "Panel",
              options: [
                {
                  display: "PBR-Panel",
                  value: "pbrPanel",
                },
                {
                  display: "Rev-PBR Panel",
                  value: "revPbrPanel",
                },
                {
                  display: "U Panel",
                  value: "uPanel",
                },
                {
                  display: "AG Panel",
                  value: "agPanel",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            start: {
              displayName: "Start",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          walkDoor: {
            accessories: {
              closer: {
                displayName: "Closer",
                type: "BOOLEAN",
                value: "",
              },
              deadBolt: {
                displayName: "Dead Bolt",
                type: "BOOLEAN",
                value: "",
              },
              kickPlate: {
                displayName: "Kick Plate",
                type: "BOOLEAN",
                value: "",
              },
              latchGuard: {
                displayName: "Latch Guard",
                type: "BOOLEAN",
                value: "",
              },
              panicHardware: {
                displayName: "Panic Hardware",
                type: "BOOLEAN",
                value: "",
              },
              window: {
                displayName: "Window",
                options: [
                  {
                    display: "None",
                    value: "none",
                  },
                  {
                    display: "Uninsulated",
                    value: "uninsulated",
                  },
                  {
                    display: "Insulated",
                    value: "insulated",
                  },
                ],
                type: "STANDARD_DROPDOWN",
                value: "",
              },
            },
            bay: {
              displayName: "Bay",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            offset: {
              displayName: "Offset",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            size: {
              displayName: "Size",
              options: [
                {
                  display: "3x7",
                  value: "3x7",
                },
                {
                  display: "4x7",
                  value: "4x7",
                },
                {
                  display: "6x7",
                  value: "6x7",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          wallInsulation: {
            bySteelTech: {
              displayName: "Steel Tech",
              type: "BOOLEAN",
              value: "",
            },
            thickness: {
              displayName: "Thickness",
              suffix: "in",
              type: "NUMBER",
              value: "",
            },
            type: {
              displayName: "Type",
              options: [
                {
                  display: "None",
                  value: "none",
                },
                {
                  display: "Single Layer",
                  value: "singleLayer",
                },
                {
                  display: "System",
                  value: "system",
                },
                {
                  display: "Spray Foam",
                  value: "sprayFoam",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
        },
      ],
      project: {
        loads: {
          groundSnow: {
            displayName: "Ground Snow",
            suffix: "psf",
            type: "FLOAT",
            value: "",
          },
          groundSnowReducible: {
            displayName: "Reducible",
            type: "BOOLEAN",
            value: "",
          },
          liveLoad: {
            displayName: "Live Load",
            suffix: "psf",
            type: "FLOAT",
            value: "20",
          },
          liveLoadReducible: {
            displayName: "Reducible",
            type: "BOOLEAN",
            value: true,
          },
          windExposure: {
            displayName: "Wind Exposure",
            options: [
              {
                display: "B",
                value: "b",
              },
              {
                display: "C",
                value: "c",
              },
              {
                display: "D",
                value: "d",
              },
            ],
            type: "STANDARD_DROPDOWN",
            value: "c",
          },
          windLoad: {
            displayName: "Wind Load",
            suffix: "mph",
            type: "FLOAT",
            value: "",
          },
        },
        primaryInfo: {
          dealerInfo: {
            address: {
              displayName: "Address",
              type: "TEXT",
              value: "",
            },
            city: {
              displayName: "City",
              type: "TEXT",
              value: "",
            },
            email: {
              displayName: "Email",
              type: "email",
              value: "",
            },
            name: {
              displayName: "Name",
              required: true,
              type: "TEXT",
              value: "",
            },
            phone: {
              displayName: "Phone",
              type: "PHONE",
              value: "",
            },
            state: {
              displayName: "State",
              type: "STATES_DROPDOWN",
              value: null,
            },
            zip: {
              displayName: "Zip",
              type: "number",
              value: "",
            },
          },
          projectInfo: {
            address: {
              displayName: "Address",
              type: "TEXT",
              value: "",
            },
            city: {
              displayName: "City",
              type: "TEXT",
              value: "",
            },
            contactEmail: {
              displayName: "Email",
              required: true,
              type: "email",
              value: "",
            },
            contactName: {
              displayName: "Name",
              required: true,
              type: "TEXT",
              value: "",
            },
            contactNumber: {
              displayName: "Phone",
              required: true,
              type: "PHONE",
              value: "",
            },
            projectName: {
              displayName: "Project Name",
              required: true,
              type: "TEXT",
              value: "50'-0\"x80'-0\"x14'-0\"",
            },
            state: {
              displayName: "State",
              type: "STATES_DROPDOWN",
              value: null,
            },
            zip: {
              displayName: "Zip",
              type: "number",
              value: "",
            },
          },
        },
      },
    },
    {
      buildings: [
        {
          accessories: {
            guttersAndDown: {
              displayName: "Gutters & Downspouts",
              type: "BOOLEAN",
              value: false,
            },
          },
          backSidewall: {
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "4",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "25",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          baseCondition: {
            baseTrim: {
              displayName: "Base Trim",
              options: [
                {
                  display: "Base Trim",
                  value: "baseTrim",
                },
                {
                  display: "Return Base",
                  value: "ratGuard",
                },
                {
                  display: "Notch, No Trim",
                  value: "notchNoTrim",
                },
                {
                  display: "No Trim or Notch",
                  value: "noTrimOrNotch",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "baseTrim",
            },
            slabOrPiers: {
              displayName: "Slab",
              options: [
                {
                  display: "Slab",
                  value: "slab",
                },
                {
                  display: "Piers",
                  value: "piers",
                },
              ],
              type: "RADIO_BUTTON",
              value: "slab",
            },
          },
          columnBaseElevation: {
            backSidewall: {
              displayName: "Back Sidewall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
            frontSidewall: {
              displayName: "Front Sidewall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
            leftEndwall: {
              displayName: "Left Endwall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
            rightEndwall: {
              displayName: "Right Endwall",
              suffix: "in",
              type: "ELEVATION",
              value: "",
            },
          },
          exteriorPanels: {
            roofFinish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "galv",
            },
            roofPanel: {
              displayName: "Panel",
              options: [
                {
                  display: "Trapezoidal Standing Seam",
                  value: "standingSeamTrapezoidal",
                },
                {
                  display: "Vertical Standing Seam ",
                  value: "standingSeamVertical",
                },
                {
                  display: "PBR 26",
                  value: "pbr26",
                },
                {
                  display: "PBR 24",
                  value: "pbr24",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "pbr26",
            },
            wallFinish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "color",
            },
            wallPanel: {
              displayName: "Panel",
              options: [
                {
                  display: "PBR 26",
                  value: "pbr26",
                },
                {
                  display: "PBR 24",
                  value: "pbr24",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "pbr26",
            },
          },
          framedOpening: {
            baseElevation: {
              displayName: "Base Elevation",
              suffix: "ft",
              type: "ELEVATION",
              value: "",
            },
            bay: {
              displayName: "Bay",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            framingType: {
              displayName: "Framing Type",
              options: [
                {
                  display: "No Framing",
                  value: "noFraming",
                },
                {
                  display: "Jamb, Base to Girt",
                  value: "jambBaseToGirt",
                },
                {
                  display: "Jamb, Base to Roof",
                  value: "jambBaseToRoof",
                },
                {
                  display: "Jamb, Girt to Girt",
                  value: "jambGirtToGirt",
                },
                {
                  display: "Open Bay",
                  value: "openBay",
                },
                {
                  display: "Jamb, Base to Added Girt",
                  value: "jambBaseToAddedGirt",
                },
                {
                  display: "Strip Window",
                  value: "stripWindow",
                },
                {
                  display: "Jamb, Girt to Girt (No Girt Cut)",
                  value: "jambGirtToGirtNoGirtCut",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            headerHeight: {
              displayName: "Header Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            offset: {
              displayName: "Offset",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            openingType: {
              displayName: "Opening Type",
              options: [
                {
                  display: "Walk Door",
                  value: "walkDoor",
                },
                {
                  display: "Self Flashing Window",
                  value: "selfFlashingWindow",
                },
                {
                  display: "Standard Window",
                  value: "standardWindow",
                },
                {
                  display: "Overhead Door",
                  value: "overheadDoor",
                },
                {
                  display: "IS Roll-Up Door",
                  value: "isRollUpDoor",
                },
                {
                  display: "OS Roll-Up Door",
                  value: "osRollUpDoor",
                },
                {
                  display: "Louver",
                  value: "louver",
                },
                {
                  display: "Overhead Door Blocking",
                  value: "overheadDoorBlocking",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            panelOption: {
              displayName: "Panel Option",
              options: [
                {
                  display: "Sheet Over Opening",
                  value: "sheetOverOpening",
                },
                {
                  display: "Remove Panel",
                  value: "removePanel",
                },
                {
                  display: "Field Located",
                  value: "fieldLocated",
                },
                {
                  display: "Girts in Opening",
                  value: "girtsInOpening",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            sillHeight: {
              displayName: "Sill Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            width: {
              displayName: "Width",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
          },
          frontSidewall: {
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "4",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "25",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          initiated: true,
          leftEndwall: {
            mirroredBays: {
              type: "BOOLEAN",
              displayName: "Mirrored",
              value: "",
            },
            expandable: {
              displayName: "Expandable",
              type: "BOOLEAN",
              value: "",
            },
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "3",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          liner: {
            end: {
              displayName: "End",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            finish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "color",
            },
            fullHeight: {
              displayName: "Full Height",
              type: "BOOLEAN",
              value: "",
            },
            fullWidth: {
              displayName: "Full Width",
              type: "BOOLEAN",
              value: "",
            },
            linerHeight: {
              displayName: "Liner Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            panel: {
              displayName: "Panel",
              options: [
                {
                  display: "PBR-Panel",
                  value: "pbrPanel",
                },
                {
                  display: "Rev-PBR Panel",
                  value: "revPbrPanel",
                },
                {
                  display: "U Panel",
                  value: "uPanel",
                },
                {
                  display: "AG Panel",
                  value: "agPanel",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            start: {
              displayName: "Start",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          loads: {
            buildingUse: {
              displayName: "Building Use",
              type: "TEXT",
              value: "",
            },
            buildingOccupancy: {
              type: "STANDARD_DROPDOWN",
              displayName: "Building Occupancy",
              value: "",
              options: [
                {
                  display: "I - Low, Storage, Warehouse, or Agri",
                  value: "i",
                },
                {
                  display: "II - Normal, Standard Building",
                  value: "ii",
                },
                {
                  display: "III - High Schools, Large Occupancy",
                  value: "iii",
                },
                {
                  display: "IV - Post Essential (Fire Dept., Hospital)",
                  value: "iv",
                },
              ],
            },
            collateralLoad: {
              displayName: "Collateral Load",
              type: "FLOAT",
              value: "1.0",
            },
            slipperyRoof: {
              displayName: "Slippery Roof",
              type: "BOOLEAN",
              value: "",
            },
            thermalFactor: {
              displayName: "Thermal Factor",
              options: [
                {
                  display: "Heated",
                  value: "heated",
                },
                {
                  display: "Unheated",
                  value: "unheated",
                },
                {
                  display: "Greenhouse",
                  value: "greenhouse",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "unheated",
            },
          },
          notes: {
            buildingNotes: {
              displayName: "Building Notes",
              type: "AREA_TEXT",
              value: "",
            },
          },
          rightEndwall: {
            mirroredBays: {
              type: "BOOLEAN",
              displayName: "Mirrored",
              value: "",
            },
            expandable: {
              displayName: "Expandable",
              type: "BOOLEAN",
              value: "",
            },
            framedOpenings: [],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "3",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "20",
                },
              },
            ],
            wainscots: [],
            walkDoors: [],
            partialWalls: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            extensions: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                start: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "Start",
                  value: "",
                  options: [],
                },
                end: {
                  type: "STANDARD_DROPDOWN",
                  displayName: "End",
                  value: "",
                  options: [],
                },
                width: {
                  type: "LENGTH",
                  displayName: "Width",
                  suffix: "ft",
                  value: "",
                },
              },
            ],
          },
          rigidFrame: {
            column: {
              displayName: "Column",
              options: [
                {
                  display: "Tapered",
                  value: "tapered",
                },
                {
                  display: "Constant",
                  value: "constant",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            interiorColumn: {
              elevation: {
                displayName: "Elevation",
                suffix: "in",
                type: "ELEVATION",
                value: "-8",
              },
              locations: {
                displayName: "Locations",
                type: "TEXT",
                value: "",
              },
            },
            locations: {
              displayName: "Locations",
              type: "NUMBER",
              value: "",
            },
            rafter: {
              displayName: "Rafter",
              options: [
                {
                  display: "Tapered",
                  value: "tapered",
                },
                {
                  display: "Constant",
                  value: "constant",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          roof: {
            framedOpenings: [],
            lightPanels: [
              {
                fullWidth: {
                  type: "BOOLEAN",
                  displayName: "Full Width",
                  value: "",
                },
                quantity: {
                  type: "NUMBER",
                  displayName: "Quantity",
                  value: "",
                },
              },
            ],
            liners: [],
            surfaceSpacings: [
              {
                numberOfBays: {
                  displayName: "# Bays",
                  type: "number",
                  value: "4",
                },
                openCondition: {
                  displayName: "Open Condition",
                  type: "TEXT",
                  value: "",
                },
                openHeight: {
                  displayName: "Open Height",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "",
                },
                width: {
                  displayName: "Width",
                  suffix: "ft",
                  type: "LENGTH",
                  value: "25",
                },
              },
            ],
          },
          roofInsulation: {
            bySteelTech: {
              displayName: "Steel Tech",
              type: "BOOLEAN",
              value: "",
            },
            thickness: {
              displayName: "Thickness",
              suffix: "in",
              type: "NUMBER",
              value: "",
            },
            type: {
              displayName: "Type",
              options: [
                {
                  display: "None",
                  value: "none",
                },
                {
                  display: "Single Layer",
                  value: "singleLayer",
                },
                {
                  display: "System",
                  value: "system",
                },
                {
                  display: "Spray Foam",
                  value: "sprayFoam",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          shape: {
            heightLeft: {
              displayName: "Left Eave Height",
              suffix: "ft",
              type: "LENGTH",
              value: "16",
            },
            heightRight: {
              displayName: "Right Eave Height",
              suffix: "ft",
              type: "LENGTH",
              value: "16",
            },
            length: {
              displayName: "Length",
              suffix: "ft",
              type: "SPECIAL_CASE",
              value: "100",
            },
            peakOffset: {
              displayName: "Peak Offset",
              suffix: "ft",
              type: "LENGTH",
              value: 30,
            },
            shape: {
              displayName: "Shape",
              options: [
                {
                  display: "Single Slope",
                  value: "singleSlope",
                },
                {
                  display: "Symmetrical Double Slope",
                  value: "symmetricalDoubleSlope",
                },
                {
                  display: "Asymmetrical Double Slope",
                  value: "asymmetricalDoubleSlope",
                },
              ],
              required: true,
              type: "STANDARD_DROPDOWN",
              value: "symmetricalDoubleSlope",
            },
            slope: {
              displayName: "Slope",
              type: "FLOAT",
              value: "1",
            },
            slopeRight: {
              displayName: "Right Slope",
              type: "FLOAT",
              value: "1",
            },
            width: {
              displayName: "Width",
              suffix: "ft",
              type: "SPECIAL_CASE",
              value: "60",
            },
          },
          surfaceSpacing: {
            numberOfBays: {
              displayName: "# Bays",
              type: "number",
              value: "",
            },
            openCondition: {
              displayName: "Open Condition",
              type: "TEXT",
              value: "",
            },
            openHeight: {
              displayName: "Open Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            width: {
              displayName: "Width",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
          },
          wainscot: {
            end: {
              displayName: "End",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            finish: {
              displayName: "Finish",
              options: [
                {
                  display: "Galv",
                  value: "galv",
                },
                {
                  display: "Color",
                  value: "color",
                },
                {
                  display: "PVDF",
                  value: "PVDF",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            fullHeight: {
              displayName: "Full Height",
              type: "BOOLEAN",
              value: "",
            },
            fullWidth: {
              displayName: "Full Width",
              type: "BOOLEAN",
              value: "",
            },
            height: {
              displayName: "Height",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            panel: {
              displayName: "Panel",
              options: [
                {
                  display: "PBR-Panel",
                  value: "pbrPanel",
                },
                {
                  display: "Rev-PBR Panel",
                  value: "revPbrPanel",
                },
                {
                  display: "U Panel",
                  value: "uPanel",
                },
                {
                  display: "AG Panel",
                  value: "agPanel",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            start: {
              displayName: "Start",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          walkDoor: {
            accessories: {
              closer: {
                displayName: "Closer",
                type: "BOOLEAN",
                value: "",
              },
              deadBolt: {
                displayName: "Dead Bolt",
                type: "BOOLEAN",
                value: "",
              },
              kickPlate: {
                displayName: "Kick Plate",
                type: "BOOLEAN",
                value: "",
              },
              latchGuard: {
                displayName: "Latch Guard",
                type: "BOOLEAN",
                value: "",
              },
              panicHardware: {
                displayName: "Panic Hardware",
                type: "BOOLEAN",
                value: "",
              },
              window: {
                displayName: "Window",
                options: [
                  {
                    display: "None",
                    value: "none",
                  },
                  {
                    display: "Uninsulated",
                    value: "uninsulated",
                  },
                  {
                    display: "Insulated",
                    value: "insulated",
                  },
                ],
                type: "STANDARD_DROPDOWN",
                value: "",
              },
            },
            bay: {
              displayName: "Bay",
              options: [],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
            offset: {
              displayName: "Offset",
              suffix: "ft",
              type: "LENGTH",
              value: "",
            },
            size: {
              displayName: "Size",
              options: [
                {
                  display: "3x7",
                  value: "3x7",
                },
                {
                  display: "4x7",
                  value: "4x7",
                },
                {
                  display: "6x7",
                  value: "6x7",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
          wallInsulation: {
            bySteelTech: {
              displayName: "Steel Tech",
              type: "BOOLEAN",
              value: "",
            },
            thickness: {
              displayName: "Thickness",
              suffix: "in",
              type: "NUMBER",
              value: "",
            },
            type: {
              displayName: "Type",
              options: [
                {
                  display: "None",
                  value: "none",
                },
                {
                  display: "Single Layer",
                  value: "singleLayer",
                },
                {
                  display: "System",
                  value: "system",
                },
                {
                  display: "Spray Foam",
                  value: "sprayFoam",
                },
              ],
              type: "STANDARD_DROPDOWN",
              value: "",
            },
          },
        },
      ],
      project: {
        loads: {
          groundSnow: {
            displayName: "Ground Snow",
            suffix: "psf",
            type: "FLOAT",
            value: "",
          },
          groundSnowReducible: {
            displayName: "Reducible",
            type: "BOOLEAN",
            value: "",
          },
          liveLoad: {
            displayName: "Live Load",
            suffix: "psf",
            type: "FLOAT",
            value: "20",
          },
          liveLoadReducible: {
            displayName: "Reducible",
            type: "BOOLEAN",
            value: true,
          },
          windExposure: {
            displayName: "Wind Exposure",
            options: [
              {
                display: "B",
                value: "b",
              },
              {
                display: "C",
                value: "c",
              },
              {
                display: "D",
                value: "d",
              },
            ],
            type: "STANDARD_DROPDOWN",
            value: "c",
          },
          windLoad: {
            displayName: "Wind Load",
            suffix: "mph",
            type: "FLOAT",
            value: "",
          },
        },
        primaryInfo: {
          dealerInfo: {
            address: {
              displayName: "Address",
              type: "TEXT",
              value: "",
            },
            city: {
              displayName: "City",
              type: "TEXT",
              value: "",
            },
            email: {
              displayName: "Email",
              type: "email",
              value: "",
            },
            name: {
              displayName: "Name",
              required: true,
              type: "TEXT",
              value: "",
            },
            phone: {
              displayName: "Phone",
              type: "PHONE",
              value: "",
            },
            state: {
              displayName: "State",
              type: "STATES_DROPDOWN",
              value: null,
            },
            zip: {
              displayName: "Zip",
              type: "number",
              value: "",
            },
          },
          projectInfo: {
            address: {
              displayName: "Address",
              type: "TEXT",
              value: "",
            },
            city: {
              displayName: "City",
              type: "TEXT",
              value: "",
            },
            contactEmail: {
              displayName: "Email",
              required: true,
              type: "email",
              value: "",
            },
            contactName: {
              displayName: "Name",
              required: true,
              type: "TEXT",
              value: "",
            },
            contactNumber: {
              displayName: "Phone",
              required: true,
              type: "PHONE",
              value: "",
            },
            projectName: {
              displayName: "Project Name",
              required: true,
              type: "TEXT",
              value: "60'-0\"x100'-0\"x16'-0\"",
            },
            state: {
              displayName: "State",
              type: "STATES_DROPDOWN",
              value: null,
            },
            zip: {
              displayName: "Zip",
              type: "number",
              value: "",
            },
          },
        },
      },
    },
    // {
    //   buildings: [
    //     {
    //       accessories: {
    //         guttersAndDown: {
    //           displayName: "Gutters & Downspouts",
    //           type: "BOOLEAN",
    //           value: false,
    //         },
    //       },
    //       backSidewall: {
    //         framedOpenings: [],
    //         liners: [],
    //         surfaceSpacings: [
    //           {
    //             numberOfBays: {
    //               displayName: "# Bays",
    //               type: "number",
    //               value: "5",
    //             },
    //             openCondition: {
    //               displayName: "Open Condition",
    //               type: "TEXT",
    //               value: "",
    //             },
    //             openHeight: {
    //               displayName: "Open Height",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "",
    //             },
    //             width: {
    //               displayName: "Width",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "24",
    //             },
    //           },
    //         ],
    //         wainscots: [],
    //         walkDoors: [],
    //         partialWalls: [],
    //         lightPanels: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             quantity: {
    //               type: "NUMBER",
    //               displayName: "Quantity",
    //               value: "",
    //             },
    //           },
    //         ],
    //         extensions: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             start: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "Start",
    //               value: "",
    //               options: [],
    //             },
    //             end: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "End",
    //               value: "",
    //               options: [],
    //             },
    //             width: {
    //               type: "LENGTH",
    //               displayName: "Width",
    //               suffix: "ft",
    //               value: "",
    //             },
    //           },
    //         ],
    //       },
    //       baseCondition: {
    //         baseTrim: {
    //           displayName: "Base Trim",
    //           options: [
    //             {
    //               display: "Base Trim",
    //               value: "baseTrim",
    //             },
    //             {
    //               display: "Return Base",
    //               value: "ratGuard",
    //             },
    //             {
    //               display: "Notch, No Trim",
    //               value: "notchNoTrim",
    //             },
    //             {
    //               display: "No Trim or Notch",
    //               value: "noTrimOrNotch",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "baseTrim",
    //         },
    //         slabOrPiers: {
    //           displayName: "Slab",
    //           options: [
    //             {
    //               display: "Slab",
    //               value: "slab",
    //             },
    //             {
    //               display: "Piers",
    //               value: "piers",
    //             },
    //           ],
    //           type: "RADIO_BUTTON",
    //           value: "slab",
    //         },
    //       },
    //       columnBaseElevation: {
    //         backSidewall: {
    //           displayName: "Back Sidewall",
    //           suffix: "in",
    //           type: "ELEVATION",
    //           value: "",
    //         },
    //         frontSidewall: {
    //           displayName: "Front Sidewall",
    //           suffix: "in",
    //           type: "ELEVATION",
    //           value: "",
    //         },
    //         leftEndwall: {
    //           displayName: "Left Endwall",
    //           suffix: "in",
    //           type: "ELEVATION",
    //           value: "",
    //         },
    //         rightEndwall: {
    //           displayName: "Right Endwall",
    //           suffix: "in",
    //           type: "ELEVATION",
    //           value: "",
    //         },
    //       },
    //       exteriorPanels: {
    //         roofFinish: {
    //           displayName: "Finish",
    //           options: [
    //             {
    //               display: "Galv",
    //               value: "galv",
    //             },
    //             {
    //               display: "Color",
    //               value: "color",
    //             },
    //             {
    //               display: "PVDF",
    //               value: "PVDF",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "color",
    //         },
    //         roofPanel: {
    //           displayName: "Panel",
    //           options: [
    //             {
    //               display: "Trapezoidal Standing Seam",
    //               value: "standingSeamTrapezoidal",
    //             },
    //             {
    //               display: "Vertical Standing Seam ",
    //               value: "standingSeamVertical",
    //             },
    //             {
    //               display: "PBR 26",
    //               value: "pbr26",
    //             },
    //             {
    //               display: "PBR 24",
    //               value: "pbr24",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "pbr26",
    //         },
    //         wallFinish: {
    //           displayName: "Finish",
    //           options: [
    //             {
    //               display: "Galv",
    //               value: "galv",
    //             },
    //             {
    //               display: "Color",
    //               value: "color",
    //             },
    //             {
    //               display: "PVDF",
    //               value: "PVDF",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "color",
    //         },
    //         wallPanel: {
    //           displayName: "Panel",
    //           options: [
    //             {
    //               display: "PBR 26",
    //               value: "pbr26",
    //             },
    //             {
    //               display: "PBR 24",
    //               value: "pbr24",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "pbr26",
    //         },
    //       },
    //       framedOpening: {
    //         baseElevation: {
    //           displayName: "Base Elevation",
    //           suffix: "ft",
    //           type: "ELEVATION",
    //           value: "",
    //         },
    //         bay: {
    //           displayName: "Bay",
    //           options: [],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         framingType: {
    //           displayName: "Framing Type",
    //           options: [
    //             {
    //               display: "No Framing",
    //               value: "noFraming",
    //             },
    //             {
    //               display: "Jamb, Base to Girt",
    //               value: "jambBaseToGirt",
    //             },
    //             {
    //               display: "Jamb, Base to Roof",
    //               value: "jambBaseToRoof",
    //             },
    //             {
    //               display: "Jamb, Girt to Girt",
    //               value: "jambGirtToGirt",
    //             },
    //             {
    //               display: "Open Bay",
    //               value: "openBay",
    //             },
    //             {
    //               display: "Jamb, Base to Added Girt",
    //               value: "jambBaseToAddedGirt",
    //             },
    //             {
    //               display: "Strip Window",
    //               value: "stripWindow",
    //             },
    //             {
    //               display: "Jamb, Girt to Girt (No Girt Cut)",
    //               value: "jambGirtToGirtNoGirtCut",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         headerHeight: {
    //           displayName: "Header Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         offset: {
    //           displayName: "Offset",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         openingType: {
    //           displayName: "Opening Type",
    //           options: [
    //             {
    //               display: "Walk Door",
    //               value: "walkDoor",
    //             },
    //             {
    //               display: "Self Flashing Window",
    //               value: "selfFlashingWindow",
    //             },
    //             {
    //               display: "Standard Window",
    //               value: "standardWindow",
    //             },
    //             {
    //               display: "Overhead Door",
    //               value: "overheadDoor",
    //             },
    //             {
    //               display: "IS Roll-Up Door",
    //               value: "isRollUpDoor",
    //             },
    //             {
    //               display: "OS Roll-Up Door",
    //               value: "osRollUpDoor",
    //             },
    //             {
    //               display: "Louver",
    //               value: "louver",
    //             },
    //             {
    //               display: "Overhead Door Blocking",
    //               value: "overheadDoorBlocking",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         panelOption: {
    //           displayName: "Panel Option",
    //           options: [
    //             {
    //               display: "Sheet Over Opening",
    //               value: "sheetOverOpening",
    //             },
    //             {
    //               display: "Remove Panel",
    //               value: "removePanel",
    //             },
    //             {
    //               display: "Field Located",
    //               value: "fieldLocated",
    //             },
    //             {
    //               display: "Girts in Opening",
    //               value: "girtsInOpening",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         sillHeight: {
    //           displayName: "Sill Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         width: {
    //           displayName: "Width",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //       },
    //       frontSidewall: {
    //         framedOpenings: [],
    //         liners: [],
    //         surfaceSpacings: [
    //           {
    //             numberOfBays: {
    //               displayName: "# Bays",
    //               type: "number",
    //               value: "5",
    //             },
    //             openCondition: {
    //               displayName: "Open Condition",
    //               type: "TEXT",
    //               value: "",
    //             },
    //             openHeight: {
    //               displayName: "Open Height",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "",
    //             },
    //             width: {
    //               displayName: "Width",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "24",
    //             },
    //           },
    //         ],
    //         wainscots: [],
    //         walkDoors: [],
    //         partialWalls: [],
    //         lightPanels: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             quantity: {
    //               type: "NUMBER",
    //               displayName: "Quantity",
    //               value: "",
    //             },
    //           },
    //         ],
    //         extensions: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             start: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "Start",
    //               value: "",
    //               options: [],
    //             },
    //             end: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "End",
    //               value: "",
    //               options: [],
    //             },
    //             width: {
    //               type: "LENGTH",
    //               displayName: "Width",
    //               suffix: "ft",
    //               value: "",
    //             },
    //           },
    //         ],
    //       },
    //       initiated: false,
    //       leftEndwall: {
    //         mirroredBays: {
    //           type: "BOOLEAN",
    //           displayName: "Mirrored",
    //           value: "",
    //         },
    //         expandable: {
    //           displayName: "Expandable",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         framedOpenings: [],
    //         liners: [],
    //         surfaceSpacings: [
    //           {
    //             numberOfBays: {
    //               displayName: "# Bays",
    //               type: "number",
    //               value: "5",
    //             },
    //             openCondition: {
    //               displayName: "Open Condition",
    //               type: "TEXT",
    //               value: "",
    //             },
    //             openHeight: {
    //               displayName: "Open Height",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "",
    //             },
    //             width: {
    //               displayName: "Width",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "16",
    //             },
    //           },
    //         ],
    //         wainscots: [],
    //         walkDoors: [],
    //         partialWalls: [],
    //         lightPanels: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             quantity: {
    //               type: "NUMBER",
    //               displayName: "Quantity",
    //               value: "",
    //             },
    //           },
    //         ],
    //         extensions: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             start: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "Start",
    //               value: "",
    //               options: [],
    //             },
    //             end: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "End",
    //               value: "",
    //               options: [],
    //             },
    //             width: {
    //               type: "LENGTH",
    //               displayName: "Width",
    //               suffix: "ft",
    //               value: "",
    //             },
    //           },
    //         ],
    //       },
    //       liner: {
    //         end: {
    //           displayName: "End",
    //           options: [],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         finish: {
    //           displayName: "Finish",
    //           options: [
    //             {
    //               display: "Galv",
    //               value: "galv",
    //             },
    //             {
    //               display: "Color",
    //               value: "color",
    //             },
    //             {
    //               display: "PVDF",
    //               value: "PVDF",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         fullHeight: {
    //           displayName: "Full Height",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         fullWidth: {
    //           displayName: "Full Width",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         linerHeight: {
    //           displayName: "Liner Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         panel: {
    //           displayName: "Panel",
    //           options: [
    //             {
    //               display: "PBR-Panel",
    //               value: "pbrPanel",
    //             },
    //             {
    //               display: "Rev-PBR Panel",
    //               value: "revPbrPanel",
    //             },
    //             {
    //               display: "U Panel",
    //               value: "uPanel",
    //             },
    //             {
    //               display: "AG Panel",
    //               value: "agPanel",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         start: {
    //           displayName: "Start",
    //           options: [],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //       },
    //       loads: {
    //         buildingUse: {
    //           displayName: "Building Use",
    //           type: "TEXT",
    //           value: "",
    //         },
    //         buildingOccupancy: {
    //           type: "STANDARD_DROPDOWN",
    //           displayName: "Building Occupancy",
    //           value: "",
    //           options: [
    //             {
    //               display: "I - Low, Storage, Warehouse, or Agri",
    //               value: "i",
    //             },
    //             {
    //               display: "II - Normal, Standard Building",
    //               value: "ii",
    //             },
    //             {
    //               display: "III - High Schools, Large Occupancy",
    //               value: "iii",
    //             },
    //             {
    //               display: "IV - Post Essential (Fire Dept., Hospital)",
    //               value: "iv",
    //             },
    //           ],
    //         },
    //         collateralLoad: {
    //           displayName: "Collateral Load",
    //           type: "FLOAT",
    //           value: "1.0",
    //         },
    //         slipperyRoof: {
    //           displayName: "Slippery Roof",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         thermalFactor: {
    //           displayName: "Thermal Factor",
    //           options: [
    //             {
    //               display: "Heated",
    //               value: "heated",
    //             },
    //             {
    //               display: "Unheated",
    //               value: "unheated",
    //             },
    //             {
    //               display: "Greenhouse",
    //               value: "greenhouse",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "unheated",
    //         },
    //       },
    //       notes: {
    //         buildingNotes: {
    //           displayName: "Building Notes",
    //           type: "AREA_TEXT",
    //           value: "",
    //         },
    //       },
    //       rightEndwall: {
    //         mirroredBays: {
    //           type: "BOOLEAN",
    //           displayName: "Mirrored",
    //           value: "",
    //         },
    //         expandable: {
    //           displayName: "Expandable",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         framedOpenings: [],
    //         liners: [],
    //         surfaceSpacings: [
    //           {
    //             numberOfBays: {
    //               displayName: "# Bays",
    //               type: "number",
    //               value: "5",
    //             },
    //             openCondition: {
    //               displayName: "Open Condition",
    //               type: "TEXT",
    //               value: "",
    //             },
    //             openHeight: {
    //               displayName: "Open Height",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "",
    //             },
    //             width: {
    //               displayName: "Width",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "16",
    //             },
    //           },
    //         ],
    //         wainscots: [],
    //         walkDoors: [],
    //         partialWalls: [],
    //         lightPanels: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             quantity: {
    //               type: "NUMBER",
    //               displayName: "Quantity",
    //               value: "",
    //             },
    //           },
    //         ],
    //         extensions: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             start: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "Start",
    //               value: "",
    //               options: [],
    //             },
    //             end: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "End",
    //               value: "",
    //               options: [],
    //             },
    //             width: {
    //               type: "LENGTH",
    //               displayName: "Width",
    //               suffix: "ft",
    //               value: "",
    //             },
    //           },
    //         ],
    //       },
    //       rigidFrame: {
    //         column: {
    //           displayName: "Column",
    //           options: [
    //             {
    //               display: "Tapered",
    //               value: "tapered",
    //             },
    //             {
    //               display: "Constant",
    //               value: "constant",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         interiorColumn: {
    //           elevation: {
    //             displayName: "Elevation",
    //             suffix: "in",
    //             type: "ELEVATION",
    //             value: "-8",
    //           },
    //           locations: {
    //             displayName: "Locations",
    //             type: "TEXT",
    //             value: "",
    //           },
    //         },
    //         locations: {
    //           displayName: "Locations",
    //           type: "NUMBER",
    //           value: "",
    //         },
    //         rafter: {
    //           displayName: "Rafter",
    //           options: [
    //             {
    //               display: "Tapered",
    //               value: "tapered",
    //             },
    //             {
    //               display: "Constant",
    //               value: "constant",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //       },
    //       roof: {
    //         framedOpenings: [],
    //         lightPanels: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             quantity: {
    //               type: "NUMBER",
    //               displayName: "Quantity",
    //               value: "",
    //             },
    //           },
    //         ],
    //         liners: [],
    //         surfaceSpacings: [
    //           {
    //             numberOfBays: {
    //               displayName: "# Bays",
    //               type: "number",
    //               value: "5",
    //             },
    //             openCondition: {
    //               displayName: "Open Condition",
    //               type: "TEXT",
    //               value: "",
    //             },
    //             openHeight: {
    //               displayName: "Open Height",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "",
    //             },
    //             width: {
    //               displayName: "Width",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "24",
    //             },
    //           },
    //         ],
    //       },
    //       roofInsulation: {
    //         bySteelTech: {
    //           displayName: "Steel Tech",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         thickness: {
    //           displayName: "Thickness",
    //           suffix: "in",
    //           type: "NUMBER",
    //           value: "",
    //         },
    //         type: {
    //           displayName: "Type",
    //           options: [
    //             {
    //               display: "None",
    //               value: "none",
    //             },
    //             {
    //               display: "Single Layer",
    //               value: "singleLayer",
    //             },
    //             {
    //               display: "System",
    //               value: "system",
    //             },
    //             {
    //               display: "Spray Foam",
    //               value: "sprayFoam",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //       },
    //       shape: {
    //         heightLeft: {
    //           displayName: "Left Eave Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "18",
    //         },
    //         heightRight: {
    //           displayName: "Right Eave Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "18",
    //         },
    //         length: {
    //           displayName: "Length",
    //           suffix: "ft",
    //           type: "SPECIAL_CASE",
    //           value: "120",
    //         },
    //         peakOffset: {
    //           displayName: "Peak Offset",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: 40,
    //         },
    //         shape: {
    //           displayName: "Shape",
    //           options: [
    //             {
    //               display: "Single Slope",
    //               value: "singleSlope",
    //             },
    //             {
    //               display: "Symmetrical Double Slope",
    //               value: "symmetricalDoubleSlope",
    //             },
    //             {
    //               display: "Asymmetrical Double Slope",
    //               value: "asymmetricalDoubleSlope",
    //             },
    //           ],
    //           required: true,
    //           type: "STANDARD_DROPDOWN",
    //           value: "symmetricalDoubleSlope",
    //         },
    //         slope: {
    //           displayName: "Slope",
    //           type: "FLOAT",
    //           value: "1",
    //         },
    //         slopeRight: {
    //           displayName: "Right Slope",
    //           type: "FLOAT",
    //           value: "1",
    //         },
    //         width: {
    //           displayName: "Width",
    //           suffix: "ft",
    //           type: "SPECIAL_CASE",
    //           value: "80",
    //         },
    //       },
    //       surfaceSpacing: {
    //         numberOfBays: {
    //           displayName: "# Bays",
    //           type: "number",
    //           value: "",
    //         },
    //         openCondition: {
    //           displayName: "Open Condition",
    //           type: "TEXT",
    //           value: "",
    //         },
    //         openHeight: {
    //           displayName: "Open Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         width: {
    //           displayName: "Width",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //       },
    //       wainscot: {
    //         end: {
    //           displayName: "End",
    //           options: [],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         finish: {
    //           displayName: "Finish",
    //           options: [
    //             {
    //               display: "Galv",
    //               value: "galv",
    //             },
    //             {
    //               display: "Color",
    //               value: "color",
    //             },
    //             {
    //               display: "PVDF",
    //               value: "PVDF",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         fullHeight: {
    //           displayName: "Full Height",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         fullWidth: {
    //           displayName: "Full Width",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         height: {
    //           displayName: "Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         panel: {
    //           displayName: "Panel",
    //           options: [
    //             {
    //               display: "PBR-Panel",
    //               value: "pbrPanel",
    //             },
    //             {
    //               display: "Rev-PBR Panel",
    //               value: "revPbrPanel",
    //             },
    //             {
    //               display: "U Panel",
    //               value: "uPanel",
    //             },
    //             {
    //               display: "AG Panel",
    //               value: "agPanel",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         start: {
    //           displayName: "Start",
    //           options: [],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //       },
    //       walkDoor: {
    //         accessories: {
    //           closer: {
    //             displayName: "Closer",
    //             type: "BOOLEAN",
    //             value: "",
    //           },
    //           deadBolt: {
    //             displayName: "Dead Bolt",
    //             type: "BOOLEAN",
    //             value: "",
    //           },
    //           kickPlate: {
    //             displayName: "Kick Plate",
    //             type: "BOOLEAN",
    //             value: "",
    //           },
    //           latchGuard: {
    //             displayName: "Latch Guard",
    //             type: "BOOLEAN",
    //             value: "",
    //           },
    //           panicHardware: {
    //             displayName: "Panic Hardware",
    //             type: "BOOLEAN",
    //             value: "",
    //           },
    //           window: {
    //             displayName: "Window",
    //             options: [
    //               {
    //                 display: "None",
    //                 value: "none",
    //               },
    //               {
    //                 display: "Uninsulated",
    //                 value: "uninsulated",
    //               },
    //               {
    //                 display: "Insulated",
    //                 value: "insulated",
    //               },
    //             ],
    //             type: "STANDARD_DROPDOWN",
    //             value: "",
    //           },
    //         },
    //         bay: {
    //           displayName: "Bay",
    //           options: [],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         offset: {
    //           displayName: "Offset",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         size: {
    //           displayName: "Size",
    //           options: [
    //             {
    //               display: "3x7",
    //               value: "3x7",
    //             },
    //             {
    //               display: "4x7",
    //               value: "4x7",
    //             },
    //             {
    //               display: "6x7",
    //               value: "6x7",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //       },
    //       wallInsulation: {
    //         bySteelTech: {
    //           displayName: "Steel Tech",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         thickness: {
    //           displayName: "Thickness",
    //           suffix: "in",
    //           type: "NUMBER",
    //           value: "",
    //         },
    //         type: {
    //           displayName: "Type",
    //           options: [
    //             {
    //               display: "None",
    //               value: "none",
    //             },
    //             {
    //               display: "Single Layer",
    //               value: "singleLayer",
    //             },
    //             {
    //               display: "System",
    //               value: "system",
    //             },
    //             {
    //               display: "Spray Foam",
    //               value: "sprayFoam",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //       },
    //     },
    //   ],
    //   project: {
    //     loads: {
    //       groundSnow: {
    //         displayName: "Ground Snow",
    //         suffix: "psf",
    //         type: "FLOAT",
    //         value: "",
    //       },
    //       groundSnowReducible: {
    //         displayName: "Reducible",
    //         type: "BOOLEAN",
    //         value: "",
    //       },
    //       liveLoad: {
    //         displayName: "Live Load",
    //         suffix: "psf",
    //         type: "FLOAT",
    //         value: "20",
    //       },
    //       liveLoadReducible: {
    //         displayName: "Reducible",
    //         type: "BOOLEAN",
    //         value: true,
    //       },
    //       windExposure: {
    //         displayName: "Wind Exposure",
    //         options: [
    //           {
    //             display: "B",
    //             value: "b",
    //           },
    //           {
    //             display: "C",
    //             value: "c",
    //           },
    //           {
    //             display: "D",
    //             value: "d",
    //           },
    //         ],
    //         type: "STANDARD_DROPDOWN",
    //         value: "c",
    //       },
    //       windLoad: {
    //         displayName: "Wind Load",
    //         suffix: "mph",
    //         type: "FLOAT",
    //         value: "",
    //       },
    //     },
    //     primaryInfo: {
    //       dealerInfo: {
    //         address: {
    //           displayName: "Address",
    //           type: "TEXT",
    //           value: "",
    //         },
    //         city: {
    //           displayName: "City",
    //           type: "TEXT",
    //           value: "",
    //         },
    //         email: {
    //           displayName: "Email",
    //           type: "email",
    //           value: "",
    //         },
    //         name: {
    //           displayName: "Name",
    //           required: true,
    //           type: "TEXT",
    //           value: "",
    //         },
    //         phone: {
    //           displayName: "Phone",
    //           type: "PHONE",
    //           value: "",
    //         },
    //         state: {
    //           displayName: "State",
    //           type: "STATES_DROPDOWN",
    //           value: null,
    //         },
    //         zip: {
    //           displayName: "Zip",
    //           type: "number",
    //           value: "",
    //         },
    //       },
    //       projectInfo: {
    //         address: {
    //           displayName: "Address",
    //           type: "TEXT",
    //           value: "",
    //         },
    //         city: {
    //           displayName: "City",
    //           type: "TEXT",
    //           value: "",
    //         },
    //         contactEmail: {
    //           displayName: "Email",
    //           required: true,
    //           type: "email",
    //           value: "",
    //         },
    //         contactName: {
    //           displayName: "Name",
    //           required: true,
    //           type: "TEXT",
    //           value: "",
    //         },
    //         contactNumber: {
    //           displayName: "Phone",
    //           required: true,
    //           type: "PHONE",
    //           value: "",
    //         },
    //         projectName: {
    //           displayName: "Project Name",
    //           required: true,
    //           type: "TEXT",
    //           value: "80'-0\"x120'-0\"x18'-0\"",
    //         },
    //         state: {
    //           displayName: "State",
    //           type: "STATES_DROPDOWN",
    //           value: null,
    //         },
    //         zip: {
    //           displayName: "Zip",
    //           type: "number",
    //           value: "",
    //         },
    //       },
    //     },
    //   },
    // },
    // {
    //   buildings: [
    //     {
    //       accessories: {
    //         guttersAndDown: {
    //           displayName: "Gutters & Downspouts",
    //           type: "BOOLEAN",
    //           value: false,
    //         },
    //       },
    //       backSidewall: {
    //         framedOpenings: [],
    //         liners: [],
    //         surfaceSpacings: [
    //           {
    //             numberOfBays: {
    //               displayName: "# Bays",
    //               type: "number",
    //               value: "6",
    //             },
    //             openCondition: {
    //               displayName: "Open Condition",
    //               type: "TEXT",
    //               value: "",
    //             },
    //             openHeight: {
    //               displayName: "Open Height",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "",
    //             },
    //             width: {
    //               displayName: "Width",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "23.3333",
    //             },
    //           },
    //         ],
    //         wainscots: [],
    //         walkDoors: [],
    //         partialWalls: [],
    //         lightPanels: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             quantity: {
    //               type: "NUMBER",
    //               displayName: "Quantity",
    //               value: "",
    //             },
    //           },
    //         ],
    //         extensions: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             start: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "Start",
    //               value: "",
    //               options: [],
    //             },
    //             end: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "End",
    //               value: "",
    //               options: [],
    //             },
    //             width: {
    //               type: "LENGTH",
    //               displayName: "Width",
    //               suffix: "ft",
    //               value: "",
    //             },
    //           },
    //         ],
    //       },
    //       baseCondition: {
    //         baseTrim: {
    //           displayName: "Base Trim",
    //           options: [
    //             {
    //               display: "Base Trim",
    //               value: "baseTrim",
    //             },
    //             {
    //               display: "Return Base",
    //               value: "ratGuard",
    //             },
    //             {
    //               display: "Notch, No Trim",
    //               value: "notchNoTrim",
    //             },
    //             {
    //               display: "No Trim or Notch",
    //               value: "noTrimOrNotch",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "baseTrim",
    //         },
    //         slabOrPiers: {
    //           displayName: "Slab",
    //           options: [
    //             {
    //               display: "Slab",
    //               value: "slab",
    //             },
    //             {
    //               display: "Piers",
    //               value: "piers",
    //             },
    //           ],
    //           type: "RADIO_BUTTON",
    //           value: "slab",
    //         },
    //       },
    //       columnBaseElevation: {
    //         backSidewall: {
    //           displayName: "Back Sidewall",
    //           suffix: "in",
    //           type: "ELEVATION",
    //           value: "",
    //         },
    //         frontSidewall: {
    //           displayName: "Front Sidewall",
    //           suffix: "in",
    //           type: "ELEVATION",
    //           value: "",
    //         },
    //         leftEndwall: {
    //           displayName: "Left Endwall",
    //           suffix: "in",
    //           type: "ELEVATION",
    //           value: "",
    //         },
    //         rightEndwall: {
    //           displayName: "Right Endwall",
    //           suffix: "in",
    //           type: "ELEVATION",
    //           value: "",
    //         },
    //       },
    //       exteriorPanels: {
    //         roofFinish: {
    //           displayName: "Finish",
    //           options: [
    //             {
    //               display: "Galv",
    //               value: "galv",
    //             },
    //             {
    //               display: "Color",
    //               value: "color",
    //             },
    //             {
    //               display: "PVDF",
    //               value: "PVDF",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "color",
    //         },
    //         roofPanel: {
    //           displayName: "Panel",
    //           options: [
    //             {
    //               display: "Trapezoidal Standing Seam",
    //               value: "standingSeamTrapezoidal",
    //             },
    //             {
    //               display: "Vertical Standing Seam ",
    //               value: "standingSeamVertical",
    //             },
    //             {
    //               display: "PBR 26",
    //               value: "pbr26",
    //             },
    //             {
    //               display: "PBR 24",
    //               value: "pbr24",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "pbr26",
    //         },
    //         wallFinish: {
    //           displayName: "Finish",
    //           options: [
    //             {
    //               display: "Galv",
    //               value: "galv",
    //             },
    //             {
    //               display: "Color",
    //               value: "color",
    //             },
    //             {
    //               display: "PVDF",
    //               value: "PVDF",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "color",
    //         },
    //         wallPanel: {
    //           displayName: "Panel",
    //           options: [
    //             {
    //               display: "PBR 26",
    //               value: "pbr26",
    //             },
    //             {
    //               display: "PBR 24",
    //               value: "pbr24",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "pbr26",
    //         },
    //       },
    //       framedOpening: {
    //         baseElevation: {
    //           displayName: "Base Elevation",
    //           suffix: "ft",
    //           type: "ELEVATION",
    //           value: "",
    //         },
    //         bay: {
    //           displayName: "Bay",
    //           options: [],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         framingType: {
    //           displayName: "Framing Type",
    //           options: [
    //             {
    //               display: "No Framing",
    //               value: "noFraming",
    //             },
    //             {
    //               display: "Jamb, Base to Girt",
    //               value: "jambBaseToGirt",
    //             },
    //             {
    //               display: "Jamb, Base to Roof",
    //               value: "jambBaseToRoof",
    //             },
    //             {
    //               display: "Jamb, Girt to Girt",
    //               value: "jambGirtToGirt",
    //             },
    //             {
    //               display: "Open Bay",
    //               value: "openBay",
    //             },
    //             {
    //               display: "Jamb, Base to Added Girt",
    //               value: "jambBaseToAddedGirt",
    //             },
    //             {
    //               display: "Strip Window",
    //               value: "stripWindow",
    //             },
    //             {
    //               display: "Jamb, Girt to Girt (No Girt Cut)",
    //               value: "jambGirtToGirtNoGirtCut",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         headerHeight: {
    //           displayName: "Header Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         offset: {
    //           displayName: "Offset",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         openingType: {
    //           displayName: "Opening Type",
    //           options: [
    //             {
    //               display: "Walk Door",
    //               value: "walkDoor",
    //             },
    //             {
    //               display: "Self Flashing Window",
    //               value: "selfFlashingWindow",
    //             },
    //             {
    //               display: "Standard Window",
    //               value: "standardWindow",
    //             },
    //             {
    //               display: "Overhead Door",
    //               value: "overheadDoor",
    //             },
    //             {
    //               display: "IS Roll-Up Door",
    //               value: "isRollUpDoor",
    //             },
    //             {
    //               display: "OS Roll-Up Door",
    //               value: "osRollUpDoor",
    //             },
    //             {
    //               display: "Louver",
    //               value: "louver",
    //             },
    //             {
    //               display: "Overhead Door Blocking",
    //               value: "overheadDoorBlocking",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         panelOption: {
    //           displayName: "Panel Option",
    //           options: [
    //             {
    //               display: "Sheet Over Opening",
    //               value: "sheetOverOpening",
    //             },
    //             {
    //               display: "Remove Panel",
    //               value: "removePanel",
    //             },
    //             {
    //               display: "Field Located",
    //               value: "fieldLocated",
    //             },
    //             {
    //               display: "Girts in Opening",
    //               value: "girtsInOpening",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         sillHeight: {
    //           displayName: "Sill Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         width: {
    //           displayName: "Width",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //       },
    //       frontSidewall: {
    //         framedOpenings: [],
    //         liners: [],
    //         surfaceSpacings: [
    //           {
    //             numberOfBays: {
    //               displayName: "# Bays",
    //               type: "number",
    //               value: "6",
    //             },
    //             openCondition: {
    //               displayName: "Open Condition",
    //               type: "TEXT",
    //               value: "",
    //             },
    //             openHeight: {
    //               displayName: "Open Height",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "",
    //             },
    //             width: {
    //               displayName: "Width",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "23.3333",
    //             },
    //           },
    //         ],
    //         wainscots: [],
    //         walkDoors: [],
    //         partialWalls: [],
    //         lightPanels: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             quantity: {
    //               type: "NUMBER",
    //               displayName: "Quantity",
    //               value: "",
    //             },
    //           },
    //         ],
    //         extensions: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             start: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "Start",
    //               value: "",
    //               options: [],
    //             },
    //             end: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "End",
    //               value: "",
    //               options: [],
    //             },
    //             width: {
    //               type: "LENGTH",
    //               displayName: "Width",
    //               suffix: "ft",
    //               value: "",
    //             },
    //           },
    //         ],
    //       },
    //       initiated: false,
    //       leftEndwall: {
    //         mirroredBays: {
    //           type: "BOOLEAN",
    //           displayName: "Mirrored",
    //           value: "",
    //         },
    //         expandable: {
    //           displayName: "Expandable",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         framedOpenings: [],
    //         liners: [],
    //         surfaceSpacings: [
    //           {
    //             numberOfBays: {
    //               displayName: "# Bays",
    //               type: "number",
    //               value: "5",
    //             },
    //             openCondition: {
    //               displayName: "Open Condition",
    //               type: "TEXT",
    //               value: "",
    //             },
    //             openHeight: {
    //               displayName: "Open Height",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "",
    //             },
    //             width: {
    //               displayName: "Width",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "20",
    //             },
    //           },
    //         ],
    //         wainscots: [],
    //         walkDoors: [],
    //         partialWalls: [],
    //         lightPanels: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             quantity: {
    //               type: "NUMBER",
    //               displayName: "Quantity",
    //               value: "",
    //             },
    //           },
    //         ],
    //         extensions: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             start: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "Start",
    //               value: "",
    //               options: [],
    //             },
    //             end: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "End",
    //               value: "",
    //               options: [],
    //             },
    //             width: {
    //               type: "LENGTH",
    //               displayName: "Width",
    //               suffix: "ft",
    //               value: "",
    //             },
    //           },
    //         ],
    //       },
    //       liner: {
    //         end: {
    //           displayName: "End",
    //           options: [],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         finish: {
    //           displayName: "Finish",
    //           options: [
    //             {
    //               display: "Galv",
    //               value: "galv",
    //             },
    //             {
    //               display: "Color",
    //               value: "color",
    //             },
    //             {
    //               display: "PVDF",
    //               value: "PVDF",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         fullHeight: {
    //           displayName: "Full Height",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         fullWidth: {
    //           displayName: "Full Width",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         linerHeight: {
    //           displayName: "Liner Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         panel: {
    //           displayName: "Panel",
    //           options: [
    //             {
    //               display: "PBR-Panel",
    //               value: "pbrPanel",
    //             },
    //             {
    //               display: "Rev-PBR Panel",
    //               value: "revPbrPanel",
    //             },
    //             {
    //               display: "U Panel",
    //               value: "uPanel",
    //             },
    //             {
    //               display: "AG Panel",
    //               value: "agPanel",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         start: {
    //           displayName: "Start",
    //           options: [],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //       },
    //       loads: {
    //         buildingUse: {
    //           displayName: "Building Use",
    //           type: "TEXT",
    //           value: "",
    //         },
    //         buildingOccupancy: {
    //           type: "STANDARD_DROPDOWN",
    //           displayName: "Building Occupancy",
    //           value: "",
    //           options: [
    //             {
    //               display: "I - Low, Storage, Warehouse, or Agri",
    //               value: "i",
    //             },
    //             {
    //               display: "II - Normal, Standard Building",
    //               value: "ii",
    //             },
    //             {
    //               display: "III - High Schools, Large Occupancy",
    //               value: "iii",
    //             },
    //             {
    //               display: "IV - Post Essential (Fire Dept., Hospital)",
    //               value: "iv",
    //             },
    //           ],
    //         },
    //         collateralLoad: {
    //           displayName: "Collateral Load",
    //           type: "FLOAT",
    //           value: "1.0",
    //         },
    //         slipperyRoof: {
    //           displayName: "Slippery Roof",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         thermalFactor: {
    //           displayName: "Thermal Factor",
    //           options: [
    //             {
    //               display: "Heated",
    //               value: "heated",
    //             },
    //             {
    //               display: "Unheated",
    //               value: "unheated",
    //             },
    //             {
    //               display: "Greenhouse",
    //               value: "greenhouse",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "unheated",
    //         },
    //       },
    //       notes: {
    //         buildingNotes: {
    //           displayName: "Building Notes",
    //           type: "AREA_TEXT",
    //           value: "",
    //         },
    //       },
    //       rightEndwall: {
    //         mirroredBays: {
    //           type: "BOOLEAN",
    //           displayName: "Mirrored",
    //           value: "",
    //         },
    //         expandable: {
    //           displayName: "Expandable",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         framedOpenings: [],
    //         liners: [],
    //         surfaceSpacings: [
    //           {
    //             numberOfBays: {
    //               displayName: "# Bays",
    //               type: "number",
    //               value: "5",
    //             },
    //             openCondition: {
    //               displayName: "Open Condition",
    //               type: "TEXT",
    //               value: "",
    //             },
    //             openHeight: {
    //               displayName: "Open Height",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "",
    //             },
    //             width: {
    //               displayName: "Width",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "20",
    //             },
    //           },
    //         ],
    //         wainscots: [],
    //         walkDoors: [],
    //         partialWalls: [],
    //         lightPanels: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             quantity: {
    //               type: "NUMBER",
    //               displayName: "Quantity",
    //               value: "",
    //             },
    //           },
    //         ],
    //         extensions: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             start: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "Start",
    //               value: "",
    //               options: [],
    //             },
    //             end: {
    //               type: "STANDARD_DROPDOWN",
    //               displayName: "End",
    //               value: "",
    //               options: [],
    //             },
    //             width: {
    //               type: "LENGTH",
    //               displayName: "Width",
    //               suffix: "ft",
    //               value: "",
    //             },
    //           },
    //         ],
    //       },
    //       rigidFrame: {
    //         column: {
    //           displayName: "Column",
    //           options: [
    //             {
    //               display: "Tapered",
    //               value: "tapered",
    //             },
    //             {
    //               display: "Constant",
    //               value: "constant",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         interiorColumn: {
    //           elevation: {
    //             displayName: "Elevation",
    //             suffix: "in",
    //             type: "ELEVATION",
    //             value: "-8",
    //           },
    //           locations: {
    //             displayName: "Locations",
    //             type: "TEXT",
    //             value: "",
    //           },
    //         },
    //         locations: {
    //           displayName: "Locations",
    //           type: "NUMBER",
    //           value: "",
    //         },
    //         rafter: {
    //           displayName: "Rafter",
    //           options: [
    //             {
    //               display: "Tapered",
    //               value: "tapered",
    //             },
    //             {
    //               display: "Constant",
    //               value: "constant",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //       },
    //       roof: {
    //         framedOpenings: [],
    //         lightPanels: [
    //           {
    //             fullWidth: {
    //               type: "BOOLEAN",
    //               displayName: "Full Width",
    //               value: "",
    //             },
    //             quantity: {
    //               type: "NUMBER",
    //               displayName: "Quantity",
    //               value: "",
    //             },
    //           },
    //         ],
    //         liners: [],
    //         surfaceSpacings: [
    //           {
    //             numberOfBays: {
    //               displayName: "# Bays",
    //               type: "number",
    //               value: "6",
    //             },
    //             openCondition: {
    //               displayName: "Open Condition",
    //               type: "TEXT",
    //               value: "",
    //             },
    //             openHeight: {
    //               displayName: "Open Height",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "",
    //             },
    //             width: {
    //               displayName: "Width",
    //               suffix: "ft",
    //               type: "LENGTH",
    //               value: "23.3333",
    //             },
    //           },
    //         ],
    //       },
    //       roofInsulation: {
    //         bySteelTech: {
    //           displayName: "Steel Tech",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         thickness: {
    //           displayName: "Thickness",
    //           suffix: "in",
    //           type: "NUMBER",
    //           value: "",
    //         },
    //         type: {
    //           displayName: "Type",
    //           options: [
    //             {
    //               display: "None",
    //               value: "none",
    //             },
    //             {
    //               display: "Single Layer",
    //               value: "singleLayer",
    //             },
    //             {
    //               display: "System",
    //               value: "system",
    //             },
    //             {
    //               display: "Spray Foam",
    //               value: "sprayFoam",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //       },
    //       shape: {
    //         heightLeft: {
    //           displayName: "Left Eave Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "20",
    //         },
    //         heightRight: {
    //           displayName: "Right Eave Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "20",
    //         },
    //         length: {
    //           displayName: "Length",
    //           suffix: "ft",
    //           type: "SPECIAL_CASE",
    //           value: "140",
    //         },
    //         peakOffset: {
    //           displayName: "Peak Offset",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: 50,
    //         },
    //         shape: {
    //           displayName: "Shape",
    //           options: [
    //             {
    //               display: "Single Slope",
    //               value: "singleSlope",
    //             },
    //             {
    //               display: "Symmetrical Double Slope",
    //               value: "symmetricalDoubleSlope",
    //             },
    //             {
    //               display: "Asymmetrical Double Slope",
    //               value: "asymmetricalDoubleSlope",
    //             },
    //           ],
    //           required: true,
    //           type: "STANDARD_DROPDOWN",
    //           value: "symmetricalDoubleSlope",
    //         },
    //         slope: {
    //           displayName: "Slope",
    //           type: "FLOAT",
    //           value: "1",
    //         },
    //         slopeRight: {
    //           displayName: "Right Slope",
    //           type: "FLOAT",
    //           value: "1",
    //         },
    //         width: {
    //           displayName: "Width",
    //           suffix: "ft",
    //           type: "SPECIAL_CASE",
    //           value: "100",
    //         },
    //       },
    //       surfaceSpacing: {
    //         numberOfBays: {
    //           displayName: "# Bays",
    //           type: "number",
    //           value: "",
    //         },
    //         openCondition: {
    //           displayName: "Open Condition",
    //           type: "TEXT",
    //           value: "",
    //         },
    //         openHeight: {
    //           displayName: "Open Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         width: {
    //           displayName: "Width",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //       },
    //       wainscot: {
    //         end: {
    //           displayName: "End",
    //           options: [],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         finish: {
    //           displayName: "Finish",
    //           options: [
    //             {
    //               display: "Galv",
    //               value: "galv",
    //             },
    //             {
    //               display: "Color",
    //               value: "color",
    //             },
    //             {
    //               display: "PVDF",
    //               value: "PVDF",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         fullHeight: {
    //           displayName: "Full Height",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         fullWidth: {
    //           displayName: "Full Width",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         height: {
    //           displayName: "Height",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         panel: {
    //           displayName: "Panel",
    //           options: [
    //             {
    //               display: "PBR-Panel",
    //               value: "pbrPanel",
    //             },
    //             {
    //               display: "Rev-PBR Panel",
    //               value: "revPbrPanel",
    //             },
    //             {
    //               display: "U Panel",
    //               value: "uPanel",
    //             },
    //             {
    //               display: "AG Panel",
    //               value: "agPanel",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         start: {
    //           displayName: "Start",
    //           options: [],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //       },
    //       walkDoor: {
    //         accessories: {
    //           closer: {
    //             displayName: "Closer",
    //             type: "BOOLEAN",
    //             value: "",
    //           },
    //           deadBolt: {
    //             displayName: "Dead Bolt",
    //             type: "BOOLEAN",
    //             value: "",
    //           },
    //           kickPlate: {
    //             displayName: "Kick Plate",
    //             type: "BOOLEAN",
    //             value: "",
    //           },
    //           latchGuard: {
    //             displayName: "Latch Guard",
    //             type: "BOOLEAN",
    //             value: "",
    //           },
    //           panicHardware: {
    //             displayName: "Panic Hardware",
    //             type: "BOOLEAN",
    //             value: "",
    //           },
    //           window: {
    //             displayName: "Window",
    //             options: [
    //               {
    //                 display: "None",
    //                 value: "none",
    //               },
    //               {
    //                 display: "Uninsulated",
    //                 value: "uninsulated",
    //               },
    //               {
    //                 display: "Insulated",
    //                 value: "insulated",
    //               },
    //             ],
    //             type: "STANDARD_DROPDOWN",
    //             value: "",
    //           },
    //         },
    //         bay: {
    //           displayName: "Bay",
    //           options: [],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //         offset: {
    //           displayName: "Offset",
    //           suffix: "ft",
    //           type: "LENGTH",
    //           value: "",
    //         },
    //         size: {
    //           displayName: "Size",
    //           options: [
    //             {
    //               display: "3x7",
    //               value: "3x7",
    //             },
    //             {
    //               display: "4x7",
    //               value: "4x7",
    //             },
    //             {
    //               display: "6x7",
    //               value: "6x7",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //       },
    //       wallInsulation: {
    //         bySteelTech: {
    //           displayName: "Steel Tech",
    //           type: "BOOLEAN",
    //           value: "",
    //         },
    //         thickness: {
    //           displayName: "Thickness",
    //           suffix: "in",
    //           type: "NUMBER",
    //           value: "",
    //         },
    //         type: {
    //           displayName: "Type",
    //           options: [
    //             {
    //               display: "None",
    //               value: "none",
    //             },
    //             {
    //               display: "Single Layer",
    //               value: "singleLayer",
    //             },
    //             {
    //               display: "System",
    //               value: "system",
    //             },
    //             {
    //               display: "Spray Foam",
    //               value: "sprayFoam",
    //             },
    //           ],
    //           type: "STANDARD_DROPDOWN",
    //           value: "",
    //         },
    //       },
    //     },
    //   ],
    //   project: {
    //     loads: {
    //       groundSnow: {
    //         displayName: "Ground Snow",
    //         suffix: "psf",
    //         type: "FLOAT",
    //         value: "",
    //       },
    //       groundSnowReducible: {
    //         displayName: "Reducible",
    //         type: "BOOLEAN",
    //         value: "",
    //       },
    //       liveLoad: {
    //         displayName: "Live Load",
    //         suffix: "psf",
    //         type: "FLOAT",
    //         value: "20",
    //       },
    //       liveLoadReducible: {
    //         displayName: "Reducible",
    //         type: "BOOLEAN",
    //         value: true,
    //       },
    //       windExposure: {
    //         displayName: "Wind Exposure",
    //         options: [
    //           {
    //             display: "B",
    //             value: "b",
    //           },
    //           {
    //             display: "C",
    //             value: "c",
    //           },
    //           {
    //             display: "D",
    //             value: "d",
    //           },
    //         ],
    //         type: "STANDARD_DROPDOWN",
    //         value: "c",
    //       },
    //       windLoad: {
    //         displayName: "Wind Load",
    //         suffix: "mph",
    //         type: "FLOAT",
    //         value: "",
    //       },
    //     },
    //     primaryInfo: {
    //       dealerInfo: {
    //         address: {
    //           displayName: "Address",
    //           type: "TEXT",
    //           value: "",
    //         },
    //         city: {
    //           displayName: "City",
    //           type: "TEXT",
    //           value: "",
    //         },
    //         email: {
    //           displayName: "Email",
    //           type: "email",
    //           value: "",
    //         },
    //         name: {
    //           displayName: "Name",
    //           required: true,
    //           type: "TEXT",
    //           value: "",
    //         },
    //         phone: {
    //           displayName: "Phone",
    //           type: "PHONE",
    //           value: "",
    //         },
    //         state: {
    //           displayName: "State",
    //           type: "STATES_DROPDOWN",
    //           value: null,
    //         },
    //         zip: {
    //           displayName: "Zip",
    //           type: "number",
    //           value: "",
    //         },
    //       },
    //       projectInfo: {
    //         address: {
    //           displayName: "Address",
    //           type: "TEXT",
    //           value: "",
    //         },
    //         city: {
    //           displayName: "City",
    //           type: "TEXT",
    //           value: "",
    //         },
    //         contactEmail: {
    //           displayName: "Email",
    //           required: true,
    //           type: "email",
    //           value: "",
    //         },
    //         contactName: {
    //           displayName: "Name",
    //           required: true,
    //           type: "TEXT",
    //           value: "",
    //         },
    //         contactNumber: {
    //           displayName: "Phone",
    //           required: true,
    //           type: "PHONE",
    //           value: "",
    //         },
    //         projectName: {
    //           displayName: "Project Name",
    //           required: true,
    //           type: "TEXT",
    //           value: "100'-0\"x140'-0\"x20'-0\"",
    //         },
    //         state: {
    //           displayName: "State",
    //           type: "STATES_DROPDOWN",
    //           value: null,
    //         },
    //         zip: {
    //           displayName: "Zip",
    //           type: "number",
    //           value: "",
    //         },
    //       },
    //     },
    //   },
    // },
  ],
  activeTab: 0,
  rules: {
    length: [
      (v) =>
        /^(?!$|.*'[^\x22]+$)(?:([0-9]+)')?(?:([0-9]+)\x22?)?$/.test(v) ||
        "Must be in feet'inches\" format",
    ],
    email: [
      (v) =>
        (!!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
        "Email must be a valid",
    ],
    phoneNumber: [
      (v) =>
        (!!v && /^\(?[\d]{3}\)?[\s-.]?[\d]{3}[\s-.]?[\d]{4}$/.test(v)) ||
        "Phone Number must be valid",
    ],
  },
  formCheck: 0,
  formValid: false,
  attachment: {},
  buildingSubmitted: false,
}
