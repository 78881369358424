<template>
  <v-container>
    <v-row class="pt-15">
      <h2>Upload An MBS Quote File</h2>
    </v-row>
    <v-row>
      <v-file-input v-model="file" show-size></v-file-input>
    </v-row>
    <v-row>
      <h5>File must be of type '.mbsQuote'</h5>
    </v-row>
    <v-row>
      <v-btn color="primary" @click="uploadFile(file)">Submit</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "Upload",

  components: {},

  methods: {
    ...mapActions({
      setStateFromFile: "data/setStateFromFile",
    }),
    uploadToState(file) {
      return this.setStateFromFile(file)
    },
    uploadFile(file) {
      let reader = new FileReader()
      let self = this
      try {
        if (file.name.includes(".mbsQuote")) {
          let results = {}
          reader.onload = function () {
            results = reader.result
          }
          reader.readAsText(file, "UTF-8") // fileReader.result -> String.
          reader.onloadend = function () {
            self.uploadToState(results)
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
  },

  data: () => ({
    file: [],
    uploadDialog: true,
  }),
}
</script>
