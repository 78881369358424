import Vue from "vue"
import VueRouter from "vue-router"
import Index from "../views/Index.vue"
import FormIndex from "../views/form/Index.vue"
import ProjectIndex from "../views/project/Index.vue"
import BuildingIndex from "../views/building/Index.vue"
import AuthIndex from "../views/auth/Index.vue"
import Upload from "../views/form/Upload.vue"
import { getAuth } from "firebase/auth"
import { firebaseApp } from "../plugins/firebase"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Index,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/upload",
    name: "Upload",
    component: Upload,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: AuthIndex,
    meta: {
      notAuthorized: true,
    },
  },
  {
    path: "/quote",
    name: "Quote",
    component: FormIndex,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "project",
        name: "Project",
        component: ProjectIndex,
        meta: {
          authRequired: true,
        },
      },
      {
        path: "building/:buildingIndex",
        name: "Building",
        component: BuildingIndex,
        meta: {
          authRequired: true,
        },
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
    meta: {
      authRequired: true,
    },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    const currentUser = getAuth(firebaseApp).currentUser
    if (currentUser) {
      next()
    } else {
      next({
        path: "/login",
      })
    }
  } else {
    next()
  }
  if (to.matched.some((record) => record.meta.notAuthorized)) {
    const currentUser = getAuth(firebaseApp).currentUser
    if (!currentUser) {
      next()
    } else {
      next({
        path: "/",
      })
    }
  } else {
    next()
  }
})

export default router
