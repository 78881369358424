<template>
  <v-container>
    <v-row>
      <div :id="`stage${type}`" width="100%" height="100%">
        <canvas :id="`c${type}`"></canvas>
      </div>
    </v-row>
    <v-row class="pt-2">
      <v-btn @click="createCanvas()">Draw</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex"
import { lineSegmentsIntercept } from "@/utils/validation"

export default {
  /* eslint-disable no-unused-vars */
  name: "Canvas",

  props: {
    buildingIndex: String,
    type: String,
  },

  data: () => ({
    vueCanvas: null,
    x: null,
    y: null,
    scaleFactorX: 1,
    scaleFactorY: 1,
    points: [],
    maxHeight: 0,
  }),

  computed: {
    ...mapState({
      buildings: (state) => state.data.buildings,
    }),
    building() {
      return this.buildings[this.buildingIndex]
    },
    buildingWidth() {
      return parseFloat(this.building.shape.width.value)
    },
    buildingInteriorColumns() {
      return this.building.rigidFrame.interiorColumn.locations.value
    },
    leftEaveHeight() {
      return parseFloat(this.building.shape.heightLeft.value)
    },
    rightEaveHeight() {
      return parseFloat(this.building.shape.heightRight.value)
    },
    buildingPeakOffset() {
      return parseFloat(this.building.shape.peakOffset.value)
    },
    buildingLength() {
      return parseFloat(this.building.shape.length.value)
    },
    buildingSlope() {
      return parseFloat(this.building.shape.slope.value)
    },
    canvasWidth() {
      return parseFloat(this.buildingWidth) + 15
    },
    surfaceBays() {
      return this.building[this.type].surfaceSpacings
    },
    walkDoors() {
      return this.building[this.type].walkDoors
    },
    framedOpenings() {
      return this.building[this.type].framedOpenings
    },
    wainscots() {
      return this.building[this.type].wainscots
    },
    partialWalls() {
      return this.building[this.type].partialWalls
    },
    liners() {
      return this.building[this.type].liners
    },
  },

  watch: {
    buildingWidth() {
      return this.createCanvas()
    },
    buildingInteriorColumns() {
      return this.createCanvas()
    },
    buildingLength() {
      return this.createCanvas()
    },
    leftEaveHeight() {
      return this.createCanvas()
    },
    rightEaveHeight() {
      return this.createCanvas()
    },
    buildingPeakOffset() {
      return this.createCanvas()
    },
    buildingSlope() {
      return this.createCanvas()
    },
    surfaceBays: {
      deep: true,
      handler() {
        this.createCanvas()
      },
    },
    walkDoors: {
      deep: true,
      handler() {
        this.createCanvas()
      },
    },
    framedOpenings: {
      deep: true,
      handler() {
        this.createCanvas()
      },
    },
    wainscots: {
      deep: true,
      handler() {
        this.createCanvas()
      },
    },
    partialWalls: {
      deep: true,
      handler() {
        this.createCanvas()
      },
    },
    liners: {
      deep: true,
      handler() {
        this.createCanvas()
      },
    },
  },

  mounted() {
    const c = document.getElementById(`c${this.type}`)
    const ctx = c.getContext("2d")
    this.vueCanvas = ctx
    this.createCanvas()
  },

  methods: {
    createCanvas() {
      const stage = document.getElementById(`stage${this.type}`)
      const { vueCanvas } = this
      vueCanvas.canvas.width = stage.offsetWidth
      vueCanvas.canvas.height = stage.offsetHeight
      // this.vueCanvas.transform(1, 0, 0, -1, 0, this.vueCanvas.canvas.height)
      this.scaleCanvas(stage)
      this.prepareWall()
    },

    scaleCanvas(stage) {
      const { buildingWidth, buildingLength } = this
      const heightL = this.leftEaveHeight
      const heightR = this.rightEaveHeight
      let width = buildingWidth
      const peak =
        this.leftEaveHeight +
        (this.buildingPeakOffset * this.buildingSlope) / 12
      let scaleMultiplier = 0.85
      if (this.type.includes("Endwall")) {
        this.maxHeight = Math.max(heightL, heightR, peak)
      } else if (this.type.includes("roof")) {
        this.maxHeight = buildingWidth
        width = buildingLength
      } else {
        width = buildingLength
        this.maxHeight = Math.max(heightL, heightR)
      }
      this.scaleFactorX = (stage.offsetWidth * scaleMultiplier) / width
      this.scaleFactorY =
        (stage.offsetHeight * scaleMultiplier) / this.maxHeight
      this.scaleFactorX = Math.min(this.scaleFactorX, this.scaleFactorY)
      this.scaleFactorY = -this.scaleFactorX
      this.vueCanvas.scale(this.scaleFactorX, this.scaleFactorY)
    },

    prepareWall() {
      const {
        vueCanvas,
        buildingWidth,
        buildingLength,
        scaleFactorX,
        scaleFactorY,
      } = this
      let heightL = this.leftEaveHeight
      let heightR = this.rightEaveHeight
      let peak = this.buildingPeakOffset
      const c = document.getElementById(`c${this.type}`)
      vueCanvas.clearRect(0, 0, 1000, 1000)

      if (this.type === "rightEndwall") {
        heightL = this.rightEaveHeight
        heightR = this.leftEaveHeight
        peak = buildingWidth - this.buildingPeakOffset
      }
      switch (this.type) {
        case "roof":
          this.x = c.width / 2 / scaleFactorX - buildingLength / 2
          this.y = c.height / 2 / scaleFactorY - buildingWidth / 2
          this.drawWall([
            { x: this.x, y: this.y + buildingWidth },
            {
              x: this.x + buildingLength,
              y: this.y + buildingWidth,
            },
            { x: this.x + buildingLength, y: this.y },
          ])
          this.drawColumns()
          // this.drawWalkDoors()
          this.drawFramedOpenings()
          // this.drawWainscots()
          this.drawLiners()
          // this.drawDimensions()
          break
        case "leftEndwall":
          this.x = c.width / 2 / scaleFactorX - buildingWidth / 2
          this.y = c.height / 2 / scaleFactorY - this.maxHeight / 2
          this.drawWall([
            { x: this.x, y: this.y + heightL },
            {
              x: this.x + peak,
              y:
                this.y +
                this.leftEaveHeight +
                (peak * this.buildingSlope) / 12.0,
            },
            { x: this.x + buildingWidth, y: this.y + heightR },
            { x: this.x + buildingWidth, y: this.y },
          ])
          this.drawColumns()
          this.drawWalkDoors()
          this.drawFramedOpenings()
          this.drawWainscots()
          this.drawPartialWalls()
          this.drawLiners()
          // this.drawDimensions()
          break
        case "rightEndwall":
          this.x = c.width / 2 / scaleFactorX - buildingWidth / 2
          this.y = c.height / 2 / scaleFactorY - this.maxHeight / 2
          this.drawWall([
            { x: this.x, y: this.y + heightL },
            {
              x: this.x + peak,
              y:
                this.y +
                this.leftEaveHeight +
                (this.buildingPeakOffset * this.buildingSlope) / 12.0,
            },
            { x: this.x + buildingWidth, y: this.y + heightR },
            { x: this.x + buildingWidth, y: this.y },
          ])
          this.drawColumns()
          this.drawWalkDoors()
          this.drawFramedOpenings()
          this.drawWainscots()
          this.drawPartialWalls()
          this.drawLiners()
          // this.drawDimensions()
          break
        case "frontSidewall":
          this.x = c.width / 2 / scaleFactorX - buildingLength / 2
          this.y = c.height / 2 / scaleFactorY - this.maxHeight / 2
          this.drawWall([
            { x: this.x, y: this.y + heightR },
            { x: this.x + buildingLength, y: this.y + heightR },
            { x: this.x + buildingLength, y: this.y },
          ])
          this.drawColumns()
          this.drawWalkDoors()
          this.drawFramedOpenings()
          this.drawWainscots()
          this.drawPartialWalls()
          this.drawLiners()
          // this.drawDimensions()
          break
        case "backSidewall":
          this.x = c.width / 2 / scaleFactorX - buildingLength / 2
          this.y = c.height / 2 / scaleFactorY - this.maxHeight / 2
          this.drawWall([
            { x: this.x, y: this.y + heightL },
            { x: this.x + buildingLength, y: this.y + heightL },
            { x: this.x + buildingLength, y: this.y },
          ])
          this.drawColumns()
          this.drawWalkDoors()
          this.drawFramedOpenings()
          this.drawWainscots()
          this.drawPartialWalls()
          this.drawLiners()
          // this.drawDimensions()
          break
      }
    },

    drawWall(points) {
      const { vueCanvas } = this

      vueCanvas.beginPath()
      vueCanvas.moveTo(this.x, this.y)
      points.forEach((point) => {
        vueCanvas.lineTo(point.x, point.y)
      })
      vueCanvas.closePath()
      vueCanvas.strokeStyle = "red"
      vueCanvas.lineWidth = "0.25"
      vueCanvas.stroke()
    },

    drawColumns() {
      const { vueCanvas, surfaceBays } = this
      const points = []
      const peakHeight =
        this.leftEaveHeight +
        (this.buildingSlope / 12) * this.buildingPeakOffset
      const rightSlope =
        (peakHeight - this.rightEaveHeight) /
        (this.buildingWidth - this.buildingPeakOffset)
      let bays = []
      let width = 0
      surfaceBays.map((bay) => {
        for (let i = 0; i < bay.numberOfBays.value; i++) {
          width = width + parseFloat(bay.width.value)
          if (this.type === "rightEndwall")
            bays.push(this.buildingWidth - width)
          else if (this.type === "backSidewall")
            bays.push(this.buildingLength - width)
          else bays.push(width)
        }
      })
      bays.forEach((s1) => {
        let left = {}
        let right = {}
        switch (this.type) {
          case "leftEndwall":
            left = {
              x: this.x + s1,
              y: this.y + this.leftEaveHeight + (this.buildingSlope / 12) * s1,
            }
            right = {
              x: this.x + s1,
              y:
                this.y +
                peakHeight +
                (this.buildingPeakOffset - s1) * rightSlope,
            }
            if (this.buildingPeakOffset >= s1) points.push(left)
            else points.push(right)
            break
          case "rightEndwall":
            left = {
              x: this.x + s1,
              y: this.y + this.rightEaveHeight + rightSlope * s1,
            }
            right = {
              x: this.x + s1,
              y:
                this.y +
                (this.leftEaveHeight +
                  (this.buildingSlope / 12) *
                    (this.buildingWidth - this.buildingPeakOffset)) +
                (this.buildingPeakOffset - s1) * (this.buildingSlope / 12),
            }
            if (this.buildingWidth - this.buildingPeakOffset > s1)
              points.push(left)
            else points.push(right)
            break
          case "frontSidewall":
            right = {
              x: this.x + s1,
              y: this.y + this.rightEaveHeight,
            }
            points.push(right)
            break
          case "backSidewall":
            left = {
              x: this.x + s1,
              y: this.y + this.leftEaveHeight,
            }
            points.push(left)
            break
          case "roof":
            left = {
              x: this.x + s1,
              y: this.y + this.buildingWidth,
            }
            points.push(left)
            break
        }
      })
      vueCanvas.beginPath()
      points.forEach((point) => {
        vueCanvas.moveTo(point.x, this.y)
        vueCanvas.lineTo(point.x, point.y)
      })
      vueCanvas.strokeStyle = "red"
      vueCanvas.lineWidth = "0.15"
      vueCanvas.stroke()
    },

    drawWalkDoors() {
      const { vueCanvas, walkDoors, surfaceBays } = this
      let points = []
      let bays = []
      let width = 0
      surfaceBays.map((bay) => {
        for (let i = 0; i < bay.numberOfBays.value; i++) {
          width = width + parseFloat(bay.width.value)
          if (this.type === "rightEndwall")
            bays.push(this.buildingWidth - width)
          else if (this.type === "backSidewall")
            bays.push(this.buildingLength - width)
          else bays.push(width)
        }
      })
      if (this.type === "rightEndwall" || this.type === "backSidewall")
        bays.reverse()
      walkDoors.forEach((door) => {
        const offset =
          door.offset.value !== "" ? parseFloat(door.offset.value) : 0
        const x = parseFloat(door.size.value.substr(0, 1))
        const y = parseFloat(door.size.value.substr(2, 1))
        const index =
          this.type === "rightEndwall" || this.type === "backSidewall" ? 1 : 2
        const bay =
          door.bay.value === 1 &&
          this.type !== "rightEndwall" &&
          this.type !== "backSidewall"
            ? 0
            : bays[parseFloat(door.bay.value) - index]
        points = [
          { x: this.x + bay + offset, y: this.y },
          { x: this.x + bay + offset, y: this.y + y },
          { x: this.x + bay + offset + x, y: this.y + y },
          { x: this.x + bay + offset + x, y: this.y },
        ]
        vueCanvas.beginPath()
        vueCanvas.moveTo(this.x + bay + offset, this.y)
        points.forEach((point) => {
          vueCanvas.lineTo(point.x, point.y)
        })
        vueCanvas.strokeStyle = "red"
        vueCanvas.lineWidth = "0.15"
        vueCanvas.stroke()
      })
    },

    drawFramedOpenings() {
      const { vueCanvas, framedOpenings, surfaceBays } = this
      let points = []
      let bays = []
      let width = 0
      surfaceBays.map((bay) => {
        for (let i = 0; i < bay.numberOfBays.value; i++) {
          width = width + parseFloat(bay.width.value)
          if (this.type === "rightEndwall")
            bays.push(this.buildingWidth - width)
          else if (this.type === "backSidewall")
            bays.push(this.buildingLength - width)
          else bays.push(width)
        }
      })
      if (this.type === "rightEndwall" || this.type === "backSidewall")
        bays.reverse()
      framedOpenings.forEach((framedOpening) => {
        const offset =
          framedOpening.offset.value !== ""
            ? parseFloat(framedOpening.offset.value)
            : 0
        let x = 0
        let y = 0
        const index =
          this.type === "rightEndwall" || this.type === "backSidewall" ? 1 : 2
        const bay =
          framedOpening.bay.value === 1 &&
          this.type !== "rightEndwall" &&
          this.type !== "backSidewall"
            ? 0
            : bays[parseFloat(framedOpening.bay.value) - index]
        if (framedOpening.openingType.value.includes("Door")) {
          x = parseFloat(framedOpening.width.value)
          y = parseFloat(framedOpening.headerHeight.value)
          points = [
            { x: this.x + bay + offset, y: this.y },
            { x: this.x + bay + offset, y: this.y + y },
            { x: this.x + bay + offset + x, y: this.y + y },
            { x: this.x + bay + offset + x, y: this.y },
          ]
          vueCanvas.beginPath()
          vueCanvas.moveTo(this.x + bay + offset, this.y)
        } else {
          x = parseFloat(framedOpening.width.value)
          const headerHeight = parseFloat(framedOpening.headerHeight.value)
          const sillHeight = parseFloat(framedOpening.sillHeight.value)
          points = [
            { x: this.x + bay + offset + x, y: this.y + sillHeight },
            { x: this.x + bay + offset + x, y: this.y + headerHeight },
            { x: this.x + bay + offset, y: this.y + headerHeight },
            { x: this.x + bay + offset, y: this.y + sillHeight },
          ]
          vueCanvas.beginPath()

          vueCanvas.moveTo(this.x + bay + offset, this.y + sillHeight)
        }
        points.forEach((point) => {
          vueCanvas.lineTo(point.x, point.y)
        })
        vueCanvas.strokeStyle = "red"
        vueCanvas.lineWidth = "0.15"
        vueCanvas.stroke()
      })
    },

    drawPartialWalls() {
      const { vueCanvas, partialWalls, surfaceBays } = this
      let points = []
      let bays = []
      let width = 0
      surfaceBays.map((bay) => {
        for (let i = 0; i < bay.numberOfBays.value; i++) {
          width = width + parseFloat(bay.width.value)
          if (this.type === "rightEndwall")
            bays.push(this.buildingWidth - width)
          else if (this.type === "backSidewall")
            bays.push(this.buildingLength - width)
          else bays.push(width)
        }
      })
      partialWalls.forEach((partialWall) => {
        let startIndex = 2
        let endIndex = 1
        if (this.type === "rightEndwall" || this.type === "backSidewall") {
          bays.reverse()
          startIndex = 1
          endIndex = 0
        }
        const start =
          partialWall.start.value === 1 || partialWall.fullWidth.value === true
            ? 0
            : bays[parseFloat(partialWall.start.value) - startIndex]
        const end =
          partialWall.end.value - 1 === bays.indexOf(bays[bays.length - 1]) &&
          (this.type === "rightEndwall" || this.type === "backSidewall")
            ? this.type === "backSidewall"
              ? this.buildingLength
              : this.buildingWidth
            : bays[parseFloat(partialWall.end.value) - endIndex]
        points = [
          {
            x: this.x + end,
            y: this.y + parseFloat(partialWall.height.value),
          },
        ]
        vueCanvas.beginPath()
        vueCanvas.moveTo(
          this.x + start,
          this.y + parseFloat(partialWall.height.value)
        )
        points.forEach((point) => {
          vueCanvas.lineTo(point.x, point.y)
        })
        vueCanvas.strokeStyle = "red"
        vueCanvas.lineWidth = "0.15"
        vueCanvas.setLineDash([1])
        vueCanvas.lineDashOffset = 0
        vueCanvas.stroke()
      })
    },

    drawWainscots() {
      const { vueCanvas, wainscots, surfaceBays } = this
      let points = []
      let bays = []
      let width = 0
      surfaceBays.map((bay) => {
        for (let i = 0; i < bay.numberOfBays.value; i++) {
          width = width + parseFloat(bay.width.value)
          if (this.type === "rightEndwall")
            bays.push(this.buildingWidth - width)
          else if (this.type === "backSidewall")
            bays.push(this.buildingLength - width)
          else bays.push(width)
        }
      })
      wainscots.forEach((wainscot) => {
        let startIndex = 2
        let endIndex = 1
        if (this.type === "rightEndwall" || this.type === "backSidewall") {
          bays.reverse()
          startIndex = 1
          endIndex = 0
        }
        const start =
          wainscot.start.value === 1 || wainscot.fullWidth.value === true
            ? 0
            : bays[parseFloat(wainscot.start.value) - startIndex]
        const end =
          wainscot.end.value - 1 === bays.indexOf(bays[bays.length - 1]) &&
          (this.type === "rightEndwall" || this.type === "backSidewall")
            ? this.type === "backSidewall"
              ? this.buildingLength
              : this.buildingWidth
            : bays[parseFloat(wainscot.end.value) - endIndex]
        points = [
          {
            x: this.x + end,
            y: this.y + parseFloat(wainscot.height.value),
          },
        ]
        vueCanvas.beginPath()
        vueCanvas.moveTo(
          this.x + start,
          this.y + parseFloat(wainscot.height.value)
        )
        points.forEach((point) => {
          vueCanvas.lineTo(point.x, point.y)
        })
        vueCanvas.strokeStyle = "red"
        vueCanvas.lineWidth = "0.15"
        vueCanvas.setLineDash([1])
        vueCanvas.lineDashOffset = 0
        vueCanvas.stroke()
      })
    },

    drawLiners() {
      const { vueCanvas, liners, surfaceBays, buildingWidth } = this
      let points = []
      let peak = this.buildingPeakOffset
      let minHeight = Math.min(
        this.leftEaveHeight,
        this.rightEaveHeight,
        this.leftEaveHeight +
          (this.buildingSlope / 12) * this.buildingPeakOffset
      )
      let peakHeight =
        this.leftEaveHeight +
        (this.buildingSlope / 12) * this.buildingPeakOffset
      const rightSlope =
        (peakHeight - this.rightEaveHeight) /
        (this.buildingWidth - this.buildingPeakOffset)
      let bays = []
      let width = 0

      if (this.type === "rightEndwall") {
        peak = buildingWidth - this.buildingPeakOffset
      }
      surfaceBays.map((bay) => {
        for (let i = 0; i < bay.numberOfBays.value; i++) {
          width = width + parseFloat(bay.width.value)
          if (this.type === "rightEndwall")
            bays.push(this.buildingWidth - width)
          else if (this.type === "backSidewall")
            bays.push(this.buildingLength - width)
          else bays.push(width)
        }
      })
      liners.forEach((liner) => {
        let startIndex = 2
        let endIndex = 1
        if (this.type === "rightEndwall" || this.type === "backSidewall") {
          bays.reverse()
          startIndex = 1
          endIndex = 0
        }
        const start =
          liner.start.value === 1 || liner.fullWidth.value === true
            ? 0
            : bays[parseFloat(liner.start.value) - startIndex]
        const end =
          liner.end.value - 1 === bays.indexOf(bays[bays.length - 1]) &&
          (this.type === "rightEndwall" || this.type === "backSidewall")
            ? this.type === "backSidewall"
              ? this.buildingLength
              : this.buildingWidth
            : bays[parseFloat(liner.end.value) - endIndex]
        const linerHeight = parseFloat(liner.linerHeight.value)
        if (liner.fullHeight.value === false || liner.fullHeight.value === "") {
          if (linerHeight > minHeight) {
            let p1,
              p2,
              p3,
              p4,
              currentPeakStartLeftSlope,
              currentPeakEndLeftSlope,
              currentPeakStartRightSlope,
              currentPeakEndRightSlope
            switch (this.type) {
              case "leftEndwall":
                currentPeakStartLeftSlope =
                  this.leftEaveHeight + (this.buildingSlope / 12) * start
                currentPeakEndLeftSlope =
                  this.leftEaveHeight + (this.buildingSlope / 12) * end
                currentPeakStartRightSlope =
                  peakHeight + (this.buildingPeakOffset - start) * rightSlope
                currentPeakEndRightSlope =
                  peakHeight + (this.buildingPeakOffset - end) * rightSlope
                p1 = { x: this.x + start, y: this.y } // line 1
                p2 = { x: this.x + start, y: this.y + linerHeight }
                p3 = { x: this.x, y: this.y + this.leftEaveHeight } // line 2
                p4 = {
                  x: this.x + this.buildingPeakOffset,
                  y:
                    this.y +
                    this.leftEaveHeight +
                    (this.buildingPeakOffset * this.buildingSlope) / 12,
                }
                if (!lineSegmentsIntercept(p1, p2, p3, p4)) {
                  if (start > peak || end > peak) {
                    if (linerHeight < currentPeakStartRightSlope) {
                      points.push({
                        x: this.x + start,
                        y: this.y + linerHeight,
                      })
                    } else {
                      points.push({
                        x: this.x + start,
                        y: this.y + currentPeakStartRightSlope,
                      })
                    }
                  }
                } else points.push(lineSegmentsIntercept(p1, p2, p3, p4))
                p1 = { x: this.x + start, y: this.y + linerHeight } // line 1
                p2 = { x: this.x + end, y: this.y + linerHeight }
                p3 = { x: this.x, y: this.y + this.leftEaveHeight } // line 2
                p4 = {
                  x: this.x + this.buildingPeakOffset,
                  y:
                    this.y +
                    this.leftEaveHeight +
                    (this.buildingPeakOffset * this.buildingSlope) / 12,
                }
                if (!lineSegmentsIntercept(p1, p2, p3, p4)) {
                  if (start <= peak) {
                    if (linerHeight < currentPeakStartLeftSlope) {
                      points.push({
                        x: this.x + start,
                        y: this.y + linerHeight,
                      })
                    } else {
                      points.push({
                        x: this.x + start,
                        y: this.y + currentPeakStartLeftSlope,
                      })
                    }
                  }
                  if (end <= peak) {
                    if (linerHeight < currentPeakEndLeftSlope) {
                      points.push({
                        x: this.x + end,
                        y: this.y + linerHeight,
                      })
                    } else {
                      points.push({
                        x: this.x + end,
                        y: this.y + currentPeakEndLeftSlope,
                      })
                    }
                  }
                } else points.push(lineSegmentsIntercept(p1, p2, p3, p4))
                p1 = { x: this.x + start, y: this.y + linerHeight } // line 1
                p2 = { x: this.x + end, y: this.y + linerHeight }
                p3 = {
                  x: this.x + this.buildingPeakOffset,
                  y:
                    this.y +
                    this.leftEaveHeight +
                    (this.buildingSlope / 12) * this.buildingPeakOffset,
                }
                p4 = {
                  x: this.x + this.buildingWidth,
                  y: this.y + this.rightEaveHeight,
                }
                if (!lineSegmentsIntercept(p1, p2, p3, p4)) {
                  if (start >= peak) {
                    if (linerHeight < currentPeakStartRightSlope) {
                      points.push({
                        x: this.x + start,
                        y: this.y + linerHeight,
                      })
                    } else {
                      points.push({
                        x: this.x + start,
                        y: this.y + currentPeakStartRightSlope,
                      })
                    }
                  }
                  if (end >= peak) {
                    if (linerHeight < currentPeakEndRightSlope) {
                      points.push({
                        x: this.x + end,
                        y: this.y + linerHeight,
                      })
                    } else {
                      points.push({
                        x: this.x + end,
                        y: this.y + currentPeakEndRightSlope,
                      })
                    }
                  }
                } else points.push(lineSegmentsIntercept(p1, p2, p3, p4))
                p1 = { x: this.x + end, y: this.y } // line 1
                p2 = { x: this.x + end, y: this.y + linerHeight }
                p3 = {
                  x: this.x + this.buildingPeakOffset,
                  y:
                    this.y +
                    this.leftEaveHeight +
                    (this.buildingSlope / 12) * this.buildingPeakOffset,
                }
                p4 = {
                  x: this.x + this.buildingWidth,
                  y: this.y + this.rightEaveHeight,
                }
                if (!lineSegmentsIntercept(p1, p2, p3, p4)) {
                  if (
                    start < peak &&
                    end < peak &&
                    linerHeight < currentPeakStartLeftSlope
                  ) {
                    points.push({ x: this.x + start, y: this.y + linerHeight })
                  }
                  if (end < peak) {
                    if (linerHeight < currentPeakEndLeftSlope) {
                      points.push({ x: this.x + end, y: this.y + linerHeight })
                    } else {
                      points.push({
                        x: this.x + end,
                        y: this.y + currentPeakEndLeftSlope,
                      })
                    }
                  }
                } else points.push(lineSegmentsIntercept(p1, p2, p3, p4))
                points.push(
                  { x: this.x + end, y: this.y },
                  { x: this.x + start, y: this.y }
                )
                break
              case "rightEndwall":
                currentPeakStartLeftSlope =
                  this.rightEaveHeight + rightSlope * start
                currentPeakEndLeftSlope =
                  this.rightEaveHeight + rightSlope * end
                currentPeakStartRightSlope =
                  this.leftEaveHeight +
                  (this.buildingSlope / 12) *
                    (this.buildingWidth - this.buildingPeakOffset) +
                  (this.buildingPeakOffset - start) * (this.buildingSlope / 12)
                currentPeakEndRightSlope =
                  this.leftEaveHeight +
                  (this.buildingSlope / 12) *
                    (this.buildingWidth - this.buildingPeakOffset) +
                  (this.buildingPeakOffset - end) * (this.buildingSlope / 12)
                p1 = { x: this.x + start, y: this.y } // line 1
                p2 = { x: this.x + start, y: this.y + linerHeight }
                p3 = { x: this.x, y: this.y + this.rightEaveHeight } // line 2
                p4 = {
                  x: this.x + this.buildingWidth - this.buildingPeakOffset,
                  y:
                    this.y +
                    this.leftEaveHeight +
                    (this.buildingPeakOffset * this.buildingSlope) / 12,
                }
                if (!lineSegmentsIntercept(p1, p2, p3, p4)) {
                  if (start > peak || end > peak) {
                    if (linerHeight < currentPeakStartRightSlope) {
                      points.push({
                        x: this.x + start,
                        y: this.y + linerHeight,
                      })
                    } else {
                      points.push({
                        x: this.x + start,
                        y: this.y + currentPeakStartRightSlope,
                      })
                    }
                  }
                } else points.push(lineSegmentsIntercept(p1, p2, p3, p4))
                p1 = { x: this.x + start, y: this.y + linerHeight } // line 1
                p2 = { x: this.x + end, y: this.y + linerHeight }
                p3 = { x: this.x, y: this.y + this.rightEaveHeight } // line 2
                p4 = {
                  x: this.x + this.buildingWidth - this.buildingPeakOffset,
                  y:
                    this.y +
                    this.leftEaveHeight +
                    (this.buildingPeakOffset * this.buildingSlope) / 12.0,
                }
                if (!lineSegmentsIntercept(p1, p2, p3, p4)) {
                  if (start <= peak) {
                    if (linerHeight < currentPeakStartLeftSlope) {
                      points.push({
                        x: this.x + start,
                        y: this.y + linerHeight,
                      })
                    } else {
                      points.push({
                        x: this.x + start,
                        y: this.y + currentPeakStartLeftSlope,
                      })
                    }
                  }
                  if (end <= peak) {
                    if (linerHeight < currentPeakEndLeftSlope) {
                      points.push({ x: this.x + end, y: this.y + linerHeight })
                    } else {
                      points.push({
                        x: this.x + end,
                        y: this.y + currentPeakEndLeftSlope,
                      })
                    }
                  }
                } else points.push(lineSegmentsIntercept(p1, p2, p3, p4))
                p1 = { x: this.x + start, y: this.y + linerHeight } // line 1
                p2 = { x: this.x + end, y: this.y + linerHeight }
                p3 = {
                  x: this.x + this.buildingWidth - this.buildingPeakOffset,
                  y:
                    this.y +
                    this.leftEaveHeight +
                    (this.buildingPeakOffset * this.buildingSlope) / 12,
                }
                p4 = {
                  x: this.x + this.buildingWidth,
                  y: this.y + this.leftEaveHeight,
                }
                if (!lineSegmentsIntercept(p1, p2, p3, p4)) {
                  if (start >= peak) {
                    if (linerHeight < currentPeakStartRightSlope) {
                      points.push({
                        x: this.x + start,
                        y: this.y + linerHeight,
                      })
                    } else {
                      points.push({
                        x: this.x + start,
                        y: this.y + currentPeakStartRightSlope,
                      })
                    }
                  }
                  if (end >= peak) {
                    if (linerHeight < currentPeakEndRightSlope) {
                      points.push({
                        x: this.x + end,
                        y: this.y + linerHeight,
                      })
                    } else {
                      points.push({
                        x: this.x + end,
                        y: this.y + currentPeakEndRightSlope,
                      })
                    }
                  }
                } else points.push(lineSegmentsIntercept(p1, p2, p3, p4))
                p1 = { x: this.x + end, y: this.y } // line 1
                p2 = { x: this.x + end, y: this.y + linerHeight }
                p3 = {
                  x: this.x + this.buildingWidth - this.buildingPeakOffset,
                  y:
                    this.y +
                    this.leftEaveHeight +
                    (this.buildingPeakOffset * this.buildingSlope) / 12,
                }
                p4 = {
                  x: this.x + this.buildingWidth,
                  y: this.y + this.leftEaveHeight,
                }
                if (!lineSegmentsIntercept(p1, p2, p3, p4)) {
                  if (
                    start < peak &&
                    end < peak &&
                    linerHeight < currentPeakStartLeftSlope
                  ) {
                    points.push({ x: this.x + start, y: this.y + linerHeight })
                  }
                  if (end < peak) {
                    if (linerHeight < currentPeakEndLeftSlope) {
                      points.push({ x: this.x + end, y: this.y + linerHeight })
                    } else {
                      points.push({
                        x: this.x + end,
                        y: this.y + currentPeakEndLeftSlope,
                      })
                    }
                  }
                } else points.push(lineSegmentsIntercept(p1, p2, p3, p4))
                points.push(
                  { x: this.x + end, y: this.y },
                  { x: this.x + start, y: this.y }
                )
                break
              case "frontSidewall":
                points = [
                  {
                    x: this.x + start,
                    y: this.y + this.rightEaveHeight,
                  },
                  {
                    x: this.x + end,
                    y: this.y + this.rightEaveHeight,
                  },
                ]
                break
              case "backSidewall":
                points = [
                  {
                    x: this.x + start,
                    y: this.y + this.leftEaveHeight,
                  },
                  {
                    x: this.x + end,
                    y: this.y + this.leftEaveHeight,
                  },
                ]
                break
              case "roof":
                points = [
                  {
                    x: this.x + start,
                    y: this.y + this.buildingLength,
                  },
                  {
                    x: this.x + end,
                    y: this.y + this.buildingLength,
                  },
                ]
                break
            }
          } else {
            points = [
              {
                x: this.x + start,
                y: this.y,
              },
              {
                x: this.x + start,
                y: this.y + linerHeight,
              },
              {
                x: this.x + end,
                y: this.y + linerHeight,
              },
              {
                x: this.x + end,
                y: this.y,
              },
              {
                x: this.x + start,
                y: this.y,
              },
            ]
          }
        } else {
          switch (this.type) {
            case "leftEndwall":
              if (start < peak) {
                points.push({
                  x: this.x + start,
                  y:
                    this.y +
                    this.leftEaveHeight +
                    (this.buildingSlope / 12) * start,
                })
              }
              if (start <= peak && end >= peak) {
                points.push({
                  x: this.x + peak,
                  y:
                    this.y +
                    this.leftEaveHeight +
                    (this.buildingPeakOffset * this.buildingSlope) / 12.0,
                })
              }
              if (end < peak) {
                points.push({
                  x: this.x + end,
                  y:
                    this.y +
                    this.leftEaveHeight +
                    (this.buildingSlope / 12) * end,
                })
              }
              if (start > peak) {
                points.push({
                  x: this.x + start,
                  y:
                    this.y +
                    peakHeight +
                    (this.buildingPeakOffset - start) * rightSlope,
                })
              }
              if (end > peak) {
                points.push({
                  x: this.x + end,
                  y:
                    this.y +
                    peakHeight +
                    (this.buildingPeakOffset - end) * rightSlope,
                })
              }
              break
            case "rightEndwall":
              if (start < peak) {
                points.push({
                  x: this.x + start,
                  y: this.y + this.rightEaveHeight + rightSlope * start,
                })
              }
              if (start <= peak && end >= peak) {
                points.push({
                  x: this.x + peak,
                  y:
                    this.y +
                    this.leftEaveHeight +
                    (this.buildingPeakOffset * this.buildingSlope) / 12.0,
                })
              }
              if (end < peak) {
                points.push({
                  x: this.x + end,
                  y: this.y + this.rightEaveHeight + rightSlope * end,
                })
              }
              if (start > peak) {
                points.push({
                  x: this.x + start,
                  y:
                    this.y +
                    (this.leftEaveHeight +
                      (this.buildingSlope / 12) *
                        (this.buildingWidth - this.buildingPeakOffset)) +
                    (this.buildingPeakOffset - start) *
                      (this.buildingSlope / 12),
                })
              }
              if (end > this.buildingPeakOffset) {
                points.push({
                  x: this.x + end,
                  y:
                    this.y +
                    (this.leftEaveHeight +
                      (this.buildingSlope / 12) *
                        (this.buildingWidth - this.buildingPeakOffset)) +
                    (this.buildingPeakOffset - end) * (this.buildingSlope / 12),
                })
              }
              break
            case "frontSidewall":
              points = [
                {
                  x: this.x + start,
                  y: this.y + this.rightEaveHeight,
                },
                {
                  x: this.x + end,
                  y: this.y + this.rightEaveHeight,
                },
              ]
              break
            case "backSidewall":
              points = [
                {
                  x: this.x + start,
                  y: this.y + this.leftEaveHeight,
                },
                {
                  x: this.x + end,
                  y: this.y + this.leftEaveHeight,
                },
              ]
              break
            case "roof":
              points = [
                {
                  x: this.x + start,
                  y: this.y + this.buildingLength,
                },
                {
                  x: this.x + end,
                  y: this.y + this.buildingLength,
                },
              ]
              break
          }
          points.push(
            {
              x: this.x + end,
              y: this.y,
            },
            {
              x: this.x + start,
              y: this.y,
            }
          )
        }
        vueCanvas.beginPath()
        vueCanvas.moveTo(this.x + start, this.y)
        points.forEach((point) => {
          vueCanvas.lineTo(point.x, point.y)
        })
        vueCanvas.closePath()
        vueCanvas.strokeStyle = "blue"
        vueCanvas.lineWidth = "0.3"
        vueCanvas.setLineDash([0.5])
        vueCanvas.lineDashOffset = 0
        vueCanvas.stroke()
      })
    },

    drawInteriorColumns() {
      const { vueCanvas, buildingInteriorColumns } = this
      const points = []
      const peakHeight =
        this.leftEaveHeight +
        (this.buildingSlope / 12) * this.buildingPeakOffset
      const rightSlope =
        (peakHeight - this.rightEaveHeight) / this.buildingPeakOffset
      let split = buildingInteriorColumns.split(" ")
      let splitNum = split.map((s) => {
        return parseFloat(s)
      })
      splitNum.forEach((s1) => {
        const left = {
          x: this.x + s1,
          y: this.y + this.leftEaveHeight + (this.buildingSlope / 12) * s1,
        }
        const right = {
          x: this.x + s1,
          y: this.y + peakHeight + (this.buildingPeakOffset - s1) * rightSlope,
        }
        if (this.buildingPeakOffset >= s1) points.push(left)
        else points.push(right)
      })
      vueCanvas.beginPath()
      points.forEach((point) => {
        vueCanvas.moveTo(point.x, this.y)
        vueCanvas.lineTo(point.x, point.y)
      })
      vueCanvas.strokeStyle = "red"
      vueCanvas.lineWidth = "0.15"
      vueCanvas.stroke()
    },

    drawDimensions() {
      let xText = ""
      let leftText = ""
      let rightText = ""
      switch (this.type) {
        case "leftEndwall":
          xText = `${this.buildingWidth.toFixed(2)} ft`
          leftText = `${this.leftEaveHeight.toFixed(2)} ft`
          rightText = `${this.rightEaveHeight.toFixed(2)} ft`
          //Bottom
          this.vueCanvas.save()
          this.vueCanvas.translate(
            this.x + this.buildingWidth / 2,
            this.y * 1.08
          )
          this.vueCanvas.rotate(Math.PI / 180)
          this.vueCanvas.scale(this.scaleFactorX, this.scaleFactorY)
          this.vueCanvas.font = `${2 / this.scaleFactorX}px roboto`
          this.vueCanvas.textAlign = "center"
          this.vueCanvas.fillText(xText, 0, 0)
          this.vueCanvas.restore()
          //LeftEave
          this.vueCanvas.save()
          this.vueCanvas.translate(
            this.x * 0.5,
            this.y + this.leftEaveHeight / 2
          )
          this.vueCanvas.rotate(-Math.PI / 2)
          this.vueCanvas.scale(this.scaleFactorX, this.scaleFactorY)
          this.vueCanvas.font = `${2 / this.scaleFactorX}px roboto`
          this.vueCanvas.textAlign = "center"
          this.vueCanvas.fillText(leftText, 0, 0)
          this.vueCanvas.restore()
          //rightEave
          this.vueCanvas.save()
          this.vueCanvas.translate(
            this.x + this.buildingWidth * 1.05,
            this.y + this.rightEaveHeight / 2
          )
          this.vueCanvas.rotate(Math.PI / 2)
          this.vueCanvas.scale(this.scaleFactorX, this.scaleFactorY)
          this.vueCanvas.font = `${2 / this.scaleFactorX}px roboto`
          this.vueCanvas.textAlign = "center"
          this.vueCanvas.fillText(rightText, 0, 0)
          this.vueCanvas.restore()
          break
      }
    },
  },

  components: {},
}
</script>
<style scoped>
#stageleftEndwall,
#stagerightEndwall,
#stagefrontSidewall,
#stagebackSidewall,
#stageroof {
  width: 100%;
  height: 400px;
  position: relative;
  border: 1px solid gray;
}
canvas {
  position: absolute;
}
</style>
