<template>
  <v-container>
    <v-row>
      <h3>Building Geometry</h3>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler
          :path="`${path}.shape`"
          :addRules="[(v) => !!geometryCheck(v)]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler
          :path="`${path}.width`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler
          :path="`${path}.length`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler
          :path="`${path}.heightLeft`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler
          :path="`${path}.heightRight`"
          :disabled="
            shape.shape.value === 'symmetricalDoubleSlope' ||
            shape.shape.value === 'singleSlope'
              ? true
              : false
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler
          :path="`${path}.slope`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler
          :path="`${path}.slopeRight`"
          :disabled="
            shape.shape.value === 'symmetricalDoubleSlope' ||
            shape.shape.value === 'singleSlope'
              ? true
              : false
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler
          :path="`${path}.peakOffset`"
          :disabled="
            shape.shape.value === 'symmetricalDoubleSlope' ||
            shape.shape.value === 'singleSlope'
              ? true
              : false
          "
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex"

export default {
  name: "Shape",

  props: {
    buildingIndex: String,
  },

  computed: {
    ...mapState({ buildings: (state) => state.data.buildings }),
    path() {
      return `buildings[${this.buildingIndex}].shape`
    },
    shape() {
      return this.buildings[this.buildingIndex].shape
    },
  },

  watch: {
    shape: {
      deep: true,
      handler() {
        this.geometryCheck(this.shape.shape.value)
      },
    },
  },

  components: {
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },

  methods: {
    ...mapActions({
      geometryValidation: "data/geometryValidation",
    }),
    async geometryCheck(value) {
      return await this.geometryValidation({
        buildingIndex: this.buildingIndex,
        value,
      })
    },
  },
}
</script>
