import { get } from "lodash"

export default {
  getBuilding: function (state) {
    return state.building
  },
  generatePathToField: (state) => (path) => {
    const quote = state
    const obj = get(quote, path)

    return obj
  },
}
