<template>
  <v-container class="px-0">
    <v-card class="mx-auto pt-10">
      <v-row class="mx-10">
        <h2>Upload Drawings</h2>
      </v-row>
      <v-row class="mx-10">
        <v-file-input
          v-model="file"
          show-size
          :rules="[
            (v) =>
              (!!v && v.size <= 20000000) ||
              !v ||
              'File size must be smaller than 20MB',
            (v) =>
              (!!v && v.type === 'application/pdf') ||
              !v ||
              'File must be a PDF.',
          ]"
        ></v-file-input>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex"

export default {
  name: "Attachments",

  components: {},

  computed: {
    ...mapState({
      attachment: (state) => state.form.attachment,
    }),
    file: {
      get() {
        if (Object.keys(this.attachment).length !== 0) return this.attachment
        else return null
      },
      set(newValue) {
        this.uploadToState(newValue)
      },
    },
  },

  methods: {
    ...mapActions({
      uploadAttachment: "form/setObject",
    }),
    uploadToState(file) {
      if (file !== null && file.name.includes(".pdf")) {
        return this.uploadAttachment({ path: "attachment", value: file })
      }
    },
  },

  data: () => ({
    uploadDialog: true,
  }),
}
</script>
