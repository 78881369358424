import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Vuex from "vuex"
import vuetify from "./plugins/vuetify"
import { auth } from "./plugins/firebase"
import { onAuthStateChanged } from "firebase/auth"

Vue.config.productionTip = false
Vue.use(Vuex)

let app = ""
// eslint-disable-next-line no-unused-vars
onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app")
  }
})
