import state from "./state"
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

const data = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default data
