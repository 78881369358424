<template>
  <v-container class="pb-6">
    <v-row>
      <h3>Column Base Elevation</h3>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler :path="`${path}.leftEndwall`" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler :path="`${path}.rightEndwall`" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler :path="`${path}.frontSidewall`" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <input-handler :path="`${path}.backSidewall`" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ColumnBaseElevation",

  props: {
    buildingIndex: String,
  },

  computed: {
    path() {
      return `buildings[${this.buildingIndex}].columnBaseElevation`
    },
  },

  components: {
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },
}
</script>
