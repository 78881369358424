import { signInWithEmailAndPassword, signOut } from "firebase/auth"
import { auth } from "../../../plugins/firebase"
import router from "../../../router/index"

export default {
  login: function ({ commit }, { email, password }) {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        commit("AUTHORIZED", user)
        router.push("/")
      })
      .catch((error) => {
        console.log(error.message)
      })
  },
  signOut: function ({ commit }) {
    signOut(auth)
      .then(() => {
        commit("UNAUTHORIZED")
        router.push("/login")
      })
      .catch((error) => {
        console.log(error.message)
      })
  },
}
