<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="6"
        md="3"
        v-for="(template, index) in templates"
        :key="index"
      >
        <v-card class="mx-auto" max-width="300">
          <v-card-text id="cardText"
            ><v-chip color="primary" label no-ripple outlined x-small>{{
              index + 1
            }}</v-chip>
            <v-chip
              v-if="template.isEZ"
              color="primary"
              style="float: right"
              label
              no-ripple
              x-small
              >EZ Btn</v-chip
            >
            <br />
            {{ template.project.primaryInfo.projectInfo.projectName.value }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="uploadToState(template)">
              Import <v-icon>mdi-arrow-right-thin</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex"

export default {
  name: "Upload",

  computed: {
    ...mapState({
      templates: (state) => state.form.templates,
    }),
  },

  components: {},

  methods: {
    ...mapActions({
      setStateFromTemplate: "data/setStateFromTemplate",
    }),
    uploadToState(template) {
      return this.setStateFromTemplate(template)
    },
  },

  data: () => ({
    file: [],
    uploadDialog: true,
  }),
}
</script>

<style lang="scss" scoped>
#cardText {
  height: 7em;
}
</style>
