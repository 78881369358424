const building = {
  shape: {
    width: {
      type: "SPECIAL_CASE",
      displayName: "Width",
      suffix: "ft",
      value: "",
    },
    length: {
      type: "SPECIAL_CASE",
      displayName: "Length",
      suffix: "ft",
      value: "",
    },
    shape: {
      type: "STANDARD_DROPDOWN",
      displayName: "Shape",
      value: "symmetricalDoubleSlope",
      required: true,
      options: [
        { display: "Single Slope", value: "singleSlope" },
        {
          display: "Symmetrical Double Slope",
          value: "symmetricalDoubleSlope",
        },
        {
          display: "Asymmetrical Double Slope",
          value: "asymmetricalDoubleSlope",
        },
      ],
    },
    heightLeft: {
      type: "LENGTH",
      displayName: "Left Eave Height",
      suffix: "ft",
      value: "",
    },
    heightRight: {
      type: "LENGTH",
      displayName: "Right Eave Height",
      suffix: "ft",
      value: "",
    },
    slope: {
      type: "FLOAT",
      displayName: "Slope",
      value: "2",
    },
    slopeRight: {
      type: "FLOAT",
      displayName: "Right Slope",
      value: "",
    },
    peakOffset: {
      type: "LENGTH",
      displayName: "Peak Offset",
      suffix: "ft",
      value: "",
    },
  },
  baseCondition: {
    slabOrPiers: {
      type: "RADIO_BUTTON",
      displayName: "Slab",
      value: "slab",
      options: [
        { display: "Slab", value: "slab" },
        { display: "Piers", value: "piers" },
      ],
    },
    baseTrim: {
      type: "STANDARD_DROPDOWN",
      displayName: "Base Trim",
      options: [
        {
          display: "Base Trim",
          value: "baseTrim",
        },
        {
          display: "Return Base",
          value: "ratGuard",
        },
        {
          display: "Notch, No Trim",
          value: "notchNoTrim",
        },
        {
          display: "No Trim or Notch",
          value: "noTrimOrNotch",
        },
      ],
      value: "baseTrim",
    },
  },
  exteriorPanels: {
    roofPanel: {
      type: "STANDARD_DROPDOWN",
      displayName: "Panel",
      options: [
        {
          display: "Trapezoidal Standing Seam",
          value: "standingSeamTrapezoidal",
        },
        {
          display: "Vertical Standing Seam ",
          value: "standingSeamVertical",
        },
        {
          display: "PBR 26",
          value: "pbr26",
        },
        {
          display: "PBR 24",
          value: "pbr24",
        },
      ],
      value: "pbr26",
    },
    wallPanel: {
      type: "STANDARD_DROPDOWN",
      displayName: "Panel",
      options: [
        {
          display: "PBR 26",
          value: "pbr26",
        },
        {
          display: "PBR 24",
          value: "pbr24",
        },
      ],
      value: "pbr26",
    },
    roofFinish: {
      type: "STANDARD_DROPDOWN",
      displayName: "Finish",
      options: [
        {
          display: "Galv",
          value: "galv",
        },
        {
          display: "Color",
          value: "color",
        },
        {
          display: "PVDF",
          value: "PVDF",
        },
      ],
      value: "galv",
    },
    wallFinish: {
      type: "STANDARD_DROPDOWN",
      displayName: "Finish",
      options: [
        {
          display: "Galv",
          value: "galv",
        },
        {
          display: "Color",
          value: "color",
        },
        {
          display: "PVDF",
          value: "PVDF",
        },
      ],
      value: "color",
    },
  },
  columnBaseElevation: {
    leftEndwall: {
      type: "ELEVATION",
      displayName: "Left Endwall",
      suffix: "in",
      value: "",
    },
    rightEndwall: {
      type: "ELEVATION",
      displayName: "Right Endwall",
      suffix: "in",
      value: "",
    },
    frontSidewall: {
      type: "ELEVATION",
      displayName: "Front Sidewall",
      suffix: "in",
      value: "",
    },
    backSidewall: {
      type: "ELEVATION",
      displayName: "Back Sidewall",
      suffix: "in",
      value: "",
    },
  },
  loads: {
    collateralLoad: {
      type: "FLOAT",
      displayName: "Collateral Load",
      suffix: "psf",
      value: "1",
    },
    thermalFactor: {
      type: "STANDARD_DROPDOWN",
      displayName: "Thermal Factor",
      value: "",
      options: [
        {
          display: "Heated",
          value: "heated",
        },
        {
          display: "Unheated with Insulation",
          value: "unheatedWithInsulation",
        },
        {
          display: "Unheated without Insulation",
          value: "unheatedWithoutInsulation",
        },
        {
          display: "Greenhouse",
          value: "greenhouse",
        },
      ],
    },
    buildingUse: {
      type: "STANDARD_DROPDOWN",
      displayName: "Building Use",
      value: "",
      options: [
        {
          display: "Residential",
          value: "residential",
        },
        {
          display: "Office",
          value: "office",
        },
        {
          display: "Storage",
          value: "storage",
        },
        {
          display: "Ag",
          value: "ag",
        },
        {
          display: "Shop",
          value: "shop",
        },
        {
          display: "Commercial",
          value: "commercial",
        },
        {
          display: "Warehouse",
          value: "warehouse",
        },
        {
          display: "Other - Specified in Notes",
          value: "other",
        },
      ],
    },
    buildingOccupancy: {
      type: "STANDARD_DROPDOWN",
      displayName: "Building Occupancy",
      value: "",
      options: [
        {
          display: "I - Low, Storage, Warehouse, or Agri",
          value: "i",
        },
        {
          display: "II - Normal, Standard Building",
          value: "ii",
        },
        {
          display: "III - High Schools, Large Occupancy",
          value: "iii",
        },
        {
          display: "IV - Post Essential (Fire Dept., Hospital)",
          value: "iv",
        },
      ],
    },
    slipperyRoof: {
      type: "BOOLEAN",
      displayName: "Slippery Roof",
      value: "true",
    },
  },
  surfaceSpacing: {
    numberOfBays: {
      type: "number",
      displayName: "# Bays",
      value: "",
    },
    width: {
      type: "SPECIAL_CASE",
      displayName: "Width",
      suffix: "ft",
      value: "",
    },
    openHeight: {
      type: "LENGTH",
      displayName: "Open Height",
      suffix: "ft",
      value: "",
    },
    openCondition: {
      type: "TEXT",
      displayName: "Open Condition",
      value: "",
    },
  },
  framedOpening: {
    openingType: {
      type: "STANDARD_DROPDOWN",
      displayName: "Opening Type",
      value: "",
      options: [
        { display: "Walk Door Opening", value: "walkDoorOpening" },
        { display: "Self Flashing Window", value: "selfFlashingWindow" },
        { display: "Standard Window", value: "standardWindow" },
        { display: "Overhead Door", value: "overheadDoor" },
        { display: "IS Roll-Up Door", value: "isRollUpDoor" },
        { display: "OS Roll-Up Door", value: "osRollUpDoor" },
        { display: "Louver", value: "louver" },
        { display: "Overhead Door Blocking", value: "overheadDoorBlocking" },
      ],
    },
    framingType: {
      type: "STANDARD_DROPDOWN",
      displayName: "Framing Type",
      value: "",
      options: [
        { display: "No Framing", value: "noFraming" },
        { display: "Jamb, Base to Girt", value: "jambBaseToGirt" },
        { display: "Jamb, Base to Roof", value: "jambBaseToRoof" },
        { display: "Jamb, Girt to Girt", value: "jambGirtToGirt" },
        { display: "Open Bay", value: "openBay" },
        {
          display: "Jamb, Base to Added Girt",
          value: "jambBaseToAddedGirt",
        },
        { display: "Strip Window", value: "stripWindow" },
        {
          display: "Jamb, Girt to Girt (No Girt Cut)",
          value: "jambGirtToGirtNoGirtCut",
        },
      ],
    },
    panelOption: {
      type: "STANDARD_DROPDOWN",
      displayName: "Panel Option",
      value: "",
      options: [
        { display: "Sheet Over Opening", value: "sheetOverOpening" },
        { display: "Remove Panel", value: "removePanel" },
        { display: "Field Located", value: "fieldLocated" },
        { display: "Girts in Opening", value: "girtsInOpening" },
      ],
    },
    bay: {
      type: "STANDARD_DROPDOWN",
      displayName: "Bay",
      value: "",
      options: [],
    },
    offset: {
      type: "LENGTH",
      displayName: "Offset",
      suffix: "ft",
      value: "",
    },
    width: {
      type: "LENGTH",
      displayName: "Width",
      suffix: "ft",
      value: "",
    },
    headerHeight: {
      type: "LENGTH",
      displayName: "Header Height",
      suffix: "ft",
      value: "",
    },
    sillHeight: {
      type: "LENGTH",
      displayName: "Sill Height",
      suffix: "ft",
      value: "",
    },
    baseElevation: {
      type: "ELEVATION",
      displayName: "Base Elevation",
      suffix: "ft",
      value: "",
    },
    centered: {
      type: "BOOLEAN",
      displayName: "Centered",
      value: "",
    },
  },
  liner: {
    fullWidth: {
      type: "BOOLEAN",
      displayName: "Full Width",
      value: "",
    },
    fullHeight: {
      type: "BOOLEAN",
      displayName: "Full Height",
      value: "",
    },
    start: {
      type: "STANDARD_DROPDOWN",
      displayName: "Start",
      value: "",
      options: [],
    },
    end: {
      type: "STANDARD_DROPDOWN",
      displayName: "End",
      value: "",
      options: [],
    },
    panel: {
      type: "STANDARD_DROPDOWN",
      displayName: "Panel",
      value: "agPanel",
      options: [
        {
          display: "PBR-Panel",
          value: "pbrPanel",
        },
        {
          display: "Rev-PBR Panel",
          value: "revPbrPanel",
        },
        {
          display: "U Panel",
          value: "uPanel",
        },
        {
          display: "AG Panel",
          value: "agPanel",
        },
      ],
    },
    finish: {
      type: "STANDARD_DROPDOWN",
      displayName: "Finish",
      value: "color",
      options: [
        {
          display: "Galv",
          value: "galv",
        },
        {
          display: "Color",
          value: "color",
        },
        {
          display: "PVDF",
          value: "PVDF",
        },
      ],
    },
    linerHeight: {
      type: "LENGTH",
      displayName: "Liner Height",
      suffix: "ft",
      value: "",
    },
  },
  walkDoor: {
    size: {
      type: "STANDARD_DROPDOWN",
      displayName: "Size",
      value: "",
      options: [
        {
          display: "3x7",
          value: "3x7",
        },
        {
          display: "4x7",
          value: "4x7",
        },
        {
          display: "6x7",
          value: "6x7",
        },
      ],
    },
    bay: {
      type: "STANDARD_DROPDOWN",
      displayName: "Bay",
      value: "",
      options: [],
    },
    offset: {
      type: "LENGTH",
      displayName: "Offset",
      suffix: "ft",
      value: "",
    },
    centered: {
      type: "BOOLEAN",
      displayName: "Centered",
      value: "",
    },
    accessories: {
      window: {
        type: "STANDARD_DROPDOWN",
        displayName: "Window",
        value: "none",
        options: [
          {
            display: "None",
            value: "none",
          },
          {
            display: "Uninsulated",
            value: "uninsulated",
          },
          {
            display: "Insulated",
            value: "insulated",
          },
        ],
      },
      options: {
        type: "STANDARD_DROPDOWN",
        displayName: "Accessories",
        value: [],
        options: [
          {
            display: "Dead Bolt",
            value: "deadBolt",
          },
          {
            display: "Closer",
            value: "closer",
          },
          {
            display: "Latch Guard",
            value: "latchGuard",
          },
          {
            display: "Kick Plate",
            value: "kickPlate",
          },
          {
            display: "Panic Hardware",
            value: "panicHardware",
          },
        ],
      },
    },
  },
  partialWall: {
    fullWidth: {
      type: "BOOLEAN",
      displayName: "Full Width",
      value: "",
    },
    fullHeight: {
      type: "BOOLEAN",
      displayName: "Full Height",
      value: "",
    },
    start: {
      type: "STANDARD_DROPDOWN",
      displayName: "Start",
      value: "",
      options: [],
    },
    end: {
      type: "STANDARD_DROPDOWN",
      displayName: "End",
      value: "",
      options: [],
    },
    height: {
      type: "LENGTH",
      displayName: "Height",
      suffix: "ft",
      value: "",
    },
    open: {
      type: "STANDARD_DROPDOWN",
      displayName: "Open",
      value: "openToElements",
      options: [
        {
          display: "Open for Materials by Others",
          value: "openForOthers",
        },
        {
          display: "Open to the Elements",
          value: "openToElements",
        },
      ],
    },
  },
  extension: {
    fullWidth: {
      type: "BOOLEAN",
      displayName: "Full Width",
      value: "",
    },
    start: {
      type: "STANDARD_DROPDOWN",
      displayName: "Start",
      value: "",
      options: [],
    },
    end: {
      type: "STANDARD_DROPDOWN",
      displayName: "End",
      value: "",
      options: [],
    },
    width: {
      type: "LENGTH",
      displayName: "Width",
      suffix: "ft",
      value: "",
    },
  },
  lightPanel: {
    fullWidth: {
      type: "BOOLEAN",
      displayName: "Full Width",
      value: "",
    },
    quantity: {
      type: "NUMBER",
      displayName: "Quantity",
      value: "",
    },
  },
  wainscot: {
    fullWidth: {
      type: "BOOLEAN",
      displayName: "Full Width",
      value: "",
    },
    fullHeight: {
      type: "BOOLEAN",
      displayName: "Full Height",
      value: "",
    },
    start: {
      type: "STANDARD_DROPDOWN",
      displayName: "Start",
      value: "",
      options: [],
    },
    end: {
      type: "STANDARD_DROPDOWN",
      displayName: "End",
      value: "",
      options: [],
    },
    panel: {
      type: "STANDARD_DROPDOWN",
      displayName: "Panel",
      value: "pbrPanel",
      options: [
        {
          display: "PBR-Panel",
          value: "pbrPanel",
        },
        {
          display: "Rev-PBR Panel",
          value: "revPbrPanel",
        },
        {
          display: "U Panel",
          value: "uPanel",
        },
        {
          display: "AG Panel",
          value: "agPanel",
        },
      ],
    },
    finish: {
      type: "STANDARD_DROPDOWN",
      displayName: "Finish",
      value: "color",
      options: [
        {
          display: "Galv",
          value: "galv",
        },
        {
          display: "Color",
          value: "color",
        },
        {
          display: "PVDF",
          value: "PVDF",
        },
      ],
    },
    height: {
      type: "LENGTH",
      displayName: "Height",
      suffix: "ft",
      value: "",
    },
  },
  rigidFrame: {
    column: {
      type: "STANDARD_DROPDOWN",
      displayName: "Column",
      value: "",
      options: [
        {
          display: "Tapered",
          value: "tapered",
        },
        {
          display: "Constant",
          value: "constant",
        },
      ],
    },
    rafter: {
      type: "STANDARD_DROPDOWN",
      displayName: "Rafter",
      value: "",
      options: [
        {
          display: "Tapered",
          value: "tapered",
        },
        {
          display: "Constant",
          value: "constant",
        },
      ],
    },
    locations: {
      type: "NUMBER",
      displayName: "Locations",
      value: "",
    },
    interiorColumn: {
      elevation: {
        type: "ELEVATION",
        displayName: "Elevation",
        suffix: "in",
        value: "-8",
      },
      locations: {
        type: "TEXT",
        displayName: "Locations",
        value: "",
      },
    },
  },
  roof: {
    surfaceSpacings: [
      {
        numberOfBays: {
          displayName: "# Bays",
          type: "number",
          value: "",
        },
        openCondition: {
          displayName: "Open Condition",
          type: "TEXT",
          value: "",
        },
        openHeight: {
          displayName: "Open Height",
          suffix: "ft",
          type: "LENGTH",
          value: "",
        },
        width: {
          displayName: "Width",
          suffix: "ft",
          type: "LENGTH",
          value: "",
        },
      },
    ],
    framedOpenings: [],
    liners: [],
    lightPanels: [],
  },
  leftEndwall: {
    surfaceSpacings: [
      {
        numberOfBays: {
          displayName: "# Bays",
          type: "number",
          value: "",
        },
        openCondition: {
          displayName: "Open Condition",
          type: "TEXT",
          value: "",
        },
        openHeight: {
          displayName: "Open Height",
          suffix: "ft",
          type: "LENGTH",
          value: "",
        },
        width: {
          displayName: "Width",
          suffix: "ft",
          type: "LENGTH",
          value: "",
        },
      },
    ],
    framedOpenings: [],
    liners: [],
    extensions: [],
    lightPanels: [],
    wainscots: [],
    partialWalls: [],
    walkDoors: [],
    expandable: {
      type: "BOOLEAN",
      displayName: "Expandable",
      value: "",
    },
    mirroredBays: {
      type: "BOOLEAN",
      displayName: "Mirrored",
      value: "",
    },
    mirroredWalkDoors: {
      type: "BOOLEAN",
      displayName: "Mirrored",
      value: "",
    },
    mirroredFramedOpenings: {
      type: "BOOLEAN",
      displayName: "Mirrored",
      value: "",
    },
  },
  rightEndwall: {
    surfaceSpacings: [
      {
        numberOfBays: {
          displayName: "# Bays",
          type: "number",
          value: "",
        },
        openCondition: {
          displayName: "Open Condition",
          type: "TEXT",
          value: "",
        },
        openHeight: {
          displayName: "Open Height",
          suffix: "ft",
          type: "LENGTH",
          value: "",
        },
        width: {
          displayName: "Width",
          suffix: "ft",
          type: "LENGTH",
          value: "",
        },
      },
    ],
    framedOpenings: [],
    liners: [],
    extensions: [],
    lightPanels: [],
    wainscots: [],
    partialWalls: [],
    walkDoors: [],
    expandable: {
      type: "BOOLEAN",
      displayName: "Expandable",
      value: "",
    },
    mirroredBays: {
      type: "BOOLEAN",
      displayName: "Mirrored",
      value: "",
    },
    mirroredWalkDoors: {
      type: "BOOLEAN",
      displayName: "Mirrored",
      value: "",
    },
    mirroredFramedOpenings: {
      type: "BOOLEAN",
      displayName: "Mirrored",
      value: "",
    },
  },
  frontSidewall: {
    surfaceSpacings: [],
    framedOpenings: [],
    liners: [],
    extensions: [],
    lightPanels: [],
    wainscots: [],
    partialWalls: [],
    walkDoors: [],
    mirroredWalkDoors: {
      type: "BOOLEAN",
      displayName: "Mirrored",
      value: "",
    },
    mirroredFramedOpenings: {
      type: "BOOLEAN",
      displayName: "Mirrored",
      value: "",
    },
  },
  backSidewall: {
    surfaceSpacings: [],
    framedOpenings: [],
    liners: [],
    extensions: [],
    lightPanels: [],
    wainscots: [],
    partialWalls: [],
    walkDoors: [],
    mirroredWalkDoors: {
      type: "BOOLEAN",
      displayName: "Mirrored",
      value: "",
    },
    mirroredFramedOpenings: {
      type: "BOOLEAN",
      displayName: "Mirrored",
      value: "",
    },
  },
  accessories: {
    guttersAndDown: {
      type: "BOOLEAN",
      displayName: "Gutters & Downspouts",
      value: "",
    },
  },
  notes: {
    buildingNotes: {
      type: "AREA_TEXT",
      displayName: "Building Notes",
      value: "",
    },
  },
  roofInsulation: {
    type: {
      type: "STANDARD_DROPDOWN",
      displayName: "Type",
      options: [
        {
          display: "None",
          value: "none",
        },
        {
          display: "Single Layer",
          value: "singleLayer",
        },
        {
          display: "System",
          value: "system",
        },
        {
          display: "Spray Foam",
          value: "sprayFoam",
        },
      ],
      value: "",
    },
    thickness: {
      type: "NUMBER",
      displayName: "Thickness",
      suffix: "in",
      value: "",
    },
    bySteelTech: {
      type: "BOOLEAN",
      displayName: "Steel Tech",
      value: "",
    },
  },
  wallInsulation: {
    type: {
      type: "STANDARD_DROPDOWN",
      displayName: "Type",
      options: [
        {
          display: "None",
          value: "none",
        },
        {
          display: "Single Layer",
          value: "singleLayer",
        },
        {
          display: "System",
          value: "system",
        },
        {
          display: "Spray Foam",
          value: "sprayFoam",
        },
      ],
      value: "",
    },
    thickness: {
      type: "NUMBER",
      displayName: "Thickness",
      suffix: "in",
      value: "",
    },
    bySteelTech: {
      type: "BOOLEAN",
      displayName: "Steel Tech",
      value: "",
    },
  },
  initiated: false,
}
const project = {
  primaryInfo: {
    dealerInfo: {
      name: {
        type: "TEXT",
        displayName: "Name",
        required: true,
        value: "",
      },
      address: {
        type: "TEXT",
        displayName: "Address",
        value: "",
      },
      city: {
        type: "TEXT",
        displayName: "City",
        value: "",
      },
      state: {
        type: "STATES_DROPDOWN",
        displayName: "State",
        value: "",
      },
      zip: {
        type: "number",
        displayName: "Zip",
        value: "",
      },
      email: {
        type: "email",
        displayName: "Email",
        value: "",
      },
      phone: {
        type: "PHONE",
        displayName: "Phone",
        value: "",
      },
    },
    projectInfo: {
      address: {
        type: "TEXT",
        displayName: "Address",
        value: "",
      },
      city: {
        type: "TEXT",
        displayName: "City",
        value: "",
      },
      state: {
        type: "STATES_DROPDOWN",
        displayName: "State",
        value: "",
      },
      zip: {
        type: "NUMBER",
        displayName: "Zip",
        value: "",
      },
      projectName: {
        type: "TEXT",
        displayName: "Project Name",
        required: true,
        value: "",
      },
      contactName: {
        type: "TEXT",
        displayName: "Name",
        required: true,
        value: "",
      },
      contactEmail: {
        type: "email",
        displayName: "Email",
        required: true,
        value: "",
      },
      contactNumber: {
        type: "PHONE",
        displayName: "Phone",
        required: true,
        value: "",
      },
    },
  },
  loads: {
    liveLoad: {
      type: "FLOAT",
      displayName: "Live Load",
      suffix: "psf",
      value: "20",
    },
    groundSnow: {
      type: "FLOAT",
      displayName: "Ground Snow",
      suffix: "psf",
      value: "",
    },
    windExposure: {
      type: "STANDARD_DROPDOWN",
      displayName: "Wind Exposure",
      value: "c",
      options: [
        { display: "B", value: "b" },
        { display: "C", value: "c" },
        { display: "D", value: "d" },
      ],
    },
    windLoad: {
      type: "NUMBER",
      displayName: "Wind Load",
      suffix: "mph",
      value: "",
    },
    liveLoadReducible: {
      type: "BOOLEAN",
      displayName: "Reducible",
      value: "",
    },
    groundSnowReducible: {
      type: "BOOLEAN",
      displayName: "Reducible",
      value: "",
    },
  },
}
const buildings = []

export const defaultBuilding = () => {
  return {
    building,
    project,
    buildings,
  }
}

export default {
  project,
  building,
  buildings,
}
