<template>
  <v-container fluid>
    <v-row
      v-for="(item, index) in items"
      :key="index"
      class="pb-3"
      style="height='20em'"
    >
      <v-col cols="12" md="auto">
        <input-handler :path="`${path}[${index}].fullWidth`" />
      </v-col>
      <v-col cols="12" md="4">
        <input-handler
          :path="`${path}[${index}].quantity`"
          :setValue="
            item.fullWidth.value === true ? panelQuantity : item.quantity.value
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <h5>All light panels are 12'</h5>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex"

export default {
  name: "LightPanel",

  props: {
    type: String,
    items: Array,
  },

  computed: {
    ...mapState({
      buildings: (state) => state.data.buildings,
    }),
    buildingIndex() {
      return this.$route.params.buildingIndex
    },
    panelQuantity() {
      const buildingWidth = this.buildings[this.buildingIndex].shape.width.value
      const buildingLength =
        this.buildings[this.buildingIndex].shape.length.value

      if (this.type.includes("Endwall") && buildingWidth > 0) {
        return Math.round(buildingWidth / 3).toString()
      } else if (
        (this.type.includes("Sidewall") || this.type === "roof") &&
        buildingLength > 0
      ) {
        return Math.round(buildingLength / 3).toString()
      } else {
        return "0"
      }
    },
    path() {
      return `buildings[${this.buildingIndex}].${this.type}.lightPanels`
    },
    building() {
      return this.buildings[this.buildingIndex]
    },
  },

  components: {
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },

  methods: {
    ...mapActions({
      updateStateValue: "data/updateStateValue",
    }),

    // async addLiner(type, buildingIndex) {
    //   await this.addValidLiner({ type, buildingIndex })
    // },
    // removeLiner(type, buildingIndex) {
    //   this.removeValidLiner({ type, buildingIndex })
    // },
  },

  data: () => ({}),
}
</script>
