<template>
  <v-container fluid>
    <v-row v-for="(item, index) in items" :key="index" class="pb-4">
      <v-col cols="12" md="2">
        <input-handler
          :path="`${path}[${index}].openingType`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
      <v-col cols="12" md="2">
        <input-handler
          :path="`${path}[${index}].framingType`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
      <v-col cols="12" md="2">
        <input-handler
          :path="`${path}[${index}].panelOption`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
      <v-col cols="12" md="1">
        <input-handler
          :path="`${path}[${index}].bay`"
          :addRules="[(v) => !!v || 'This field is required.']"
          :options="options"
        />
      </v-col>
      <v-col cols="12" md="1">
        <input-handler
          :path="`${path}[${index}].offset`"
          :addRules="[(v) => !!v || 'This field is required.']"
          :setValue="
            item.centered.value
              ? centeredWidth(item.bay.value, item.width.value)
              : item.offset.value
          "
        />
      </v-col>
      <v-col cols="12" md="1">
        <input-handler
          :path="`${path}[${index}].width`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
      <v-col cols="12" md="1">
        <input-handler
          :path="`${path}[${index}].headerHeight`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
      <v-col cols="12" md="1">
        <input-handler :path="`${path}[${index}].sillHeight`" />
      </v-col>
      <v-col cols="12" md="1">
        <input-handler :path="`${path}[${index}].baseElevation`" />
      </v-col>
      <v-col cols="12" md="2">
        <input-handler :path="`${path}[${index}].centered`" />
      </v-col>
    </v-row>
    <v-row class="pa-4">
      <v-col>
        <v-btn
          small
          color="accent"
          @click="addFramedOpening(type, buildingIndex)"
          >Add</v-btn
        >
      </v-col>
      <v-col v-if="items.length > 0">
        <v-btn
          small
          color="secondary"
          @click="removeFramedOpening(type, buildingIndex)"
          >Remove
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex"
export default {
  name: "FramedOpenings",

  props: {
    items: Array,
    type: String,
  },

  data: () => ({ bayWidths: [] }),

  computed: {
    ...mapState({
      buildings: (state) => state.data.buildings,
      activeTab: (state) => state.form.activeTab,
    }),
    buildingIndex() {
      return this.$route.params.buildingIndex
    },
    path() {
      return `buildings[${this.buildingIndex}].${this.type}.framedOpenings`
    },
    leftEndwallFramedOpenings() {
      return this.buildings[this.buildingIndex].leftEndwall.framedOpenings
    },
    rightEndwallFramedOpenings() {
      return this.buildings[this.buildingIndex].rightEndwall.framedOpenings
    },
    frontSidewallFramedOpenings() {
      return this.buildings[this.buildingIndex].frontSidewall.framedOpenings
    },
    backSidewallFramedOpenings() {
      return this.buildings[this.buildingIndex].backSidewall.framedOpenings
    },
    leftEndwallPath() {
      return `buildings[${this.buildingIndex}].leftEndwall`
    },
    rightEndwallPath() {
      return `buildings[${this.buildingIndex}].rightEndwall`
    },
    frontSidewallPath() {
      return `buildings[${this.buildingIndex}].frontSidewall`
    },
    backSidewallPath() {
      return `buildings[${this.buildingIndex}].backSidewall`
    },
    leftEndwallMirrored() {
      return this.buildings[this.buildingIndex].leftEndwall
        .mirroredFramedOpenings
    },
    rightEndwallMirrored() {
      return this.buildings[this.buildingIndex].rightEndwall
        .mirroredFramedOpenings
    },
    frontSidewallMirrored() {
      return this.buildings[this.buildingIndex].frontSidewall
        .mirroredFramedOpenings
    },
    backSidewallMirrored() {
      return this.buildings[this.buildingIndex].backSidewall
        .mirroredFramedOpenings
    },
    tab() {
      return this.activeTab
    },
    options() {
      const surface =
        this.buildings[this.buildingIndex][this.type].surfaceSpacings
      const options = []
      let bays = 0
      if (surface.length > 0) {
        surface.forEach((bay) => {
          if (bay.numberOfBays.value !== "")
            bays = bays + parseInt(bay.numberOfBays.value)
        })
        surface.map((bay) => {
          for (let i = 0; i < bay.numberOfBays.value; i++) {
            this.bayWidths.push(parseFloat(bay.width.value))
          }
        })
      }
      for (let i = 0; i < bays; i++) {
        let bay = i + 1
        const option = {
          display: bay,
          value: bay,
        }
        options.push(option)
      }
      return options
    },
  },

  components: {
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },

  watch: {
    leftEndwallMirrored: {
      deep: true,
      handler() {
        if (
          this.type === "leftEndwall" &&
          this.leftEndwallMirrored.value !== this.rightEndwallMirrored.value
        ) {
          this.updateStateValue({
            object: this.rightEndwallMirrored,
            value: this.leftEndwallMirrored.value,
            path: `${this.rightEndwallPath}.mirroredFramedOpenings`,
          })
          if (this.leftEndwallMirrored.value) {
            const leftEndwallClone = JSON.parse(
              JSON.stringify(this.leftEndwallFramedOpenings)
            )
            this.setObject({
              object: leftEndwallClone,
              path: this.rightEndwallPath,
              value: "framedOpenings",
            })
          }
        }
      },
    },
    leftEndwallFramedOpenings: {
      deep: true,
      handler() {
        if (this.leftEndwallMirrored.value && this.tab === 1) {
          const leftEndwallClone = JSON.parse(
            JSON.stringify(this.leftEndwallFramedOpenings)
          )
          this.setObject({
            object: leftEndwallClone,
            path: this.rightEndwallPath,
            value: "framedOpenings",
          })
        }
      },
    },
    rightEndwallMirrored: {
      deep: true,
      handler() {
        if (
          this.type === "rightEndwall" &&
          this.leftEndwallMirrored.value !== this.rightEndwallMirrored.value
        ) {
          this.updateStateValue({
            object: this.leftEndwallMirrored,
            value: this.rightEndwallMirrored.value,
            path: `${this.leftEndwallPath}.mirroredFramedOpenings`,
          })
          if (this.rightEndwallMirrored.value) {
            const rightEndwallClone = JSON.parse(
              JSON.stringify(this.rightEndwallFramedOpenings)
            )
            this.setObject({
              object: rightEndwallClone,
              path: this.leftEndwallPath,
              value: "framedOpenings",
            })
            this.updateValue = true
          }
        }
      },
    },
    rightEndwallFramedOpenings: {
      deep: true,
      handler() {
        if (this.leftEndwallMirrored.value && this.tab === 3) {
          const rightEndwallClone = JSON.parse(
            JSON.stringify(this.rightEndwallFramedOpenings)
          )
          this.setObject({
            object: rightEndwallClone,
            path: this.leftEndwallPath,
            value: "framedOpenings",
          })
        }
      },
    },
    backSidewallMirrored: {
      deep: true,
      handler() {
        if (
          this.type === "backSidewall" &&
          this.frontSidewallMirrored.value !== this.backSidewallMirrored.value
        ) {
          this.updateStateValue({
            object: this.frontSidewallMirrored,
            value: this.backSidewallMirrored.value,
            path: `${this.frontSidewallPath}.mirroredFramedOpenings`,
          })
          if (this.backSidewallMirrored.value) {
            const backSidewallClone = JSON.parse(
              JSON.stringify(this.backSidewallFramedOpenings)
            )
            this.setObject({
              object: backSidewallClone,
              path: this.backSidewallPath,
              value: "framedOpenings",
            })
            this.updateValue = true
          }
        }
      },
    },
    backSidewallFramedOpenings: {
      deep: true,
      handler() {
        if (this.frontSidewallMirrored.value && this.tab === 4) {
          const backSidewallClone = JSON.parse(
            JSON.stringify(this.backSidewallFramedOpenings)
          )
          this.setObject({
            object: backSidewallClone,
            path: this.frontSidewallPath,
            value: "framedOpenings",
          })
        }
      },
    },
    frontSidewallMirrored: {
      deep: true,
      handler() {
        if (
          this.type === "frontSidewall" &&
          this.backSidewallMirrored.value !== this.frontSidewallMirrored.value
        ) {
          this.updateStateValue({
            object: this.backSidewallMirrored,
            value: this.frontSidewallMirrored.value,
            path: `${this.backSidewallPath}.mirroredFramedOpenings`,
          })
          if (this.frontSidewallMirrored.value) {
            const frontSidewallClone = JSON.parse(
              JSON.stringify(this.frontSidewallFramedOpenings)
            )
            this.setObject({
              object: frontSidewallClone,
              path: this.frontSidewallPath,
              value: "framedOpenings",
            })
            this.updateValue = true
          }
        }
      },
    },
    frontSidewallFramedOpenings: {
      deep: true,
      handler() {
        if (this.backSidewallMirrored.value && this.tab === 2) {
          const frontSidewallClone = JSON.parse(
            JSON.stringify(this.frontSidewallFramedOpenings)
          )
          this.setObject({
            object: frontSidewallClone,
            path: this.backSidewallPath,
            value: "framedOpenings",
          })
        }
      },
    },
  },

  methods: {
    ...mapActions({
      updateStateValue: "data/updateStateValue",
      setObject: "data/setObject",
      addValidFramedOpening: "data/addFramedOpeningToArray",
      removeValidFramedOpening: "data/removeFramedOpeningFromArray",
    }),
    async addFramedOpening(type, buildingIndex) {
      await this.addValidFramedOpening({ type, buildingIndex })
    },
    removeFramedOpening(type, buildingIndex) {
      this.removeValidFramedOpening({ type, buildingIndex })
    },
    centeredWidth(bay, width) {
      let bays = JSON.parse(JSON.stringify(this.bayWidths))
      if (this.type === "rightEndwall" || this.type === "backSidewall")
        bays.reverse()
      if (bay !== "") {
        const offset = bays[bay - 1] / 2 - width / 2
        return offset.toFixed(4).toString()
      }
      return "0"
    },
  },
}
</script>
