<template>
  <v-container fill-height>
    <v-row class="pa-2" no-gutters>
      <v-col cols="5">
        <v-card flat>
          <v-card-title class="pa-0">
            Bay Spacing
            <v-row align="start" justify="start" no-gutters class="pl-4">
              <input-handler
                v-if="
                  (type === 'leftEndwall' || type === 'rightEndwall') &&
                  surfaceSpacings.length > 0
                "
                class="pb-4"
                :path="`${path}.expandable`"
              />
              <input-handler
                v-if="
                  (type === 'leftEndwall' || type === 'rightEndwall') &&
                  surfaceSpacings.length > 0
                "
                class="pb-4"
                :path="`${path}.mirroredBays`"
              />
            </v-row>
          </v-card-title>
          <Surface-Spacing :type="type" :items="surfaceSpacings" />
        </v-card>
      </v-col>
      <v-col cols="7">
        <v-card flat>
          <v-card-title class="pa-0"> Liner </v-card-title>
          <Liner :type="type" :items="liners" />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pa-2" no-gutters>
      <v-col v-if="type !== 'roof'">
        <v-card flat>
          <v-card-title class="pa-0"> Extensions</v-card-title>
          <Extension :type="type" :items="extensions" />
        </v-card>
      </v-col>
      <v-col>
        <v-card flat>
          <v-card-title class="pa-0"> Light Panels</v-card-title>
          <Light-Panel :type="type" :items="lightPanels" />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pa-2" no-gutters v-if="type !== 'roof'">
      <v-card flat>
        <v-card-title class="pa-0">
          Walk Doors
          <!-- <v-row align="start" justify="start" no-gutters class="pl-4">
            <input-handler
              v-if="type !== 'roof' && surfaceSpacings.length > 0"
              class="pb-4"
              :path="`${path}.mirroredWalkDoors`"
            /> </v-row> -->
        </v-card-title>
        <Walk-Door :type="type" :items="walkDoors" />
      </v-card>
    </v-row>
    <v-row class="pa-2" no-gutters v-if="type !== 'roof'">
      <v-card flat>
        <v-card-title class="pa-0">
          Framed Openings
          <!-- <v-row align="start" justify="start" no-gutters class="pl-4">
            <input-handler
              v-if="type !== 'roof' && surfaceSpacings.length > 0"
              class="pb-4"
              :path="`${path}.mirroredFramedOpenings`"
            />
          </v-row> -->
        </v-card-title>
        <Framed-Openings :type="type" :items="framedOpenings" />
      </v-card>
    </v-row>
    <v-row class="pa-2" no-gutters v-if="type !== 'roof'">
      <v-card flat>
        <v-card-title class="pa-0">Partial Walls</v-card-title>
        <Partial-Wall :type="type" :items="partialWalls" />
      </v-card>
    </v-row>
    <v-row class="pa-2" no-gutters v-if="type !== 'roof'">
      <v-card flat>
        <v-card-title class="pa-0"> Wainscots</v-card-title>
        <Wainscot :type="type" :items="wainscots" />
      </v-card>
    </v-row>
    <v-row class="pa-2" no-gutters>
      <v-card flat width="100%">
        <v-card-title class="pa-0"> 2D Building</v-card-title>
        <Canvas :type="type" :buildingIndex="buildingIndex" />
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex"
import SurfaceSpacing from "./SurfaceSpacing"
import FramedOpenings from "./FramedOpenings"
import Liner from "./Liner"
import Wainscot from "./Wainscot"
import WalkDoor from "./WalkDoor"
import PartialWall from "./PartialWall"
import Extension from "./Extension"
import LightPanel from "./LightPanel"
import Canvas from "./Canvas"

export default {
  name: "Index",

  components: {
    SurfaceSpacing,
    FramedOpenings,
    Liner,
    Wainscot,
    WalkDoor,
    PartialWall,
    Extension,
    LightPanel,
    Canvas,
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },

  props: {
    type: String,
    title: String,
    surfaceSpacings: Array,
    liners: Array,
    framedOpenings: Array,
    wainscots: Array,
    walkDoors: Array,
    partialWalls: Array,
    extensions: Array,
    lightPanels: Array,
  },

  computed: {
    ...mapState({
      buildings: (state) => state.data.buildings,
    }),
    buildingIndex() {
      return this.$route.params.buildingIndex
    },

    path() {
      return `buildings[${this.buildingIndex}].${this.type}`
    },
  },
}
</script>
