<template>
  <v-container>
    <v-row>
      <v-col>
        <!-- <v-row class="pb-2">
          <h3>Dealer Contact</h3>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <input-handler :path="`${path}.dealerInfo.email`" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <input-handler :path="`${path}.dealerInfo.phone`" />
          </v-col>
        </v-row> -->
        <v-row class="py-2">
          <h3>Project Info</h3>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <input-handler
              :path="`${path}.projectInfo.projectName`"
              :addRules="[(v) => !!v || 'This field is required.']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <input-handler :path="`${path}.projectInfo.address`" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <input-handler
              :path="`${path}.projectInfo.city`"
              :addRules="[(v) => !!v || 'This field is required.']"
            />
          </v-col>
          <v-col cols="12" md="2">
            <input-handler
              :path="`${path}.projectInfo.state`"
              :addRules="[(v) => !!v || 'This field is required.']"
            />
          </v-col>
          <v-col cols="12" md="2">
            <input-handler
              :path="`${path}.projectInfo.zip`"
              :addRules="[
                (v) => !!v || 'This field is required.',
                (v) => /\b\d{5}\b$/.test(v) || 'Must be a valid zip code.',
              ]"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row class="pb-2">
          <h3>Dealer Info</h3>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <input-handler
              :path="`${path}.dealerInfo.name`"
              :addRules="[(v) => !!v || 'This field is required.']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <input-handler :path="`${path}.dealerInfo.address`" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <input-handler :path="`${path}.dealerInfo.city`" />
          </v-col>
          <v-col cols="12" md="2">
            <input-handler :path="`${path}.dealerInfo.state`" />
          </v-col>
          <v-col cols="12" md="2">
            <input-handler :path="`${path}.dealerInfo.zip`" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row class="py-2">
          <h3>Dealer Contact</h3>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <input-handler :path="`${path}.projectInfo.contactName`" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <input-handler :path="`${path}.projectInfo.contactNumber`" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <input-handler :path="`${path}.projectInfo.contactEmail`" />
          </v-col>
        </v-row>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "primaryInfo",

  components: {
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },

  data: () => ({
    path: "project.primaryInfo",
  }),
}
</script>
