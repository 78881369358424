<template>
  <v-container fill-height>
    <v-row>
      <v-tabs color="primary" fixed-tabs v-model="tab" show-arrows>
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab :ripple="false" id="roof"> Roof </v-tab>
        <v-tab :ripple="false" id="leftEndwall"> Left Endwall </v-tab>
        <v-tab :ripple="false" id="frontSidewall"> Front Sidewall </v-tab>
        <v-tab :ripple="false" id="rightEndwall"> Right Endwall </v-tab>
        <v-tab :ripple="false" id="backSidewall"> Back Sidewall </v-tab>
        <v-tab-item>
          <Wall
            :surfaceSpacings="roof.surfaceSpacings"
            :liners="roof.liners"
            :framedOpenings="roof.framedOpenings"
            :extensions="roof.extensions"
            :lightPanels="roof.lightPanels"
            type="roof"
            title="Roof"
          />
        </v-tab-item>
        <v-tab-item>
          <Wall
            :surfaceSpacings="leftEndwall.surfaceSpacings"
            :liners="leftEndwall.liners"
            :framedOpenings="leftEndwall.framedOpenings"
            :extensions="leftEndwall.extensions"
            :lightPanels="leftEndwall.lightPanels"
            :wainscots="leftEndwall.wainscots"
            :partialWalls="leftEndwall.partialWalls"
            :walkDoors="leftEndwall.walkDoors"
            type="leftEndwall"
            title="Left Endwall"
          />
        </v-tab-item>
        <v-tab-item>
          <Wall
            :surfaceSpacings="frontSidewall.surfaceSpacings"
            :liners="frontSidewall.liners"
            :framedOpenings="frontSidewall.framedOpenings"
            :extensions="frontSidewall.extensions"
            :lightPanels="frontSidewall.lightPanels"
            :wainscots="frontSidewall.wainscots"
            :partialWalls="frontSidewall.partialWalls"
            :walkDoors="frontSidewall.walkDoors"
            type="frontSidewall"
            title="Front Sidewall"
          />
        </v-tab-item>
        <v-tab-item>
          <Wall
            :surfaceSpacings="rightEndwall.surfaceSpacings"
            :liners="rightEndwall.liners"
            :framedOpenings="rightEndwall.framedOpenings"
            :extensions="rightEndwall.extensions"
            :lightPanels="rightEndwall.lightPanels"
            :wainscots="rightEndwall.wainscots"
            :partialWalls="rightEndwall.partialWalls"
            :walkDoors="rightEndwall.walkDoors"
            type="rightEndwall"
            title="Right Endwall"
          />
        </v-tab-item>
        <v-tab-item>
          <Wall
            :surfaceSpacings="backSidewall.surfaceSpacings"
            :liners="backSidewall.liners"
            :framedOpenings="backSidewall.framedOpenings"
            :extensions="backSidewall.extensions"
            :lightPanels="backSidewall.lightPanels"
            :wainscots="backSidewall.wainscots"
            :partialWalls="backSidewall.partialWalls"
            :walkDoors="backSidewall.walkDoors"
            type="backSidewall"
            title="Back Sidewall"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex"
import Wall from "./walls/Index"

export default {
  name: "Walls",

  data: () => ({}),

  components: {
    Wall,
  },

  computed: {
    ...mapState({
      buildings: (state) => state.data.buildings,
      activeTab: (state) => state.form.activeTab,
    }),
    buildingIndex() {
      return this.$route.params.buildingIndex
    },
    roof() {
      return this.buildings[this.buildingIndex].roof
    },
    leftEndwall() {
      return this.buildings[this.buildingIndex].leftEndwall
    },
    rightEndwall() {
      return this.buildings[this.buildingIndex].rightEndwall
    },
    frontSidewall() {
      return this.buildings[this.buildingIndex].frontSidewall
    },
    backSidewall() {
      return this.buildings[this.buildingIndex].backSidewall
    },
    tab: {
      get() {
        return this.activeTab
      },
      set(newValue) {
        this.setObject({
          path: "activeTab",
          value: newValue,
        })
      },
    },
  },

  methods: {
    ...mapActions({
      setObject: "form/setObject",
    }),
  },
}
</script>
