import router from "@/router/index"

export default {
  initiateState: function ({ commit }) {
    commit("INITIATE_STATE")
  },

  addBuildingToArray: async function ({ commit }) {
    await commit("ADD_BUILDING")
  },

  removeBuildingFromArray: async function ({ commit }, buildingIndex) {
    await commit("REMOVE_BUILDING", { buildingIndex })
  },

  addSurfaceSpacingToArray: async function (
    { commit },
    { type, buildingIndex }
  ) {
    await commit("ADD_SURFACE_SPACING", { type, buildingIndex })
  },

  removeSurfaceSpacingFromArray: async function (
    { commit },
    { type, buildingIndex }
  ) {
    await commit("REMOVE_SURFACE_SPACING", { type, buildingIndex })
  },

  addFramedOpeningToArray: async function (
    { commit },
    { type, buildingIndex }
  ) {
    await commit("ADD_FRAMED_OPENING", { type, buildingIndex })
  },

  removeFramedOpeningFromArray: async function (
    { commit },
    { type, buildingIndex }
  ) {
    await commit("REMOVE_FRAMED_OPENING", { type, buildingIndex })
  },

  addLinerToArray: async function ({ commit }, { type, buildingIndex }) {
    await commit("ADD_LINER", { type, buildingIndex })
  },

  removeLinerFromArray: async function ({ commit }, { type, buildingIndex }) {
    await commit("REMOVE_LINER", { type, buildingIndex })
  },

  addWainscotToArray: async function ({ commit }, { type, buildingIndex }) {
    await commit("ADD_WAINSCOT", { type, buildingIndex })
  },

  removeWainscotFromArray: async function (
    { commit },
    { type, buildingIndex }
  ) {
    await commit("REMOVE_WAINSCOT", { type, buildingIndex })
  },

  addExtensionToArray: async function ({ commit }, { type, buildingIndex }) {
    await commit("ADD_EXTENSION", { type, buildingIndex })
  },

  removeExtensionFromArray: async function (
    { commit },
    { type, buildingIndex }
  ) {
    await commit("REMOVE_EXTENSION", { type, buildingIndex })
  },

  addPartialWallToArray: async function ({ commit }, { type, buildingIndex }) {
    await commit("ADD_PARTIAL_WALL", { type, buildingIndex })
  },

  removePartialWallFromArray: async function (
    { commit },
    { type, buildingIndex }
  ) {
    await commit("REMOVE_PARTIAL_WALL", { type, buildingIndex })
  },

  addWalkDoorToArray: async function ({ commit }, { type, buildingIndex }) {
    await commit("ADD_WALK_DOOR", { type, buildingIndex })
  },

  removeWalkDoorFromArray: async function (
    { commit },
    { type, buildingIndex }
  ) {
    await commit("REMOVE_WALK_DOOR", { type, buildingIndex })
  },

  updateStateValue: function ({ commit }, { object, value, path = "" }) {
    if (path) {
      commit("SET_VALUE_PATH", { value, path })
    } else {
      commit("SET_VALUE", { object, value })
    }
  },

  downloadState: async function ({ state }) {
    const buildings = state.buildings
    const project = state.project
    const data = {
      buildings,
      project,
    }
    const dataStringified = JSON.stringify(data)

    const name = `${project.primaryInfo.projectInfo.projectName.value}.mbsQuote`
    try {
      const blob = new Blob([dataStringified], { type: "application/json" })
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = name
      link.click()
      URL.revokeObjectURL(link.href)
    } catch (e) {
      alert("Failed to save the file!")
    }
  },

  convertStateToBase64: async function ({ state }) {
    const buildings = state.buildings
    const project = state.project
    const data = {
      buildings,
      project,
    }
    const dataStringified = JSON.stringify(data)

    try {
      const base64 = Buffer.from(dataStringified).toString("base64")
      return base64
    } catch (e) {
      alert("Failed to save the file!")
    }
  },

  convertStateToJson: async function ({ state }) {
    const buildings = state.buildings
    const project = state.project
    const data = {
      buildings,
      project,
    }
    const dataStringified = JSON.stringify(data)

    try {
      return dataStringified
    } catch (e) {
      alert("Failed to save the file!")
    }
  },

  resetState: async function ({ commit }, path) {
    router.push(path)
    await commit("RESET_BUILDING_STATE")
  },

  setStateFromFile: async function ({ commit }, file) {
    await commit("SET_STATE_FROM_FILE", file)
    router.push("/quote/project")
  },

  setStateFromTemplate: async function ({ commit }, template) {
    await commit("SET_STATE_FROM_TEMPLATE", template)
    router.push("/quote/project")
  },

  setObject: async function ({ commit }, { object, path, value }) {
    await commit("SET_OBJECT", { object, path, value })
  },

  getBuilding: function ({ state }) {
    return state.building
  },
  // VALIDATION
  geometryValidation({ commit, state }, { buildingIndex, value }) {
    const shape = state.buildings[buildingIndex].shape
    switch (value) {
      case "symmetricalDoubleSlope":
        commit("SET_VALUE", {
          object: shape.slopeRight,
          value: shape.slope.value,
        })
        commit("SET_VALUE", {
          object: shape.heightRight,
          value: shape.heightLeft.value,
        })
        commit("SET_VALUE", {
          object: shape.peakOffset,
          value: parseFloat(shape.width.value) / 2.0,
        })
        break
      case "singleSlope":
        commit("SET_VALUE", {
          object: shape.peakOffset,
          value: shape.width.value,
        })
        commit("SET_VALUE", { object: shape.slopeRight, value: 0.0 })
        commit("SET_VALUE", {
          object: shape.heightRight,
          value: (
            parseFloat(shape.heightLeft.value) +
            parseFloat(shape.slope.value) *
              (parseFloat(shape.width.value) / 12.0)
          ).toFixed(4),
        })
        break
    }
    return true
  },
}
