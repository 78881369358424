import Vue from "vue"
import defaultState from "./state"
import { get } from "lodash"

export default {
  SET_VALUE(state, { object, value }) {
    Vue.set(object, "value", value)
  },
  SET_VALUE_PATH(state, { value, path }) {
    const obj = get(state, path)
    Vue.set(obj, "value", value)
  },
  SET_OBJECT(state, { object, path, value }) {
    const obj = get(state, path)
    Vue.set(obj, value, object)
  },
  async ADD_BUILDING(state) {
    const buildingClone = JSON.parse(JSON.stringify(state.building))
    const buildings = state.buildings

    await buildings.push(buildingClone)
  },

  async REMOVE_BUILDING(state, { buildingIndex }) {
    const buildings = state.buildings

    await buildings.splice(buildingIndex, 1)
  },

  async ADD_SURFACE_SPACING(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].surfaceSpacings
    const surfaceSpacingClone = JSON.parse(
      JSON.stringify(state.building.surfaceSpacing)
    )

    await array.push(surfaceSpacingClone)
  },

  async REMOVE_SURFACE_SPACING(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].surfaceSpacings

    await array.pop()
  },

  async ADD_FRAMED_OPENING(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].framedOpenings
    const framedOpeningsClone = JSON.parse(
      JSON.stringify(state.building.framedOpening)
    )

    await array.push(framedOpeningsClone)
  },

  async REMOVE_FRAMED_OPENING(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].framedOpenings

    await array.pop()
  },

  async ADD_LINER(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].liners
    const linersClone = JSON.parse(JSON.stringify(state.building.liner))

    await array.push(linersClone)
  },

  async REMOVE_LINER(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].liners

    await array.pop()
  },

  async ADD_WAINSCOT(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].wainscots
    const wainscotsClone = JSON.parse(JSON.stringify(state.building.wainscot))

    await array.push(wainscotsClone)
  },

  async REMOVE_WAINSCOT(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].wainscots

    await array.pop()
  },

  async ADD_EXTENSION(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].extensions
    const extensionsClone = JSON.parse(JSON.stringify(state.building.extension))

    await array.push(extensionsClone)
  },

  async REMOVE_EXTENSION(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].extensions

    await array.pop()
  },

  async ADD_PARTIAL_WALL(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].partialWalls
    const partialWallsClone = JSON.parse(
      JSON.stringify(state.building.partialWall)
    )

    await array.push(partialWallsClone)
  },

  async REMOVE_PARTIAL_WALL(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].partialWalls

    await array.pop()
  },

  async ADD_WALK_DOOR(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].walkDoors
    const walkDoorsClone = JSON.parse(JSON.stringify(state.building.walkDoor))

    await array.push(walkDoorsClone)
  },

  async REMOVE_WALK_DOOR(state, { type, buildingIndex }) {
    const array = state.buildings[buildingIndex][type].walkDoors

    await array.pop()
  },

  INITIATE_STATE(state) {
    const buildingClone = JSON.parse(JSON.stringify(state.building))
    const extensionClone = JSON.parse(JSON.stringify(state.building.extension))
    const lightPanelClone = JSON.parse(
      JSON.stringify(state.building.lightPanel)
    )
    const buildings = state.buildings
    const initiated = state.building.initiated

    if (!initiated) {
      //Add a single extension to each surface
      buildingClone.leftEndwall.extensions.push(extensionClone)
      buildingClone.rightEndwall.extensions.push(extensionClone)
      buildingClone.frontSidewall.extensions.push(extensionClone)
      buildingClone.backSidewall.extensions.push(extensionClone)
      //Add a single lightPanel to each surface
      buildingClone.roof.lightPanels.push(lightPanelClone)
      buildingClone.leftEndwall.lightPanels.push(lightPanelClone)
      buildingClone.rightEndwall.lightPanels.push(lightPanelClone)
      buildingClone.frontSidewall.lightPanels.push(lightPanelClone)
      buildingClone.backSidewall.lightPanels.push(lightPanelClone)

      buildings.push(buildingClone)
      Vue.set(state.building, "initiated", true)
    }
  },

  RESET_BUILDING_STATE(state) {
    Object.assign(state, defaultState)
  },

  SET_STATE_FROM_TEMPLATE(state, template) {
    const buildingsClone = JSON.parse(JSON.stringify(template.buildings))
    const projectClone = JSON.parse(JSON.stringify(template.project))

    Vue.set(state, "buildings", buildingsClone)
    Vue.set(state, "project", projectClone)
  },
  SET_STATE_FROM_FILE(state, file) {
    const parsedFile = JSON.parse(file)
    Vue.set(state.building, "initiated", true)
    Vue.set(state, "buildings", parsedFile.buildings)
    Vue.set(state, "project", parsedFile.project)
  },
}
