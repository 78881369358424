<template>
  <v-container fill-height>
    <v-row>
      <h2>Customer & Project Information</h2>
    </v-row>
    <v-row>
      <Primary-Info />
    </v-row>
    <v-row>
      <v-col align="right">
        <v-btn color="primary" class="mr-4" @click="checkForm()"
          >Add Building</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex"
import PrimaryInfo from "./primaryInfo"

export default {
  name: "Index",

  components: {
    PrimaryInfo,
  },

  data: () => ({}),

  mounted() {},

  computed: {
    ...mapState({
      formCheck: (state) => state.form.formCheck,
      formValid: (state) => state.form.formValid,
    }),
  },

  methods: {
    ...mapActions({
      setObject: "form/setObject",
    }),
    async checkForm() {
      let iterator = 1
      iterator = this.formCheck + iterator
      await this.setObject({ value: iterator, path: "formCheck" })
      this.goToBuilding()
    },
    async goToBuilding() {
      if (this.formValid) {
        await this.setObject({ value: false, path: "formValid" })
        this.$router.push("building/0")
      }
    },
  },
}
</script>
