import axios from "axios"

const BASE_URL =
  "https://us-central1-mbs-dealer-sheet.cloudfunctions.net/express"
// const BASE_URL = "http://localhost:3000"

export async function createItem(id, data, attachments) {
  try {
    const path = `${BASE_URL}/api/clickup/createTask`
    const body = { listId: id, data, attachments }
    const response = await axios.post(path, body)

    return response
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function sendEmail(data) {
  try {
    const path = `${BASE_URL}/api/sendGrid/sendEmail`
    const body = data
    const response = await axios.post(path, body)
    console.log("sendEmail", response)
    return response
  } catch (err) {
    console.error(err)
    throw err
  }
}
