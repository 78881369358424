import Vue from "vue"
import Vuetify from "vuetify/lib/framework"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#ff6600",
        secondary: "#CDCDCD",
        accent: "#6E8995",
        error: "#FF5252",
      },
      dark: {
        primary: "#ff6600",
        secondary: "#CDCDCD",
        accent: "#6E8995",
        error: "#FF5252",
      },
    },
  },
})
