<template>
  <v-container fluid v-if="surfaces.length > 0 && options.length > 0">
    <v-row v-for="(item, index) in items" :key="index" class="pb-3">
      <v-col cols="12" md="3" v-if="!type.includes('Endwall')">
        <input-handler :path="`${path}[${index}].fullWidth`" />
      </v-col>
      <v-col cols="12" md="2" v-if="!type.includes('Endwall')">
        <input-handler
          :path="`${path}[${index}].start`"
          :disabled="item.fullWidth.value ? true : false"
          :setValue="item.fullWidth.value ? '1' : ''"
          :options="item.fullWidth.value ? ['1'] : options"
          :addRules="[
            (v) => !bayCheck(v) || 'Must be equal to or less than End',
          ]"
        />
      </v-col>
      <v-col cols="12" md="2" v-if="!type.includes('Endwall')">
        <input-handler
          :path="`${path}[${index}].end`"
          :disabled="item.fullWidth.value ? true : false"
          :setValue="
            item.fullWidth.value
              ? String(options[options.length - 1].value)
              : ''
          "
          :options="
            item.fullWidth.value
              ? [String(options[options.length - 1].value)]
              : options
          "
          :addRules="[
            (v) =>
              v >= item.start.value ||
              'Must be equal to or greater than Start.',
          ]"
        />
      </v-col>
      <v-col cols="12" md="4">
        <input-handler :path="`${path}[${index}].width`" />
      </v-col>
    </v-row>
    <v-row>
      <h5>Soffits included</h5>
    </v-row>
    <v-row class="pa-4" v-if="!type.includes('Endwall')">
      <v-col>
        <v-btn small color="accent" @click="addExtension(type, buildingIndex)"
          >Add</v-btn
        >
      </v-col>
      <v-col v-if="items.length > 1">
        <v-btn
          small
          color="secondary"
          @click="removeExtension(type, buildingIndex)"
          >Remove
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex"
import validate from "@/utils/validation"

export default {
  name: "Extension",

  props: {
    type: String,
    items: Array,
  },

  computed: {
    ...mapState({
      buildings: (state) => state.data.buildings,
    }),
    buildingIndex() {
      return this.$route.params.buildingIndex
    },
    surfaces() {
      return this.buildings[this.buildingIndex][this.type].surfaceSpacings
    },
    path() {
      return `buildings[${this.buildingIndex}].${this.type}.extensions`
    },
    options() {
      const surface =
        this.buildings[this.buildingIndex][this.type].surfaceSpacings
      const options = []
      let bays = 0
      surface.forEach((bay) => {
        bays = bays + parseInt(bay.numberOfBays.value)
      })
      for (let i = 0; i < bays; i++) {
        let bay = i + 1
        const option = {
          display: bay,
          value: bay,
        }
        options.push(option)
      }
      return options
    },

    end() {
      if (this.type === "leftEndwall" || this.type === "rightEndwall")
        return {
          path: `buildings[${this.buildingIndex}].shape.width`,
          value: this.buildings[this.buildingIndex].shape.width.value,
        }
      if (this.type === "frontSidewall" || this.type === "backSidewall")
        return {
          path: `buildings[${this.buildingIndex}].shape.length`,
          value: this.buildings[this.buildingIndex].shape.length.value,
        }

      return ""
    },
  },

  components: {
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },

  methods: {
    ...mapActions({
      addValidExtension: "data/addExtensionToArray",
      removeValidExtension: "data/removeExtensionFromArray",
      updateStateValue: "data/updateStateValue",
    }),
    bayCheck(value) {
      return validate.bayValidation(
        this.buildings,
        this.buildingIndex,
        this.type,
        "extensions",
        value
      )
    },
    async addExtension(type, buildingIndex) {
      await this.addValidExtension({ type, buildingIndex })
    },
    removeExtension(type, buildingIndex) {
      this.removeValidExtension({ type, buildingIndex })
    },
  },

  data: () => ({}),
}
</script>
