<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-img
          :src="require('../../assets/ST Logo with bckgrnd.jpg')"
          class="my-3"
          contain
          height="100"
        />
        <h1 class="display-2 font-weight-bold mb-3">Login</h1>
      </v-col>
    </v-row>
    <v-form
      ref="loginForm"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit"
    >
      <v-row align="center" justify="center">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.emailValid]"
            type="email"
            label="Email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="show ? 'text' : 'password'"
            label="Password"
            @click:append="show = !show"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
        class="pb-4"
        v-if="signInError != ''"
      >
        <h5 class="error--text">{{ signInError }}</h5>
      </v-row>
      <v-row align="center" justify="center">
        <v-btn color="primary" type="submit" @click="submit(email, password)"
          >Login</v-btn
        >
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex"
export default {
  name: "Index",

  components: {},

  data: () => ({
    valid: true,
    show: false,
    email: "",
    password: "",
    signInError: "",
    rules: {
      required: (value) => !!value || "Required.",
      emailValid: (v) =>
        (!!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
        "Email must be a valid",
    },
  }),

  methods: {
    ...mapActions({
      authLogin: "auth/login",
    }),
    async submit(email, password) {
      if (this.$refs.loginForm.validate()) {
        await this.authLogin({ email, password })
      }
    },
  },
}
</script>
