<template>
  <v-container>
    <v-row class="pt-1">
      <h3>Exterior Panels</h3>
    </v-row>
    <v-row> <h4 class="pl-2">Roof</h4> </v-row>
    <v-row>
      <v-col cols="8">
        <input-handler
          :path="`${path}.roofPanel`"
          :addRules="[(v) => !!v || 'This field is required.']"
          :error-messages="errorMessages"
        />
      </v-col>
      <v-col cols="4">
        <input-handler
          :path="`${path}.roofFinish`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
    </v-row>
    <v-row class="pt-4"> <h4 class="pl-2">Wall</h4> </v-row>
    <v-row>
      <v-col cols="8">
        <input-handler
          :path="`${path}.wallPanel`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
      <v-col cols="4">
        <input-handler
          :path="`${path}.wallFinish`"
          :addRules="[(v) => !!v || 'This field is required.']"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "ExteriorPanels",

  props: {
    buildingIndex: String,
  },

  data: () => ({ errorMessages: "" }),

  computed: {
    ...mapState({
      buildings: (state) => state.data.buildings,
    }),
    shapeSlope() {
      return parseFloat(this.buildings[this.buildingIndex].shape.slope.value)
    },
    path() {
      return `buildings[${this.buildingIndex}].exteriorPanels`
    },
    panelRules() {
      return [(v) => this.validation(v)]
    },
  },

  methods: {
    validation(value) {
      if (value.includes("standingSeam") && this.shapeSlope < 0.25) {
        this.errorMessages = "Slope must be a minimum of 0.25 for Standing Seam"
      } else if (value.includes("pbr") && this.shapeSlope < 0.5) {
        this.errorMessages = "Slope must be a minimum of 0.5 for PBR"
      } else {
        this.errorMessages = ""
      }
      return true
    },
  },

  components: {
    InputHandler: () =>
      import(
        /* webpackChunkName: 'InputHandler' */
        "@/components/input/InputHandler"
      ),
  },
}
</script>
