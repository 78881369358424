export default {
  bayValidation(buildings, buildingIndex, wallType, type, value) {
    const array = buildings[buildingIndex][wallType][type]
    const starts = array.map((item) => item.start)
    const ends = array.map((item) => item.end)
    const startExists = starts.filter((item) => {
      return item.value >= value
    })
    const endExists = ends.filter((item) => {
      return item.value >= value
    })
    if (startExists.length > 1 || endExists.length > 1) return true
    else return false
  },
}
// point object: {x:, y:}
// p0 & p1 form one segment, p2 & p3 form the second segment
// Returns true if lines segments are intercepting
export let lineSegmentsIntercept = (p0, p1, p2, p3) => {
  // function as singleton so that closure can be used

  let v1, v2, v3, cross, u1, u2 // working variable are closed over so they do not need creation
  // each time the function is called. This gives a significant performance boost.
  v1 = { x: null, y: null } // line p0, p1 as vector
  v2 = { x: null, y: null } // line p2, p3 as vector
  v3 = { x: null, y: null } // the line from p0 to p2 as vector

  v1.x = p1.x - p0.x // line p0, p1 as vector
  v1.y = p1.y - p0.y
  v2.x = p3.x - p2.x // line p2, p3 as vector
  v2.y = p3.y - p2.y
  if ((cross = v1.x * v2.y - v1.y * v2.x) === 0) {
    // cross prod 0 if lines parallel
    return false // no intercept
  }
  v3 = { x: p0.x - p2.x, y: p0.y - p2.y } // the line from p0 to p2 as vector
  u2 = (v1.x * v3.y - v1.y * v3.x) / cross // get unit distance along line p2 p3
  // code point B
  if (u2 >= 0 && u2 <= 1) {
    // is intercept on line p2, p3
    u1 = (v2.x * v3.y - v2.y * v3.x) / cross // get unit distance on line p0, p1;
    // code point A
    if (u1 >= 0 && u1 <= 1) {
      return {
        x: p0.x + v1.x * u1,
        y: p0.y + v1.y * u1,
      }
    }
    // code point A end
  }
  return false // no intercept;
  // code point B end
}
